import { GoArrowRight } from "react-icons/go";
import AreaImage from "@/assets/images/profileManagement/game_area.png";
import { useTranslation } from "react-i18next";

export const ActiveArea = () => {
  const { t } = useTranslation(["profileManagement"], {
    keyPrefix: "IOT",
  });

  return (
    <div className="w-full h-full rounded-[32px] bg-white p-5">
      <div className="flex justify-between items-center">
        <h3 className="text-base font-semibold">{t("Active Area")}</h3>
        <div className="flex items-center gap-1 cursor-pointer hover:opacity-80">
          <h5 className="text-sm text-[#A3AED0]">{t("All Details")}</h5>
          <GoArrowRight size={15} className="text-primary" />
        </div>
      </div>
      <img src={AreaImage} alt="Area" className="w-full h-[350px] p-5" />
    </div>
  );
};
