import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Button1 from "@/components/Button/Button1";
import { useDispatch } from "react-redux";
import { login } from "@/redux/slices/appSlice";
import API from "@/lib/api";
import { setAPITokenCookie } from "@/redux/slices/appSlice";

export default function Callback() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const validateCode = async () => {
      // Check for Azure AD error response first
      const errorParam = searchParams.get("error");
      const errorDescription = searchParams.get("error_description");

      if (errorParam) {
        // Format the error description to be more readable
        const formattedError = errorDescription
          ? decodeURIComponent(errorDescription)
              .replace(/\+/g, " ")
              .replace(/\./g, ".\n")
          : "An unknown error occurred";

        setError(`${errorParam}: ${formattedError}`);
        return;
      }

      // Proceed with normal code validation
      const code = searchParams.get("code");
      const state = searchParams.get("state");

      if (!code || !state) {
        setError("Missing required parameters");
        return;
      }

      try {
        const res = await API.uaa.login(
          {
            code,
            state,
            type: "portal",
          },
          "azure-ad-grant"
        );
        const { accessToken, refreshToken, idToken, expiresIn } = res.data.data;

        dispatch(login());
        dispatch(
          setAPITokenCookie({
            accessToken: accessToken,
            refreshToken: refreshToken,
            idToken: idToken,
            expiresInSecond: expiresIn,
          })
        );
        navigate(`/property-management`);
      } catch (err) {
        console.log(err);
        setError("Authentication failed. Please try again.");
      }
    };

    validateCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, navigate]);

  if (error) {
    return (
      <div className="container mx-auto max-w-md mt-20">
        <Card>
          <CardHeader>
            <Alert variant="destructive">
              <AlertTitle>Authentication Error</AlertTitle>
              <AlertDescription className="whitespace-pre-line">
                {error}
              </AlertDescription>
            </Alert>
          </CardHeader>
          <CardContent className="flex justify-center">
            <Button1
              className="w-full"
              onClick={() => (window.location.href = "/")}
            >
              Return to Login
            </Button1>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-md mt-20">
      <Card>
        <CardContent className="flex flex-col items-center justify-center p-6">
          <p className="mt-4">Validating your login...</p>
        </CardContent>
      </Card>
    </div>
  );
}
