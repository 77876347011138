import BreadCrumbs from "@/components/BreadCrumbs/BreadCrumbs";
import ProfileListTable from "@/components/Table/profileManagement/ProfileListTable";
import ProfileDetailTab from "@/components/Tabs/profileManagement/ProfileDetailTab";
import EditableTabs from "@/components/Tabs/EditableTabs/Tabs1";
import { useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  setActiveProfile,
  setAllProfileActiveTab,
  setAllProfileTabs,
  setProfileTabs,
} from "@/redux/slices/profileManagementSlice";
import { TargetKey } from "@/types/components/Tabs/EditableTabs/Tabs1";
import { UserProfile } from "@/types/api/profile/UserProfile";

const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const AllProfile = () => {
  const { t } = useTranslation(["profileManagement"]);
  const dispatch = useDispatch();

  const allProfile = useSelector(
    (state: RootState) => state.profileManagement.allProfile
  );
  const { activeTab, tabs } = allProfile;
  const tabsRef = useRef(tabs);

  const onChangeTab = (id: string) => {
    dispatch(setAllProfileActiveTab(id));
    dispatch(
      setActiveProfile(allProfile.profileTabs.find((tab) => tab.id === id))
    );
    dispatch(
      setAllProfileTabs([
        {
          label: t("Profiles"),
          key: "0",
          closeIcon: <></>,
          forceRender: true,
          children: <ProfileListTable viewAction={addProfileTab} />,
        },
        ...tabsRef.current.filter((tab) => tab.key !== "0"),
      ])
    );
  };

  const removeTab = useCallback(
    (id: TargetKey) => {
      if (id === "0") return;

      const targetId = tabs.findIndex((tab) => tab.key === id);
      const newTabs = tabs.filter((tab) => tab.key !== id);
      if (newTabs.length !== 1 && id === activeTab) {
        const { key } =
          newTabs[targetId === newTabs.length ? targetId - 1 : targetId];
        dispatch(setAllProfileActiveTab(key));
      } else {
        dispatch(setAllProfileActiveTab("0"));
      }
      dispatch(setAllProfileTabs(newTabs));
      dispatch(setActiveProfile(undefined));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs, activeTab]
  );

  const onEditTab = (id: TargetKey, action: string) => {
    if (action === "add") {
      // No add tab here
    } else {
      removeTab(id);
    }
  };

  const addProfileTab = (profile: UserProfile, focus: boolean = false) => {
    const key = `${profile.id}`;
    const existingTab = tabsRef.current.find((tab) => tab.key === key);
    if (existingTab) {
      if (focus) {
        dispatch(setAllProfileActiveTab(key));
      }
      return;
    }

    const newTab = {
      key: key,
      label: `${profile.id}`,
      forceRender: true,
      children: <ProfileDetailTab id={profile.id} />,
    };
    dispatch(setAllProfileTabs([...tabsRef.current, newTab]));
    dispatch(setProfileTabs([...allProfile.profileTabs, profile]));
    if (focus) {
      dispatch(setAllProfileActiveTab(newTab.key));
      dispatch(setActiveProfile(profile));
    }
  };

  useEffect(() => {
    dispatch(
      setAllProfileTabs([
        {
          label: t("Profiles"),
          key: "0",
          closeIcon: <></>,
          forceRender: true,
          children: <ProfileListTable viewAction={addProfileTab} />,
        },
        ...tabsRef.current.filter((tab) => tab.key !== "0"),
      ])
    );
    dispatch(setAllProfileActiveTab(activeTab ?? "0"));

    return () => {
      dispatch(setProfileTabs([]));
      dispatch(setAllProfileTabs([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tabsRef.current = tabs;
  }, [tabs]);

  return (
    <section>
      <div className="container-fluid p-[40px]">
        <div className="row">
          <BreadCrumbs />
        </div>
        <div className="row">
          <div className="col-auto flex items-center">
            <Title>{t("Profiles")}</Title>
          </div>
        </div>
        <div className="row">
          <EditableTabs
            onChange={onChangeTab}
            activeKey={activeTab}
            onEdit={onEditTab}
            items={tabs}
          />
        </div>
      </div>
    </section>
  );
};

export default AllProfile;
