"use client";

import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { HourlySleepData } from "@/types/api/profile/UserProfile";
import { SleepState } from "@/types/enum/profile";
import { sleepLineChartConfig } from "@/lib/mock";

type Props = {
  data: HourlySleepData[];
};

export function SleepStatusChart({ data }: Props) {
  return (
    <ChartContainer config={sleepLineChartConfig}>
      <AreaChart
        accessibilityLayer
        data={data}
        margin={{
          left: 12,
          right: 12,
          top: 12,
        }}
        stackOffset="expand"
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="hour"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="line" />}
        />
        <Area
          dataKey={SleepState.AWAKE}
          type="natural"
          fill={sleepLineChartConfig.awake.color}
          stroke={sleepLineChartConfig.awake.color}
          stackId={SleepState.AWAKE}
        />
        <Area
          dataKey={SleepState.LIGHT}
          type="natural"
          fill={sleepLineChartConfig.light.color}
          stroke={sleepLineChartConfig.light.color}
          stackId={SleepState.LIGHT}
        />
        <Area
          dataKey={SleepState.DEEP}
          type="natural"
          fill={sleepLineChartConfig.deep.color}
          stroke={sleepLineChartConfig.deep.color}
          stackId={SleepState.DEEP}
        />
        <Area
          dataKey={SleepState.OFFBED}
          type="natural"
          fill={sleepLineChartConfig.offbed.color}
          stroke={sleepLineChartConfig.offbed.color}
          stackId={SleepState.OFFBED}
        />
        <Area
          dataKey={SleepState.OFFLINE}
          type="natural"
          fill={sleepLineChartConfig.offline.color}
          stroke={sleepLineChartConfig.offline.color}
          stackId={SleepState.OFFLINE}
        />
      </AreaChart>
    </ChartContainer>
  );
}
