import Motel from "@/assets/images/propertyManagement/white-building.svg";
import FormNext from "@/components/Button/FormNext";
import FilterDropdown from "@/components/Input/FilterDropdown";
import TextInput2 from "@/components/Input/TextInput2";
import { mockArea, mockDistrict, mockTime } from "@/lib/mock";
import { ChangeEvent, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import { isoStringToDateJs } from "@/libs/helper";
import DateIcon from "@/assets/images/eventManagement/date-piacker-after-add-on.svg";
import DatePickerInput from "@/components/Input/DatePickerInput";
import { Dayjs } from "dayjs";

const CustomDiv = styled.div<{ $isDateFilled?: boolean }>`
  margin-top: auto;
  margin-bottom: auto;
  & label[class$="-MuiFormLabel-root-MuiInputLabel-root"] {
    background-color: "white";
  }
`;

const CustomFilterDropdownDiv = styled.div`
  & div[class$="-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
    border-radius: 9px;
    width: 100%;
  }
  & div[class$="-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"] {
    padding: 9px 14px;
  }
  & label {
    color: black;
  }
`;

const CustomDateDiv = styled.div<{ $isDateFilled?: boolean }>`
  &
    div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary[class$="-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
    border-radius: 9px;
    background-color: ${(props) =>
      props.$isDateFilled ? "transparent" : "#F5F5F5"};
  }
`;
type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};
const EventOtherLocation = ({ mode = "NEW", onNext, onBack }: Props) => {
  const { t } = useTranslation(["eventManagement", "app"]);
  const methods = useFormContext();
  const { setValue, getValues, control, watch } = methods;

  useEffect(() => {
    console.log("[EventOtherLocation] Mode : ", mode);
    const startDt = getValues("startDt");
    const endDt = getValues("endDt");

    // Check type of start and end time if coming back from next step
    // If coming back from non confirm page, use react-hook-form stored value directly
    // If coming back from confirm page, ISO string needs to change back to number
    if (startDt != null && typeof startDt !== "number") {
      setValue("startDt", null);
    }
    // if (startDt != null && typeof startDt === "string") {
    //   setValue("startDt", isoStringToDateJs(startDt).hour());
    // }
    if (endDt != null && typeof endDt !== "number") {
      setValue("endDt", null);
    }
    // if (startDt != null && typeof startDt === "string") {
    //   setValue("endDt", isoStringToDateJs(endDt).hour());
    // }
  }, [getValues, mode, setValue]);
  return (
    <section className="min-w-[300px] max-w-[1200px] flex flex-col content-content justify-start gap-y-16 p-8 w-full">
      <div className="flex flex-row flex-wrap justify-between">
        <div className="font-bold text-lg">
          {t(
            "Event Management.Other Location.Provide a full address for your event location"
          )}
        </div>
        <div>
          <a
            className="text-[#FF780A] font-bold hover:underline hover:text-[#FF780A]"
            href="#"
          >
            {t("Event Management.Other Location.Location at JCCCP")}
          </a>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <img
          src={Motel}
          alt={t("Event Management.Other Location.Other Location")}
        />
      </div>
      <div className="px-4 flex flex-col gap-4">
        <div className="event-settings-address">
          <Controller
            control={control}
            name="address" // field name TBC, ask backend
            defaultValue=""
            render={({
              field: { value, onBlur, name },
              fieldState: { error },
            }) => {
              return (
                <TextInput2
                  size="large"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue("address", e.target.value);
                    console.log("address", getValues("address"));
                  }}
                  value={value ?? ""}
                  onBlur={onBlur}
                  defaultValue=""
                  name={name}
                  label={t("Event Management.Other Location.Address")}
                  placeholder={t("Event Management.Other Location.Address")}
                  error={error?.message ?? ""}
                  allowClear
                />
              );
            }}
          />
        </div>
        <div className="event-settings-street">
          <Controller
            control={control}
            name="street" // field name TBC, ask backend
            defaultValue=""
            render={({
              field: { value, onBlur, name },
              fieldState: { error },
            }) => {
              return (
                <TextInput2
                  size="large"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue("street", e.target.value);
                    console.log("street", getValues("street"));
                  }}
                  value={value ?? ""}
                  onBlur={onBlur}
                  defaultValue=""
                  name={name}
                  label={t("Event Management.Other Location.Street")}
                  placeholder={t("Event Management.Other Location.Street")}
                  error={error?.message ?? ""}
                  allowClear
                />
              );
            }}
          />
        </div>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <CustomDiv className="basis-1/2">
            <CustomFilterDropdownDiv>
              <Controller
                control={control}
                name="district"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      borderStyle="solid"
                      placeholder={t(
                        "Event Management.Other Location.District"
                      )}
                      options={mockDistrict}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        if (
                          mockDistrict.filter(
                            (district) => district.value === e.target.value
                          )[0].area === "nt"
                        ) {
                          setValue("area", "nt");
                        }
                        if (
                          mockDistrict.filter(
                            (district) => district.value === e.target.value
                          )[0].area === "kln"
                        ) {
                          setValue("area", "kln");
                        }
                        if (
                          mockDistrict.filter(
                            (district) => district.value === e.target.value
                          )[0].area === "hk"
                        ) {
                          setValue("area", "hk");
                        }
                        setValue("district", e.target.value);
                        onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
            </CustomFilterDropdownDiv>
          </CustomDiv>
          <CustomDiv className="basis-1/2">
            <CustomFilterDropdownDiv>
              <Controller
                control={control}
                name="area"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      borderStyle="solid"
                      placeholder={t("Event Management.Other Location.Area")}
                      options={mockArea}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setValue("district", null);
                        onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
            </CustomFilterDropdownDiv>
          </CustomDiv>
        </div>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <CustomDiv className="basis-1/2">
            <Controller
              control={control}
              name="eventDate"
              render={({
                field: { value, disabled, onBlur, name, onChange },
                fieldState: { error },
              }) => {
                return (
                  <DatePickerInput
                    suffixIcon={<img alt="" src={DateIcon} />}
                    onBlur={onBlur}
                    disabled={disabled}
                    value={value ?? ""}
                    name={name}
                    label={t("Event Management.Settings.Event Date")}
                    placeholder={t("Event Management.Settings.Event Date")}
                    error={error?.message ?? ""}
                    onChange={(e: Dayjs) => {
                      onChange(e);
                    }}
                    clearIcon={false}
                  />
                );
              }}
            />
          </CustomDiv>
          <div className="basis-1/2 flex flex-row gap-4">
            <CustomDateDiv
              className="basis-1/2"
              $isDateFilled={watch("eventDate") != null}
            >
              <CustomFilterDropdownDiv>
                <Controller
                  defaultValue={null}
                  control={control}
                  name="startDt"
                  render={({ field: { value } }) => {
                    return (
                      <FilterDropdown
                        disabled={watch("eventDate") == null}
                        value={value ?? ""}
                        borderStyle="solid"
                        placeholder={t(
                          "Event Management.Settings.Event Start Time"
                        )}
                        options={mockTime}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          if (watch("eventDate") == null) return;
                          setValue("startDt", e.target.value);
                        }}
                      />
                    );
                  }}
                />
              </CustomFilterDropdownDiv>
            </CustomDateDiv>

            <CustomDateDiv
              className="basis-1/2 items-center"
              $isDateFilled={watch("eventDate") != null}
            >
              <CustomFilterDropdownDiv>
                <Controller
                  control={control}
                  defaultValue={null}
                  name="endDt"
                  render={({ field: { value } }) => {
                    return (
                      <FilterDropdown
                        disabled={watch("eventDate") == null}
                        value={value ?? ""}
                        borderStyle="solid"
                        placeholder={t(
                          "Event Management.Settings.Event End Time"
                        )}
                        options={mockTime}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          if (watch("eventDate") == null) return;
                          setValue("endDt", e.target.value);
                        }}
                      />
                    );
                  }}
                />
              </CustomFilterDropdownDiv>
            </CustomDateDiv>
          </div>
        </div>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <CustomDiv className="basis-1/2">
            <Controller
              control={control}
              name="eventPublishDate"
              render={({
                field: { value, disabled, onBlur, name, onChange },
                fieldState: { error },
              }) => {
                return (
                  <DatePickerInput
                    suffixIcon={<img alt="" src={DateIcon} />}
                    onBlur={onBlur}
                    disabled={disabled}
                    value={value ?? ""}
                    name={name}
                    label={t("Event Management.Settings.Publish Date")}
                    placeholder={t("Event Management.Settings.Publish Date")}
                    error={error?.message ?? ""}
                    onChange={(e: Dayjs) => {
                      onChange(e);
                    }}
                    clearIcon={false}
                  />
                );
              }}
            />
          </CustomDiv>
          <CustomDateDiv
            className="basis-1/2 items-center"
            $isDateFilled={watch("eventPublishDate") != null}
          >
            <CustomFilterDropdownDiv>
              <Controller
                control={control}
                name="eventPublishTime"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      borderStyle="solid"
                      placeholder={t("Event Management.Settings.Publish Time")}
                      options={mockTime}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setValue("eventPublishTime", null);
                        onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
            </CustomFilterDropdownDiv>
          </CustomDateDiv>
        </div>
        <div className="flex gap-4 justify-center">
          {onBack && (
            <FormNext
              disabled={watch("venueType") == null}
              htmlType="button"
              type="default"
              onClick={() => {
                onBack();
              }}
            >
              {t("Back", { ns: "onboard" })}
            </FormNext>
          )}
          {onNext && (
            <FormNext
              htmlType="button"
              onClick={() => {
                onNext();
              }}
            >
              {t("Next", { ns: "app" })}
            </FormNext>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventOtherLocation;
