/* eslint-disable react-refresh/only-export-components */
import Loading from "@/components/Loading/Loading";
import { checkLoggedIn } from "@/lib/helper";
import loadable from "@loadable/component";
import { Trans } from "react-i18next";

const SessionManagement = loadable(
  () => import("@/views/sessionManagement/SessionManagement"),
  {
    fallback: <Loading isLoading={true} />,
  }
);

const AllSession = loadable(
  () => import("@/views/sessionManagement/AllSession"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const NewSession = loadable(
  () => import("@/views/sessionManagement/NewSession/NewSession"),
  {
    fallback: <Loading isLoading={true} />,
  }
);

const sessionManagement = () => {
  void checkLoggedIn();
  return [
    {
      path: "/session-management",
      element: <SessionManagement />,
      handle: {
        crumb: () => {
          return {
            display: (
              <Trans
                ns="app"
                i18nKey="SidebarMenu.Session management.Session Management"
              />
            ),
            route: `/session-management`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
      children: [
        {
          path: `app-session`,
          element: <AllSession />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans
                    ns="app"
                    i18nKey="SidebarMenu.Session management.App Session"
                  />
                ),
                route: `/session-management/app-session`,
              };
            },
          },
        },
        {
          path: `new`,
          element: <NewSession />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans
                    ns="app"
                    i18nKey="SidebarMenu.Session management.New"
                  />
                ),
                route: `/session-management/new`,
              };
            },
          },
        },
      ],
    },
  ];
};

export default sessionManagement;
