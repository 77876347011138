import {
  CommonResponse,
  ListWithPaginationResponse,
} from "@/types/api/APIResponse";
import {
  CreateEventRequest,
  EventListWithPaginationResponse,
  EventResponse,
  UpdateEventRequest,
} from "@/types/api/event/Event";
import {
  AddressInformation,
  ApplicationListWithPaginationResponse,
  ApplicationPrams,
  ApplicationResponse,
  OnboardingApplicationSubmissionRequest,
  PersonalInformation,
  SocialWorker,
  UpdateAddressInformation,
  UpdateApplicationRequest,
  UpdateContactPerson,
  UpdatePersonalInformation,
  UpdateQuestionnaire,
  UpdateRoomPreference,
  UpdateSocialWorker,
} from "@/types/api/onboarding/Application";
import { FetchStaffsParams, Staff } from "@/types/api/system/Staff";
import {
  AzureADLoginRequest,
  LoginRequest,
  LoginResponse,
} from "@/types/api/uaa/Login";
import { Unit } from "@/types/api/unit/Unit";
import {
  CreatePropertyRequest,
  Property,
} from "@/types/propertyManagement/Property";
import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { logout } from "../redux/slices/appSlice";
import store from "../redux/store";
import { ProfileIOTResponse } from "@/types/api/profile/UserProfile";

const API = {
  apiInstance: axios.create({
    baseURL: import.meta.env.VITE_API,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      ...(import.meta.env.VITE_ENV === "development" && {
        "Access-Control-Allow-Origin": "*",
      }),
    },
  }),

  API_PATH: {
    EVENT: {
      VIEW_EVENT: (id: string) => `/property-mgt/events/${id}`,
      LIST_EVENTS: "/property-mgt/events",
      CREATE_EVENT: "/property-mgt/events",
      UPDATE_EVENT: (id: string) => `/property-mgt/events/${id}`,
      APPROVE_EVENT: (id: string) => `/property-mgt/events/${id}/status`,
      REJECT_EVENT: (id: string) => `/property-mgt/events/${id}/status`,
    },
    PROFILE: {
      CREATE_PROFILE: "/profile-mgt/profiles",
      LIST_PROFILES: "/profile-mgt/profiles",
      VIEW_PROFILE: (id: string) => `/profile-mgt/profiles/${id}`,
      UPDATE_PROFILE: (id: string) => `/profile-mgt/profile/${id}`,
      VIEW_PROFILE_IOT: (id: string) =>
        `/iot/v1/sleep/pad/users/${id}/sleeping/statistics`,
    },
    PROPERTY: {
      CREATE_PROPERTY: "/property-mgt/properties",
      LIST_PROPERTIES: `/property-mgt/properties`,
      VIEW_PROPERTY: (id: string) => `/property-mgt/properties/${id}`,
    },
    UNIT: {
      LIST_UNITS: (
        propertyId?: string,
        statuses?: string,
        page?: number,
        size?: number,
        sort?: string,
        unitType?: string
      ) =>
        `/property-mgt/units?statuses=${statuses}&page=${page}&size=${size}&sort=${sort}&propertyId=${propertyId}&unitType=${unitType}`,
    },
    UAA: {
      CREATE_USER: "/uaa/users",
      REFRESH_TOKEN: "/uaa/oauth2/token",
      LOGIN: "/uaa/oauth2/token",
      INITIATE_RESET_PASSWORD: "/uaa/users/credentials/reset",
      VALIDATE_OTP: "/uaa/users/credentials/reset/validations",
      RESET_PASSWORD: "/uaa/users/credentials",
      RESEND_OTP: "/uaa/users/credentials/reset/one-time-passwords",
    },
    ONBOARDING: {
      LIST_APPLICATIONS: "/onboarding/applications",
      VIEW_APPLICATION: (id: string) => `/onboarding/applications/${id}`,
      UPDATE_APPLICATION: (id: string) => `/onboarding/applications/${id}`,
      CREATE_APPLICATION: "/onboarding/applications",
      SUBMIT: (id: string) => `/onboarding/applications/${id}/submissions`,
      REJECT: (id: string) => `/onboarding/mgt/applications/${id}/reject`,
      COMPLETE: (id: string) => `/onboarding/mgt/applications/${id}/approval`,
      WAITING_BED_ASSIGNMENT: (id: string) =>
        `/onboarding/mgt/applications/${id}/bed-waiting-list`,
      UPDATE_PERSONAL_INFORMATION: (id: string) =>
        `/onboarding/application-forms/${id}/personal-information`,
      UPDATE_ADDRESS_INFORMATION: (id: string) =>
        `/onboarding/application-forms/${id}/address-information`,
      UPDATE_CONTACT_PERSON: (id: string) =>
        `/onboarding/application-forms/${id}/contact-person`,
      UPDATE_ROOM_PREFERENCE: (id: string) =>
        `/onboarding/application-forms/${id}/room-preference`,
      UPDATE_QUESTIONNAIRE: (id: string) =>
        `/onboarding/application-forms/${id}/questionnaire`,
      UPDATE_SOCIAL_WORKER: (id: string) =>
        `/onboarding/application-forms/${id}/social-worker`,
      SUBMIT_APPLICATION: "/onboarding/applications/submissions",
      SCHEDULE_INTERVIEW: (id: string) =>
        `/onboarding/mgt/applications/${id}/interview-invitations`,
      // INTERVIEW_ACTION: (hash: string) =>
      //   `/onboarding/mgt/applications/${hash}/interview-confirmations`,
      SEND_OUT_REFERRAL: (id: string) =>
        `/onboarding/mgt/applications/${id}/referral`,
      SEND_OUT_OFFER: (id: string) =>
        `/onboarding/mgt/applications/${id}/approval`,
      ASSIGN_BED: (id: string) =>
        `/onboarding/mgt/applications/${id}/bed-assign`,
      REMARK_APPLICATION: (id: string) =>
        `/onboarding/mgt/applications/${id}/remark`,
    },
    SESSION: {
      LIST_SESSIONS: "/system-config/v1/event/sessions/search",
      CREATE_SESSION: "/system-config/v1/event/sessions",
      UPDATE_SESSION: (id: string) => `/system-config/v1/event/sessions/${id}`,
    },
    SYSTEM: {
      LIST_STAFF: "/profile-mgt/staffs/search",
      VIEW_STAFF: (id: string) => `/profile-mgt/staffs/${id}`,
      CREATE_STAFF: "/profile-mgt/staffs",
    },
    UTIL: {
      UPLOAD_FILE: "/util/cdn/files/customize-upload",
    },
  },
  event: {
    viewEvent: (id: string): Promise<AxiosResponse<EventResponse>> => {
      return API.apiInstance.get(API.API_PATH.EVENT.VIEW_EVENT(id));
    },
    listEvents: (params?: {
      page?: number;
      size?: number;
      id?: string;
      title?: string;
      createDt?: string;
      updateDt?: string;
      query?: string;
      statuses?: string;
      eventTypes?: string;
      venueType?: string;
      sort?: string;
    }): Promise<AxiosResponse<EventListWithPaginationResponse>> => {
      return API.apiInstance.get(API.API_PATH.EVENT.LIST_EVENTS, {
        params,
      });
    },
    createEvent: (payload: CreateEventRequest) => {
      return API.apiInstance.post(API.API_PATH.EVENT.CREATE_EVENT, payload);
    },
    updateEvent: (id: string, payload: UpdateEventRequest) => {
      return API.apiInstance.put(API.API_PATH.EVENT.UPDATE_EVENT(id), payload);
    },
    approveEvent: (id: string) => {
      return API.apiInstance.put(API.API_PATH.EVENT.APPROVE_EVENT(id), {
        status: "ACTIVE",
      });
    },
    rejectEvent: (id: string) => {
      return API.apiInstance.put(API.API_PATH.EVENT.REJECT_EVENT(id), {
        status: "INACTIVE",
      });
    },
  },
  profile: {
    viewProfile: (id: string) => {
      return API.apiInstance.get(API.API_PATH.PROFILE.VIEW_PROFILE(id));
    },
    listProfiles: (params?: {
      statuses?: string;
      startDt?: string;
      endDt?: string;
      userId?: string;
      phone?: string;
      idNumber?: string;
      email?: string;
      socialWorkerProfileId?: string;
      firstName?: string;
      lastName?: string;
      name?: string;
      types?: string;
      query?: string;
      page?: number;
      size?: number;
      sort?: string;
    }) => {
      return API.apiInstance.get(API.API_PATH.PROFILE.LIST_PROFILES, {
        params,
      });
    },
    createProfile: (payload: { name: string }) => {
      return API.apiInstance.post(API.API_PATH.PROFILE.CREATE_PROFILE, payload);
    },
    updateProfile: (id: string, payload: { name: string }) => {
      return API.apiInstance.put(
        API.API_PATH.PROFILE.UPDATE_PROFILE(id),
        payload
      );
    },
    viewProfileIOT: (
      id: string,
      params?: { date?: string }
    ): Promise<AxiosResponse<CommonResponse<ProfileIOTResponse>>> => {
      return API.apiInstance.get(API.API_PATH.PROFILE.VIEW_PROFILE_IOT(id), {
        params,
      });
    },
  },
  property: {
    createProperty: (
      payload: CreatePropertyRequest
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<AxiosResponse<CommonResponse<any>>> => {
      return API.apiInstance.post(
        API.API_PATH.PROPERTY.CREATE_PROPERTY,
        payload
      );
    },
    listProperties: (params?: {
      name?: string;
      page?: number;
      size?: number;
      query?: string;
      statuses?: string;
      sort?: string;
    }): Promise<AxiosResponse<ListWithPaginationResponse<Property>>> => {
      return API.apiInstance.get(API.API_PATH.PROPERTY.LIST_PROPERTIES, {
        params,
      });
    },
    viewProperty: (id: string) => {
      return API.apiInstance.get(API.API_PATH.PROPERTY.VIEW_PROPERTY(id));
    },
  },
  unit: {
    listUnits: (params?: {
      propertyId?: string;
      name?: string;
      page?: number;
      size?: number;
      query?: string;
      statuses?: string;
      sort?: string;
      unitType?: string;
      block?: string;
      floor?: string;
      room?: string;
      unit?: string;
    }): Promise<AxiosResponse<ListWithPaginationResponse<Unit>>> => {
      return API.apiInstance.get(
        API.API_PATH.UNIT.LIST_UNITS(
          params?.propertyId,
          params?.statuses,
          params?.page,
          params?.size,
          params?.sort,
          params?.unitType
        )
      );
    },
  },
  uaa: {
    initiateResetPassword: (payload: { username: string }) => {
      return API.apiInstance.post(
        API.API_PATH.UAA.INITIATE_RESET_PASSWORD,
        payload,
        {
          headers: {
            Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
          },
        }
      );
    },
    resendOtp: (payload: { username: string }) => {
      return API.apiInstance.put(API.API_PATH.UAA.RESEND_OTP, payload, {
        headers: {
          Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
        },
      });
    },
    validateOtp: (payload: { username: string; code: string }) => {
      return API.apiInstance.post(API.API_PATH.UAA.VALIDATE_OTP, payload, {
        headers: {
          Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
        },
      });
    },
    resetPassword: (payload: { username: string; credentials: string }) => {
      return API.apiInstance.patch(API.API_PATH.UAA.RESET_PASSWORD, payload, {
        headers: {
          Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
        },
      });
    },
    refreshToken: (refreshToken: string) => {
      return API.apiInstance.post(
        API.API_PATH.UAA.REFRESH_TOKEN,
        // Automatic serialization by Axios
        {
          grant_type: "refresh-token",
          refresh_token: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic Y2xpZW50OnNlY3JldA==",
          },
        }
      );
    },
    login: (
      payload: LoginRequest | AzureADLoginRequest,
      grantType = "custom-password-grant"
    ): Promise<AxiosResponse<LoginResponse>> => {
      return API.apiInstance.post(
        API.API_PATH.UAA.LOGIN,
        {
          grant_type: grantType,
          ...payload,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic Y2xpZW50OnNlY3JldA==",
          },
        }
      );
    },
  },
  onboarding: {
    listApplications: (
      params?: ApplicationPrams
    ): Promise<AxiosResponse<ApplicationListWithPaginationResponse>> => {
      return API.apiInstance.get(API.API_PATH.ONBOARDING.LIST_APPLICATIONS, {
        params,
      });
    },
    viewApplication: (
      id: string
    ): Promise<AxiosResponse<ApplicationResponse>> => {
      return API.apiInstance.get(API.API_PATH.ONBOARDING.VIEW_APPLICATION(id));
    },
    updateApplication: (id: string, payload: UpdateApplicationRequest) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_APPLICATION(id),
        payload
      );
    },
    updatePersonalInformation: (
      id: string,
      payload: UpdatePersonalInformation
    ) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_PERSONAL_INFORMATION(id),
        payload
      );
    },
    updateAddressInformation: (
      id: string,
      payload: UpdateAddressInformation
    ) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_ADDRESS_INFORMATION(id),
        payload
      );
    },
    updateContactPerson: (id: string, payload: UpdateContactPerson) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_CONTACT_PERSON(id),
        payload
      );
    },
    updateRoomPreference: (id: string, payload: UpdateRoomPreference) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_ROOM_PREFERENCE(id),
        payload
      );
    },
    updateQuestionnaire: (id: string, payload: UpdateQuestionnaire) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_QUESTIONNAIRE(id),
        payload
      );
    },
    updateSocialWorker: (id: string, payload: UpdateSocialWorker) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.UPDATE_SOCIAL_WORKER(id),
        payload
      );
    },
    submitApplication: ({
      assignBed,
      form,
    }: {
      assignBed: {
        targetId: string;
        startDt: string;
        endDt: string;
      };
      form: {
        personalInformation: PersonalInformation;
        addressInformation: AddressInformation;
        socialWorker: SocialWorker;
      };
    }) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.SUBMIT_APPLICATION, {
        assignBed,
        form,
      });
    },
    submit: (id: string, payload: OnboardingApplicationSubmissionRequest) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.SUBMIT(id), payload);
    },
    scheduleInterview: (
      id: string,
      payload: {
        interviewDtOptions: string[];
      }
    ) => {
      return API.apiInstance.post(
        API.API_PATH.ONBOARDING.SCHEDULE_INTERVIEW(id),
        { ...payload }
      );
    },
    // interviewAction: (hash: string, action: string) => {
    //   return API.apiInstance.put(
    //     API.API_PATH.ONBOARDING.INTERVIEW_ACTION(hash),
    //     { status: action }
    //   );
    // },
    rejectApplication: (id: string) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.REJECT(id));
    },
    completeApplication: (id: string) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.COMPLETE(id));
    },
    sendOutReferral: (id: string) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.SEND_OUT_REFERRAL(id));
    },
    sendOutOffer: (id: string) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.SEND_OUT_OFFER(id));
    },
    assignBed: ({
      id,
      targetId,
      startDt,
      endDt,
    }: {
      id: string;
      targetId: string;
      startDt: string;
      endDt: string;
    }) => {
      return API.apiInstance.put(API.API_PATH.ONBOARDING.ASSIGN_BED(id), {
        targetId,
        startDt,
        endDt,
      });
    },
    waitingBedAssignment: (id: string) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.WAITING_BED_ASSIGNMENT(id)
      );
    },
    remarkApplication: (id: string, payload: { remark: string }) => {
      return API.apiInstance.put(
        API.API_PATH.ONBOARDING.REMARK_APPLICATION(id),
        payload
      );
    },
  },
  questionnaire: {
    getApplications: async () => {
      return {
        data: {
          code: "SYS0000",
          data: [
            {
              question: {
                en: "Why are you interested in living in JCCCP?",
                "zh-HK": "Why are you interested in living in JCCCP?",
              },
              placeholder: {
                en: "Enter your answer",
                "zh-HK": "請入您的答案",
              },
              type: "text",
            },
          ],
        },
      };
    },
  },
  session: {
    listSessions: (params?: {
      query?: string;
      status?: string;
      type?: string;
      sort?: string;
    }) => {
      return API.apiInstance.get(API.API_PATH.SESSION.LIST_SESSIONS, {
        params,
      });
    },
    createSession: (payload: FormData) => {
      return API.apiInstance.post(
        API.API_PATH.SESSION.CREATE_SESSION,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    updateSession: (id: string, payload: FormData) => {
      return API.apiInstance.put(
        API.API_PATH.SESSION.UPDATE_SESSION(id),
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
  system: {
    listStaffs: (
      params?: FetchStaffsParams
    ): Promise<AxiosResponse<ListWithPaginationResponse<Staff>>> => {
      return API.apiInstance.get(API.API_PATH.SYSTEM.LIST_STAFF, { params });
    },
    viewStaff: (id: string) => {
      return API.apiInstance.get(API.API_PATH.SYSTEM.VIEW_STAFF(id));
    },
    createStaff: (payload: FormData) => {
      return API.apiInstance.post(API.API_PATH.SYSTEM.CREATE_STAFF, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
  util: {
    uploadFile: async (file: File, customType: string, feature: string) => {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("custom-type", customType);
      formData.append("feature", feature);
      return API.apiInstance.post(API.API_PATH.UTIL.UPLOAD_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};

API.apiInstance.defaults.withCredentials = true;

API.apiInstance.interceptors.request.use((config) => {
  // Do not override Authorization header if it is in login/forgot password
  if (
    [
      API.API_PATH.UAA.LOGIN,
      API.API_PATH.UAA.REFRESH_TOKEN,
      API.API_PATH.UAA.INITIATE_RESET_PASSWORD,
      API.API_PATH.UAA.VALIDATE_OTP,
      API.API_PATH.UAA.RESET_PASSWORD,
      API.API_PATH.UAA.RESEND_OTP,
    ].includes(config.url ?? "")
  ) {
    return config;
  }
  const token = Cookies.get("API_TOKEN");
  if (token) {
    if (config && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
});

// Add this flag to track if we're currently refreshing the token
let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}> = [];

// Helper function to process failed requests queue
const processQueue = (error: unknown = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

API.apiInstance.interceptors.response.use(
  (res) => {
    if (res.data.code !== "SYS0000" && res.data["access_token"] === undefined) {
      throw new AxiosError(
        res.data.message,
        res.data.code,
        res.config,
        res.request,
        res
      );
    }
    return Promise.resolve(res);
  },
  async (err) => {
    const originalRequest = err.config;

    // Handle 401 errors
    if (err?.response?.status === 401) {
      // If it's not a refresh token request and we're not already retrying
      if (
        !originalRequest._retry &&
        !originalRequest.url?.includes(API.API_PATH.UAA.REFRESH_TOKEN)
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return API.apiInstance(originalRequest);
            })
            .catch((err) => {
              handleLogout();
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const refreshToken = Cookies.get("REFRESH_TOKEN");
          if (!refreshToken) {
            throw new Error("No refresh token available");
          }

          const response = await API.uaa.refreshToken(refreshToken);
          const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
            response.data.data;

          Cookies.set("API_TOKEN", newAccessToken);
          Cookies.set("REFRESH_TOKEN", newRefreshToken);

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          processQueue();
          return API.apiInstance(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError);
          handleLogout();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        // If we get here, it means either:
        // 1. The refresh token request itself failed
        // 2. We already tried refreshing and still got 401
        handleLogout();
      }
    }

    return Promise.reject(err);
  }
);

// Helper function to handle logout
const handleLogout = () => {
  // Clear all tokens
  Cookies.remove("API_TOKEN");
  Cookies.remove("REFRESH_TOKEN");

  // Only dispatch logout if user is logged in
  if (store.getState().app.loggedIn) {
    store.dispatch(logout());
    window.location.reload();
  }
};

export default API;
