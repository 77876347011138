import { Tabs } from "antd";
import styled from "styled-components";
import colors from "@/theme/default/colors";
import { type TabsProps } from "antd/lib/tabs";

const CustomTabs = styled(Tabs)`
  & .ant-tabs-tab-with-remove {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom-color: inherit !important;
    background: transparent !important;
  }
  & .ant-tabs-tab-active {
    color: ${colors.tabs.activeColor} !important;
    border-bottom-color: ${colors.tabs.activeColor} !important;
    border-bottom-width: 1px !important;
    & .ant-tabs-tab-btn {
      color: ${colors.tabs.activeColor} !important;
    }
  }
`;

const Tabs1 = (props: TabsProps) => {
  return <CustomTabs hideAdd type="editable-card" {...props} />;
};

export default Tabs1;
