import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DummyPicture from "@/assets/images/eventManagement/dummy-picture.png";
import { useFormContext } from "react-hook-form";
import API from "@/lib/api";

type Props = {
  fileTypeCaption: string;
  readonly?: boolean;
  file?: File | null;
};

function ImagePreviewLoader(props: Props) {
  const { t } = useTranslation(["eventManagement", "app"]);
  const { fileTypeCaption } = props;
  const [, setDragging] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const methods = useFormContext();
  const { setValue, getValues } = methods;
  const iconRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (file: File) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      const res = await API.util.uploadFile(file, "FEATURE", "EVENT_DOC");
      console.log(res.data.data[0].link.url);
      setValue("heroImage", res.data.data[0].link.url);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    if (iconRef.current) {
      iconRef.current.click();
    }
  };

  const validateFile = (file: File | null) => {
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please select an image file");
        return;
      } else if (file.size > 1000000) {
        setError("File size is too large");
        return;
      } else {
        setValue("heroImage", file);
        setError("");
      }
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    validateFile(file);
    if (file != null) {
      console.log("[file]", file);
      handleUpload(file);
      //   dispatch(setNewEventHeroImage(file));
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    console.log("[File]", file);
    validateFile(file);
  };

  const clearImage = () => {
    setValue("heroImage", null);
    setError("");
  };

  return (
    <section className="flex flex-col gap-y-4">
      {props.readonly ? (
        <>
          <div className="text-center flex flex-row justify-center items-center border-[#d9d9d9] bg-[#f5f5f5] border-[1px] border-solid rounded-[8px] min-h-[50px] p-4">
            <img
              // SampleEventYouth is for display purpose only
              // TODO: Replace with actual image stored in DB
              src={
                props.file
                  ? //check is url or file
                    typeof props.file === "string"
                    ? props.file
                    : URL.createObjectURL(props.file)
                  : DummyPicture
              }
              alt={t("Event Management.Settings.Hero Image")}
              width="100px"
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="text-center flex flex-row justify-center items-center border-[#ff8413] border-2 border-dashed rounded-[8px] min-h-[200px] p-4"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={onBtnClick}
          >
            <input
              accept="image/*"
              ref={iconRef}
              className="file-input"
              type="file"
              onChange={handleFileInput}
              name="file"
              hidden
              disabled={props.readonly}
            />
            {props.file ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={
                    props.file
                      ? typeof props.file === "string"
                        ? props.file
                        : URL.createObjectURL(props.file)
                      : DummyPicture
                  }
                  alt={t("Event Management.Settings.Hero Image")}
                  width="200px"
                />
              </div>
            ) : (
              <Trans
                className="text-[#737373]"
                i18nKey="DragAndDropUpload.Uploads.Drag and Drop or choose file(s) here"
                ns="app"
                values={{ fileTypeCaption: fileTypeCaption ?? "" }}
              />
            )}

            <div className="text-[red]">{error}</div>
          </div>
          <div
            hidden={getValues("heroIamge")}
            onClick={clearImage}
            className="text-center cursor-pointer text-[#ff8413] hover:underline"
          >
            {t("DragAndDropUpload.Uploads.Clear image", { ns: "app" })}
          </div>
        </>
      )}
    </section>
  );
}

export default ImagePreviewLoader;
