import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 25px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 100;
  justify-content: center;
  align-items: center;
`;
const UserArea = styled.div`
  display: flex;
  height: 50px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
`;

const UserIconWrapper = styled.div`
  color: #737373;
`;

const UserName = styled.span`
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const NotificationArea = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
`;

const NotificationIconWrapper = styled.div`
  color: #737373;
`;

const Navbar = () => {
  return (
    <Wrapper>
      <UserArea>
        <UserIconWrapper>
          <PersonIcon />
        </UserIconWrapper>
        <UserName>Staff 001</UserName>
      </UserArea>
      <NotificationArea>
        <NotificationIconWrapper>
          <Badge
            sx={{ "& .MuiBadge-badge": { background: "#E62E7B" } }}
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </NotificationIconWrapper>
      </NotificationArea>
    </Wrapper>
  );
};

export default Navbar;
