import { InputLabel, MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import DropDownArrow from "@/assets/images/iconamoon-arrow-down.png";
import DropUpArrow from "@/assets/images/iconamoon-arrow-up.png";
import { useState } from "react";
type Option = {
  label: string;
  value: string | number;
};

type Props = {
  // If using react-hook-form Form Controller, name is not reuqired here.
  // react-hook-form Controller has props of name
  disabled?: boolean;
  name?: string;
  options: Option[];
  multiple?: boolean;
  placeholder?: string;
  backgroundColor?: string;
  borderStyle?: "none" | "solid";
  minWidth?: string;
  [key: string]: unknown;
};

// Fixing bug of MUI select of changed arrow
const CustomDropdownArrow = styled.img`
  position: absolute;
  right: 0;
  pointer-events: none;
`;

const CustomSelect = styled(Select)`
  & .MuiSelect-select {
    padding-right: 0;
  }
`;

const FilterDropdown = (props: Props) => {
  const {
    backgroundColor,
    borderStyle,
    placeholder,
    options,
    multiple,
    name,
    disabled,
    minWidth,
    ...restProps
  } = props;
  const [dropdownImage, setDropdownImage] = useState<string>(DropDownArrow);
  return (
    <>
      <InputLabel
        sx={{ fontFamily: "Poppins" }}
        hidden={placeholder == null || placeholder == ""}
      >
        {placeholder}
      </InputLabel>
      <CustomSelect
        disabled={disabled ?? false}
        defaultValue={restProps.defaultValue ?? ""}
        value={restProps.value ?? ""}
        name={name}
        onOpen={() => {
          setDropdownImage(DropUpArrow);
        }}
        onClose={() => {
          setDropdownImage(DropDownArrow);
        }}
        IconComponent={() => (
          <CustomDropdownArrow
            // className="pr-2 cursor-default"
            alt=""
            src={dropdownImage}
          />
          // <MdKeyboardArrowDown height={32} width={32} className="cursor-none" />
        )}
        multiple={multiple ?? false}
        id="event-status-filter"
        sx={[
          {
            backgroundColor: disabled ? "#F5F5F5" : backgroundColor ?? "white",
            border: "transparent",
            borderRadius: "9px",
            // Before any selection, label is null so giving a min width
            minWidth: minWidth ?? "150px",
            width: "fit-content",
          },
          {
            "& fieldset": {
              borderStyle: borderStyle ? "solid" : "none",
              "&:hover": {
                borderStyle: "solid",
                borderColor: "#FF780A",
                borderWidth: "2px",
              },
            },
          },
        ]}
        {...restProps}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </>
  );
};
export default FilterDropdown;
