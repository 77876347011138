export enum ProfileStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}

export enum ProfileType {
  OHY = "OHY",
  PIR = "PIR",
  SOCIAL_WORKER = "SOCIAL_WORKER",
  STAFF = "STAFF",
  ALL = "ALL",
}

export enum SleepState {
  AWAKE = "awake",
  LIGHT = "light",
  DEEP = "deep",
  OFFBED = "offbed",
  OFFLINE = "offline",
}
