import EventIconUrl from "@/assets/images/eventManagement/progressBar/event-information.svg";
import ReviewIconUrl from "@/assets/images/eventManagement/progressBar/event-review.svg";
import EventSettingsIconUrl from "@/assets/images/eventManagement/progressBar/event-settings.svg";
import LocationIconUrl from "@/assets/images/propertyManagement/progressBar/Address.svg";
import CompleteIconUrl from "@/assets/images/propertyManagement/progressBar/Complete.svg";
import DotDotIconUrl from "@/assets/images/propertyManagement/progressBar/DotDot.svg";
import type Props from "@/types/components/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  /* @media (max-width: 1536px) {
    flex-direction: row;
    margin-bottom: 1rem;
  } */
`;

const Box = styled.div<{
  $step: number;
  $currentStep: number;
}>`
  // if the step is less than or equal to the current step, then drop the opacity
  opacity: ${({ $step, $currentStep }) => ($step <= $currentStep ? 1 : 0.5)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  row-gap: 10px;
  text-overflow: ellipsis;
`;

const CustomDiv = styled.div`
  overflow: hidden;
  // Change to wrap if not to see ellipsis
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ProgressBar = (props: Props) => {
  const { t } = useTranslation(["eventManagement"], {
    keyPrefix: "Event Management",
  });
  const { step: currentStep, progressBarHidden, hiddenSteps } = props;
  return (
    <Wrapper hidden={progressBarHidden}>
      {!hiddenSteps?.includes(1) && (
        <Box $step={1} $currentStep={currentStep}>
          <img
            src={LocationIconUrl}
            alt={t("Location.Location")}
            title={t("Location.Location")}
          />
          <CustomDiv>{t("Location.Location")}</CustomDiv>
        </Box>
      )}
      {!hiddenSteps?.includes(2) && (
        <Box $step={2} $currentStep={currentStep}>
          <img src={DotDotIconUrl} alt="DotDot" />
        </Box>
      )}
      {!hiddenSteps?.includes(2) && (
        <Box $step={2} $currentStep={currentStep}>
          <img
            src={EventIconUrl}
            title={t("Event Information")}
            alt={t("Event Information")}
          />
          <CustomDiv>{t("Event Information")}</CustomDiv>
        </Box>
      )}
      {!hiddenSteps?.includes(3) && (
        <Box $step={3} $currentStep={currentStep}>
          <img src={DotDotIconUrl} alt="DotDot" />
        </Box>
      )}
      {!hiddenSteps?.includes(3) && (
        <Box $step={3} $currentStep={currentStep}>
          <img
            src={EventSettingsIconUrl}
            alt={t("Event Settings")}
            title={t("Event Settings")}
          />
          <CustomDiv>{t("Event Settings")}</CustomDiv>
        </Box>
      )}
      {!hiddenSteps?.includes(4) && (
        <Box $step={4} $currentStep={currentStep}>
          <img src={DotDotIconUrl} alt="DotDot" />
        </Box>
      )}
      {!hiddenSteps?.includes(4) && (
        <Box $step={4} $currentStep={currentStep}>
          <img src={ReviewIconUrl} alt={t("Review")} title={t("Review")} />
          <CustomDiv>{t("Review")}</CustomDiv>
        </Box>
      )}
      {!hiddenSteps?.includes(5) && (
        <Box $step={5} $currentStep={currentStep}>
          <img src={DotDotIconUrl} alt="DotDot" />
        </Box>
      )}
      {!hiddenSteps?.includes(5) && (
        <Box $step={5} $currentStep={currentStep}>
          <img
            src={CompleteIconUrl}
            alt={t("Complete")}
            title={t("Complete")}
          />
          <CustomDiv>{t("Complete")}</CustomDiv>
        </Box>
      )}
    </Wrapper>
  );
};

export default ProgressBar;
