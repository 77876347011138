import i18n from "@/i18n/i18n";
import Hostel from "@/assets/images/propertyManagement/black-building.svg";
import Motel from "@/assets/images/propertyManagement/white-building.svg";
import { VenueType } from "@/types/enum/event";
import { ChartConfig } from "@/components/ui/chart";
import { SessionType } from "@/types/enum/session";

export const eventStatusVariantoptions = {
  "in-progress": {
    color: "white",
    backgroundColor: "#212AF3E5",
    border: "transparent",
    label: "Statuses.In Progress",
  },
  active: {
    color: "white",
    backgroundColor: "#5DC65BE5",
    border: "transparent",
    label: "Statuses.Active",
  },
  pending: {
    color: "white",
    backgroundColor: "#0000008f",
    border: "black",
    label: "Statuses.Pending",
  },
  inactive: {
    color: "white",
    backgroundColor: "#E86161E5",
    border: "transparent",
    label: "Statuses.Inactive",
  },
  past: {
    color: "white",
    backgroundColor: "#5B5B5BE5",
    border: "transparent",
    label: "Statuses.Past",
  },
};

export const mockArea = [
  { label: "Hong Kong", value: "hk" },
  { label: "Kowloon", value: "kln" },
  { label: "New Territories", value: "nt" },
];
export const mockDistrict = [
  { label: "Islands", value: "islands", area: "nt" },
  { label: "Kwai Tsing", value: "kwaiTsing", area: "nt" },
  { label: "North", value: "north", area: "nt" },
  { label: "Sai Kung", value: "saiKung", area: "nt" },
  { label: "Sha Tin", value: "shaTin", area: "nt" },
  { label: "Tai Po", value: "taiPo", area: "nt" },
  { label: "Tsuen Wan", value: "tsuenWan", area: "nt" },
  { label: "Tuen Mun", value: "tuenMun", area: "nt" },
  { label: "Yuen Long", value: "yuenLong", area: "nt" },
  { label: "Kowloon City", value: "kowloonCity", area: "kln" },
  { label: "Kwun Tong", value: "kwunTong", area: "kln" },
  { label: "Sham Shui Po", value: "shamShuiPo", area: "kln" },
  { label: "Wong Tai Sin", value: "wongTaiSin", area: "kln" },
  { label: "Yau Tsim Mong", value: "yauTsimMong", area: "kln" },
  { label: "Central and Western", value: "centralAndWestern", area: "hk" },
  { label: "Eastern", value: "eastern", area: "hk" },
  { label: "Southern", value: "southern", area: "hk" },
  { label: "Wan Chai", value: "wanChai", area: "hk" },
];

export const mockLocations = [
  {
    id: 2,
    location: "JCCCP Facility, 3202 ABC Building, Sha Tin",
    picture: Hostel,
    type: "INTERNAL_VENUE",
  },
  {
    id: 1,
    location: "Other Location",
    picture: Motel,
    type: "EXTERNAL_VENUE",
  },
];

export const venueTypeOptions = [
  { id: 0, label: "Virtual", value: VenueType.VIRTUAL, picture: Hostel },
  { id: 1, label: "Physical", value: "", picture: Motel },
];

export const mockView = [
  {
    label: i18n.t("Event Management.Location.Floor Plan View", {
      ns: "eventManagement",
    }),
    value: "FLOOR-PLAN-VIEW",
  },
  {
    label: i18n.t("Event Management.Location.Table View", {
      ns: "eventManagement",
    }),
    value: "TABLE-VIEW",
  },
];
export const mockTime = [
  { label: "00:00", value: 0 },
  { label: "01:00", value: 1 },
  { label: "02:00", value: 2 },
  { label: "03:00", value: 3 },
  { label: "04:00", value: 4 },
  { label: "05:00", value: 5 },
  { label: "06:00", value: 6 },
  { label: "07:00", value: 7 },
  { label: "08:00", value: 8 },
  { label: "09:00", value: 9 },
  { label: "10:00", value: 10 },
  { label: "11:00", value: 11 },
  { label: "12:00", value: 12 },
  { label: "13:00", value: 13 },
  { label: "14:00", value: 14 },
  { label: "15:00", value: 15 },
  { label: "16:00", value: 16 },
  { label: "17:00", value: 17 },
  { label: "18:00", value: 18 },
  { label: "19:00", value: 19 },
  { label: "20:00", value: 20 },
  { label: "21:00", value: 21 },
  { label: "22:00", value: 22 },
  { label: "23:00", value: 23 },
];
export const mockFloor = [
  { label: "Ground", value: 0 },
  { label: "1/F", value: 1 },
  { label: "2/F", value: 2 },
  { label: "3/F", value: 3 },
  { label: "4/F", value: 4 },
  { label: "5/F", value: 5 },
];
export const mockRoom = [
  { label: "", value: "no" },
  { label: "Room A", value: "A" },
  { label: "Room B", value: "B" },
  { label: "Room C", value: "C" },
  { label: "Room D", value: "D" },
  { label: "Room E", value: "E" },
  { label: "Room F", value: "F" },
];

export const mockStaff = [
  { staffName: "Staff 1", staffId: 1 },
  { staffName: "Staff 2", staffId: 2 },
  { staffName: "Staff 3", staffId: 3 },
  { staffName: "Staff 4", staffId: 4 },
  { staffName: "Staff 5", staffId: 5 },
  { staffName: "Staff 6", staffId: 6 },
  { staffName: "Staff 7", staffId: 7 },
];
export const mockGuest = [{ label: "Invite all", value: "ALL" }];
// SPORTS, ENTERTAINMENT, ART, EDUCATIONAL, OTHER
export const mockEventType = [
  { label: "Sports", value: "SPORTS" },
  { label: "Entertainment", value: "ENTERTAINMENT" },
  { label: "Art", value: "ART" },
  { label: "Educational", value: "EDUCATIONAL" },
  { label: "Others", value: "OTHER" },
];

export const sleepLineChartConfig = {
  awake: {
    label: "Awake",
    color: "#A37BD2",
  },
  light: {
    label: "Light",
    color: "#4B5E61",
  },
  deep: {
    label: "Deep",
    color: "#D3D3D3",
  },
  offbed: {
    label: "Offbed",
    color: "#7FD1D1",
  },
  offline: {
    label: "Offline",
    color: "#FFA500",
  },
} satisfies ChartConfig;
export const mockEvent = [
  { label: "First Time Login", value: "FIRST_TIME_LOGIN" },
];

export const mockOperator = [
  { label: "Occurred Exactly", value: "OCCURRED_EXACTLY" },
];

export const mockPatternTime = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const eventSessionType = [
  { label: "Full Screen", value: SessionType.FULL_SCREEN },
  { label: "Session", value: SessionType.SECTION },
  { label: "App Notification", value: SessionType.APP_NOTIFICATION },
];
