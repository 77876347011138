import styled from "styled-components";
import { DatePicker } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import colors from "@/theme/default/colors";

const CustomDatePicker = styled(DatePicker)`
  padding: 9px 14px;
  border-radius: 10px;
  width: 100%;
  & input {
    font-size: 16px !important;
  }
`;

const Label = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: ${colors.datePicker.error};
  .anticon {
    margin-right: 8px;
  }
`;

type Props = {
  label: string;
  error: string;
  [key: string]: unknown;
};

// ensuredForwardRef tyoe of ref is not working, so use react forwardRef
const DatePickerInput = ({ label, error, ...restProps }: Props) => {
  return (
    <>
      {label && <Label className="font-poppins">{label}</Label>}
      <CustomDatePicker
        placeholder={restProps["placeholder"] + ""}
        {...(error && { status: "error" })}
        format="D MMMM, YYYY"
        {...restProps}
      />
      {error ? (
        <Error>
          <CloseCircleFilled />
          {error ?? ""}
        </Error>
      ) : null}
    </>
  );
};

export default DatePickerInput;
