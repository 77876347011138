import Loading from "@/components/Loading/Loading";
import API from "@/lib/api";
import { login, setAPITokenCookie } from "@/redux/slices/appSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

const SSO = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("PROCESSING");
  //get query parameters
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(searchParams.get("token"));
  const token = searchParams.get("token") || null;

  //Make API call to exchange token for access token and refresh token
  const tokenExchange = async ({
    // accessToken,
    refreshToken,
  }: {
    // accessToken: string;
    refreshToken: string;
  }) => {
    try {
      const res = await API.uaa.refreshToken(refreshToken);
      const {
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
        idToken,
        expiresIn,
      } = res.data.data;
      //Save tokens to cookies
      dispatch(login());
      dispatch(
        setAPITokenCookie({
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          idToken: idToken,
          expiresInSecond: expiresIn,
        })
      );
      navigate("/dashboard", { replace: true });
      //Navigate to app
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      //Process the token and navigate to app
      //Decode the token
      const decodedToken = atob(token);
      const { accessToken, refreshToken } = JSON.parse(decodedToken);
      console.log("HUUUUUUUUU", refreshToken);
      console.log("HUUUUUUUUU", accessToken);
      if (!accessToken || !refreshToken) {
        setStatus("FAIL");
      } else {
        tokenExchange({
          //   accessToken,
          refreshToken,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token || status === "FAIL") {
    // Redirect to login page
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Loading />
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default SSO;
