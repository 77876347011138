import { Controller, useFormContext } from "react-hook-form";
import DummyPicture from "@/assets/images/eventManagement/dummy-picture.png";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { firstCapital } from "@/lib/reactUtil";
type Props = {
  title: string;
  description: string;
  imageLayout: "top" | "middle" | "topRight";
  eventType: string;
};
const previewHeroImageWidth = "200px";

const Preview = ({ title, description, eventType, imageLayout }: Props) => {
  const { t } = useTranslation(["eventManagement", "app"]);
  const methods = useFormContext();
  const { control, watch } = methods;

  if (imageLayout === "topRight") {
    return (
      <div className="border-[#E0E0E0] border-[1px] flex flex-col justify-center p-[1rem] gap-y-4">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col justify-center gap-y-[0.125rem] basis-2/5">
            <h2 className="information-title font-bold text-center">
              {Parser(title ?? "Title")}
            </h2>
          </div>
          <div className="flex flex-row justify-center content-center basis-3/5">
            <Controller
              control={control}
              name="heroImage"
              render={() => {
                return (
                  <img
                    className=""
                    alt=""
                    src={
                      watch("heroImage")
                        ? typeof watch("heroImage") === "string"
                          ? watch("heroImage")
                          : URL.createObjectURL(watch("heroImage"))
                        : DummyPicture
                    }
                    width={previewHeroImageWidth}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="information-description text-center max-w-[300px] md:max-w-[450px]">
          {Parser(description ?? <></>)}
        </div>
        <div className="text-center font-bold">
          {firstCapital(eventType) ?? t("All Event.Event Type")}
        </div>
      </div>
    );
  }

  if (imageLayout === "top") {
    return (
      <div className="border-[#E0E0E0] border-[1px] flex flex-col justify-center p-[1rem] gap-y-4">
        <div className="flex flex-row justify-center">
          <Controller
            control={control}
            name="heroImage"
            render={() => {
              return (
                <img
                  alt=""
                  src={
                    watch("heroImage")
                      ? typeof watch("heroImage") === "string"
                        ? watch("heroImage")
                        : URL.createObjectURL(watch("heroImage"))
                      : DummyPicture
                  }
                  width={previewHeroImageWidth}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col content-center gap-y-[0.125rem]">
          <h2 className="information-title font-bold text-center">
            {Parser(title ?? "Title")}
          </h2>
        </div>
        <div className="flex flex-col content-center information-description text-center max-w-[300px] md:max-w-[450px]">
          {Parser(description ?? <></>)}
        </div>
        <div className="flex flex-col content-center text-center font-bold">
          {firstCapital(eventType) ?? t("All Event.Event Type")}
        </div>
      </div>
    );
  }

  if (imageLayout === "middle") {
    return (
      <div className="border-[#E0E0E0] border-[1px] flex flex-col justify-center p-[1rem] gap-y-4">
        <div className="flex flex-col content-center gap-y-[0.125rem]">
          <h2 className="information-title font-bold text-center">
            {Parser(title ?? "Title")}
          </h2>
        </div>
        <div className="flex flex-row justify-center">
          <Controller
            control={control}
            name="heroImage"
            render={() => {
              return (
                <img
                  alt=""
                  src={
                    watch("heroImage")
                      ? typeof watch("heroImage") === "string"
                        ? watch("heroImage")
                        : URL.createObjectURL(watch("heroImage"))
                      : DummyPicture
                  }
                  width={previewHeroImageWidth}
                />
              );
            }}
          />
        </div>
        <div className="flex flex-col content-center information-description text-center max-w-[300px] md:max-w-[450px]">
          {Parser(description ?? <></>)}
        </div>
        <div className="flex flex-col content-center text-center font-bold">
          {firstCapital(eventType) ?? t("All Event.Event Type")}
        </div>
      </div>
    );
  }
};

export default Preview;
