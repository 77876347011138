// define colors for the theme
const colors = {
  addonBtn: {
    background: "#FF8413",
    backgroundHover: "#455556",
    color: "#FFFFFF",
    disabled: "#aeaeae",
  },
  button: {
    button1: {
      background: "#FF993C",
      color: "#ffffff",
    },
    button2: {
      background: "#FF8413",
      color: "#ffffff",
    },
    next: {
      background: "#FF8413",
      border: "transparent",
      color: "white",
      disabled: {
        background: "#EFEFEF",
        border: "#EFEFEF",
        color: "#737373",
      },
    },
  },
  card: {
    personalNameCard: {
      background: "#ffffff",
      shadowColor: "rgba(84, 95, 122, 0.08)",
      subTitleColor: "#272727",
      titleColor: "#1B2559",
    },
  },
  datePicker: {
    error: "#ff4d4f",
  },
  forgetPassword: {
    description: "#737373",
  },
  input: {
    addonBtn: {
      background: "#000",
      backgroundHover: "#455556",
      color: "#FFFFFF",
      disabled: "#aeaeae",
    },
    generic: {
      border: "#d9d9d9",
      color: "#747474",
      colorHover: "#000",
      disabled: "#efefef",
      error: "#ff4d4f",
      label: "#737373",
      message: "#00b300",
    },
    numberInput1: {
      backgroundColor: "#ffffff",
      border: "#d9d9d9",
      color: "#747474",
      color2: "rgba(0, 0, 0, 0.88)",
      error: {
        border: "#ff4d4f",
        focus: {
          border: "#ff7875",
          boxShadow: "rgba(255, 38, 5, 0.06)",
        },
        hover: {
          border: "#ffa39e",
        },
      },
      focus: {
        border: "#4096ff",
        boxShadow: "rgba(5, 145, 255, 0.1)",
      },
      hover: {
        border: "#4096ff",
      },
      placeholder: {
        color: "rgba(0, 0, 0, 0.25)",
      },
    },
    select: {
      error: "#ff4d4f",
      label: "#000000",
    },
    textAreaInput1: {
      disabled: {
        background: "#ffffff",
      },
    },
    textAreaInput2: {
      disabled: {
        background: "#ffffff",
      },
    },
  },
  layoutB: {
    background: "#ffffff",
    button1: {
      background: "#FF8413",
      color: "#ffffff",
    },
    checkList: {
      danger: "#d50000",
      success: "#4fc554",
    },
    description: {
      color: "#000",
    },
    nameTag: {
      background: "#ffffff66",
      color: "#fff",
      textShadow: "#00000040",
    },
  },
  login: {
    link1: "rgba(0, 0, 0, 0.5)",
    link2: "#fe6a00",
    text1: "rgba(0, 0, 0, 0.7)",
  },
  mountCountdown: {
    color: "#fe6a00",
  },
  sidebarMenu: {
    background: "#1b1b1b",
    firstLevelMenuActiveBackground: "#FF8413",
    firstLevelMenuLabelActive: "#ffffff",
    menuLabel: "#ffffff",
    secondLevelMenuActiveBackground: "#FF8413",
    secondLevelMenuBorder: "#3E3E3E",
    toggleBackground: "#3E3E3E",
    toggleColor: "#ffffff",
  },
  tabs: {
    activeColor: "#FF8413",
    background: "#ffffff",
  },
  uploads: {
    dragAndDrop1: {
      background: {
        isUploaded: "transparent",
        notUploaded: "#EEBEA438",
      },
      error: "#ff4d4f",
      items: {
        border: {
          hover: "#FE6A00",
          link: "#D9D9D9",
        },
      },
      mainDescription: "#737373",
      secondDescription: "#AEAEAE",
    },
  },
};

export default colors;
