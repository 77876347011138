import { ChartTooltipContent } from "@/components/ui/chart";
import { ChartTooltip } from "@/components/ui/chart";
import { ChartContainer } from "@/components/ui/chart";
import { Line, YAxis } from "recharts";
import { ChartConfig } from "@/components/ui/chart";
import { CartesianGrid, LineChart, XAxis } from "recharts";
import { FaArrowRight } from "react-icons/fa6";

const getRandomValue = () => Math.floor(Math.random() * 11);

const chartData = [
  {
    month: "Jan",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
  {
    month: "Feb",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
  {
    month: "Mar",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
  {
    month: "Apr",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
  {
    month: "May",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
  {
    month: "Jun",
    physicalHealth: getRandomValue(),
    mentalHealth: getRandomValue(),
    socialSkills: getRandomValue(),
    achievements: getRandomValue(),
    selfCareAbility: getRandomValue(),
  },
];

const chartConfig = {
  physicalHealth: {
    label: "Physical Health",
    color: "hsl(var(--chart-1))",
  },
  mentalHealth: {
    label: "Mental Health",
    color: "hsl(var(--chart-2))",
  },
  socialSkills: {
    label: "Social Skills",
    color: "hsl(var(--chart-3))",
  },
  achievements: {
    label: "Achievements",
    color: "hsl(var(--chart-4))",
  },
  selfCareAbility: {
    label: "Self-care Ability",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig;

const Legend = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto gap-x-10 gap-y-4 justify-around items-center">
      {Object.entries(chartConfig).map(([key, { label, color }]) => (
        <div key={key} className="flex items-center gap-2">
          <span
            className="w-4 h-6 rounded-full"
            style={{ backgroundColor: color }}
          ></span>
          <span className="text-sm">{label}</span>
        </div>
      ))}
    </div>
  );
};

const OverallRating = () => {
  return (
    <div className="flex flex-col justify-around bg-white shadow rounded-[20px] py-5 px-10 gap-5">
      <h3 className="text-2xl font-bold">Overall Rating</h3>
      <ChartContainer config={chartConfig}>
        <LineChart accessibilityLayer data={chartData} margin={{ left: -30 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
          />
          <YAxis tickLine={false} axisLine={false} tickMargin={8} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          {Object.keys(chartConfig).map((key) => (
            <Line
              key={key}
              dataKey={key}
              type="monotone"
              stroke={chartConfig[key as keyof typeof chartConfig].color}
              strokeWidth={2}
              dot={false}
            />
          ))}
        </LineChart>
      </ChartContainer>
      <Legend />
      <div className="w-auto flex gap-1 justify-center items-center cursor-pointer hover:opacity-80">
        <h4 className="text-[#A3AED0]">All Details</h4>
        <FaArrowRight size={18} className="text-orange-500" />
      </div>
    </div>
  );
};

export default OverallRating;
