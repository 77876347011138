import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const switchFormId = (locale: string, type: string) => {
  if (type === "APPLICATION") {
    switch (locale) {
      case "zh-HK":
        return import.meta.env.VITE_APPLICATION_FORM_ID_ZH_HK;
      case "zh-CN":
        return import.meta.env.VITE_APPLICATION_FORM_ID_ZH_CN;
      case "en":
      default:
        return import.meta.env.VITE_APPLICATION_FORM_ID_EN;
    }
  }
  if (type === "REFERRAL") {
    switch (locale) {
      case "zh-HK":
        return import.meta.env.VITE_REFERRAL_FORM_ID_ZH_HK;
      case "zh-CN":
        return import.meta.env.VITE_REFERRAL_FORM_ID_ZH_CN;
      case "en":
      default:
        return import.meta.env.VITE_REFERRAL_FORM_ID_EN;
    }
  }
  if (type === "INTERVIEW") {
    switch (locale) {
      case "zh-HK":
        return import.meta.env.VITE_INTERVIEW_FORM_ID_ZH_HK;
      case "zh-CN":
        return import.meta.env.VITE_INTERVIEW_FORM_ID_ZH_CN;
      case "en":
      default:
        return import.meta.env.VITE_INTERVIEW_FORM_ID_EN;
    }
  }
};
