import { FaArrowRight, FaPersonWalking } from "react-icons/fa6";
import { IoFootball } from "react-icons/io5";
import { MdOutlineNightlight } from "react-icons/md";

const health = [
  {
    title: "Sleeping Hours",
    lastUpdate: "21 July, 23",
    value: 60,
    valueColor: "#1A1A1A",
    icon: <MdOutlineNightlight size={28} className="text-[#4318FF]" />,
  },
  {
    title: "Body Fat",
    lastUpdate: "21 July, 23",
    value: "30%",
    valueColor: "#1A1A1A",
    icon: <FaPersonWalking size={28} className="text-[#01B574]" />,
  },
  {
    title: "BMI",
    lastUpdate: "21 July, 23",
    value: 23,
    valueColor: "#FF4D4D",
    icon: <IoFootball size={28} className="text-[#FFB547]" />,
  },
];

const HealthWeekly = () => {
  return (
    <div className="flex flex-col justify-center bg-white shadow rounded-[20px] py-5 px-10 gap-5">
      <h3 className="text-2xl font-bold">Health (Weekly)</h3>
      <div className="flex flex-col gap-4">
        {health.map((health, index) => (
          <div
            key={index}
            className="flex justify-between items-center gap-1 w-full"
          >
            <div className="flex items-center gap-4">
              <div className="w-16 h-16 bg-gray-100 rounded-full p-2 flex items-center justify-center">
                {health.icon}
              </div>
              <div>
                <p className="text-lg font-bold">{health.title}</p>
                <p className="text-sm text-[#A3AED0]">
                  Last update: {health.lastUpdate}
                </p>
              </div>
            </div>
            <h4
              className="text-xl font-bold"
              style={{ color: health.valueColor }}
            >
              {health.value}
            </h4>
          </div>
        ))}
      </div>
      <div className="w-auto flex gap-1 justify-center items-center cursor-pointer hover:opacity-80">
        <h4 className="text-[#A3AED0]">All Details</h4>
        <FaArrowRight size={18} className="text-orange-500" />
      </div>
    </div>
  );
};

export default HealthWeekly;
