import store from "@/redux/store";
import { HourlySleepData, SleepData } from "@/types/api/profile/UserProfile";
import { SleepState } from "@/types/enum/profile";
import dayjs, { ConfigType, Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

import "dayjs/plugin/utc";

export function formatDateRange(startDate: string, endDate: string): string {
  const start = dayjs.utc(startDate).local();
  const end = dayjs.utc(endDate).local();

  // If the start and end dates are the same
  if (start.isSame(end, "day")) {
    return `${start.format("D MMMM, YYYY")} ${start.format(
      "HH:mm"
    )} - ${end.format("HH:mm")}`;
  }
  // If the dates are different
  else {
    return `${start.format("D MMMM, YYYY HH:mm")} - ${end.format(
      "D MMMM, YYYY HH:mm"
    )}`;
  }
}

export function localToUTC(localDate: Dayjs | string): string {
  return dayjs(localDate).utc().format("YYYY-MM-DD HH:mm:ss");
}

export function utcToLocalDateJs(utcDateString: string) {
  return dayjs.utc(utcDateString).local();
}

export function utcToLocalFormatted(utcDateString: string) {
  return dayjs.utc(utcDateString).local().format("YYYY-MM-DD HH:mm:ss");
}

export function quickFormatDate(dateString: string) {
  return dateString.replace("T", " ").slice(0, 19);
}

//utc to local date with format DD/MM/YYYY HH:mm
export function utcToLocalDateWithFormat(utcDateString: string) {
  return dayjs.utc(utcDateString).local().format("DD/MM/YYYY HH:mm");
}

export function convertToISOString(dateString: string) {
  return dateString.replace(" ", "T") + ".000Z";
}

export const formatDateIsValid = (date: ConfigType) => {
  return dayjs.utc(date).isValid();
};

export const byteToMegabyte = (byte: number) => {
  return byte / 1024 / 1024;
};

export const byteToKilobyte = (byte: number) => {
  return byte / 1024;
};

export const checkLoggedIn = () => {
  const state = store.getState();
  console.log("%c====== Check Logged In ======", "color: #aaff00");
  console.log("%cLogged In:", "color: #66ff00", state.app.loggedIn);
  console.log("%c===============================", "color: #aaff00");
  return state.app.loggedIn;
};

export const convertPaginationFormat = (oldFormat: {
  from: number;
  to: number;
  total: number;
  currentPage: number;
  maxPage: number;
  pageSize: number;
}): { pageIndex: number; pageSize: number } => {
  return {
    pageIndex: oldFormat.currentPage - 1,
    pageSize: oldFormat.pageSize,
  };
};

export const validateUrl = (url?: string) => {
  return /^(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$/.test(
    url ?? ""
  );
};

export const convertSleepChartData = (
  inputData: SleepData[]
): HourlySleepData[] => {
  const stateValues: Record<string, number> = {
    awake: 1,
    light: 2,
    deep: 3,
    offbed: 4,
    offline: 5,
  };

  return inputData.map((data) => {
    const formattedTime = dayjs(data.time).format("HH:mm");

    return {
      hour: formattedTime,
      awake:
        data.state === SleepState.AWAKE ? stateValues[SleepState.AWAKE] : 0,
      light:
        data.state === SleepState.LIGHT ? stateValues[SleepState.LIGHT] : 0,
      deep: data.state === SleepState.DEEP ? stateValues[SleepState.DEEP] : 0,
      offbed:
        data.state === SleepState.OFFBED ? stateValues[SleepState.OFFBED] : 0,
      offline:
        data.state === SleepState.OFFLINE ? stateValues[SleepState.OFFLINE] : 0,
    };
  });
};

export const getSleepQuality = (score: number): string => {
  if (!score) {
    return "-";
  }

  if (score >= 90) {
    return "A";
  } else if (score >= 80) {
    return "B";
  } else if (score >= 60) {
    return "C";
  } else {
    return "D";
  }
};

export const calculatePercentage = (value: number, total: number) => {
  if (total === 0) {
    return "0%";
  }
  return ((value / total) * 100).toFixed(2) + "%";
};

export const removeIdPrefix = (id: string) => {
  return id.split("_")?.[1] ?? id;
};

export const formatNullableValue = (
  value: string | number | null | undefined,
  isPrice = false
): string => {
  if (value === null || value === undefined || value === "null") {
    return "-";
  }
  if (isPrice) {
    const numValue = typeof value === "string" ? parseFloat(value) : value;
    if (typeof numValue === "number" && !isNaN(numValue)) {
      return numValue.toLocaleString();
    }
  }
  if (typeof value === "string" && dayjs.utc(value).isValid()) {
    return utcToLocalDateWithFormat(value);
  }
  return value.toString();
};
