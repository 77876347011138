import AvatarBooking from "@/assets/images/avatar-booking.png";
import { useAppSelector } from "@/redux/store";
import { AiFillMessage } from "react-icons/ai";
import { BsPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const BasicInfo = () => {
  const { activeProfile } = useAppSelector(
    (state) => state.profileManagement.allProfile
  );

  return (
    <div className="flex flex-col items-center bg-white shadow rounded-[32px] py-5 gap-5 w-[150px] h-full">
      <img
        src={AvatarBooking}
        alt="Profile"
        className="w-12 h-12 rounded-full object-cover"
      />
      <h3 className="text-base font-bold break-words text-center px-2">
        {activeProfile?.firstName} {activeProfile?.lastName}
      </h3>
      <p className="text-sm text-[#A3AED0]">OHY</p>
      <div className="flex flex-col items-center">
        <p className="text-xs text-[#A3AED0]">Floor</p>
        <h4 className="text-lg font-bold">12</h4>
      </div>
      <div className="flex flex-col items-center">
        <p className="text-xs text-[#A3AED0]">Room</p>
        <h4 className="text-lg font-bold">2</h4>
      </div>
      <div className="flex flex-col items-center">
        <p className="text-xs text-[#A3AED0]">Bed</p>
        <h4 className="text-lg font-bold">B</h4>
      </div>
      <div className="flex gap-3">
        <BsPhoneFill size={21} />
        <MdEmail size={21} />
        <AiFillMessage size={21} />
      </div>
    </div>
  );
};

export default BasicInfo;
