import type Props from "@/types/components/Badges/StatusBadges";
import { type VariantOptions } from "@/types/components/Badges/StatusBadges";
import styled, { css } from "styled-components";
const StyledBadge = styled.div<{
  $variantOptions: Record<string, VariantOptions>;
  $variant: string;
  $size: number | string;
}>`
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  min-width: 84px;
  font-size: ${(props) => (props.$size === "small" ? "10px" : "12px")};
  padding: 5px 10px;
  border-radius: 30px;
  text-align: center;
  ${(props) =>
    props.$variant &&
    props.$variantOptions?.[props.$variant] &&
    css`
      color: ${props.$variantOptions[props.$variant].color};
      background-color: ${props.$variantOptions[props.$variant]
        .backgroundColor};
      border: ${props.$variantOptions[props.$variant].border};
    `}
`;
const StatusBadges = (props: Props) => {
  const { variant, variantOptions, size, children, ...restProps } = props;

  // if (variantoptions && variant && !variantoptions[variant]) {
  //   alert(
  //     `Warning! Invalid variant! Variant: ${variant} \n Please tell the developer check the variantoptions. \n variantoptions: ${JSON.stringify(
  //       variantoptions
  //     )}`
  //   );
  // }
  // useEffect(() => {
  //   if (variantOptions) {
  //     console.log("variantOptions", variantOptions);
  //   } else {
  //     console.log("No variantOptions");
  //   }
  // }, [variantOptions]);

  // useEffect(() => {
  //   if (variant) {
  //     console.log("variant", variant);
  //   }
  // }, [variant]);

  return (
    <StyledBadge
      $variantOptions={variantOptions}
      $variant={variant}
      $size={size || "normal"}
      {...restProps}
    >
      {children}
    </StyledBadge>
  );
};

export default StatusBadges;
