import styled from "styled-components";
import { Button } from "antd";
import colors from "@/theme/default/colors";

const Button1 = styled(Button)`
  &.ant-btn-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &.ant-btn-default {
    background-color: ${colors.button.button1.background};
    color: ${colors.button.button1.color};
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: none;
    border-radius: 50px;
    padding: 6px;
    min-height: 63.6px;
    &:hover {
      background-color: ${colors.button.button1.background};
      color: ${colors.button.button1.color} !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
`;

export default Button1;
