/* eslint-disable react-refresh/only-export-components */
import loadable from "@loadable/component";
import Loading from "@/components/Loading/Loading";

const Login = loadable(() => import("@/views/app/Login/Login"), {
  fallback: <Loading isLoading={true} />,
});
const ForgetPassword = loadable(
  () => import("@/views/app/ForgetPassword/ForgetPassword"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const TableDemo = loadable(() => import("@/views/app/TableDemo"), {
  fallback: <Loading isLoading={true} />,
});

const appRoute = () => {
  return [
    {
      path: "login",
      handle: {
        layout: "B",
      },
      element: <Login />,
    },
    {
      path: "forgot-password",
      handle: {
        layout: "B",
      },
      element: <ForgetPassword />,
    },
    {
      path: "table",
      handle: {
        layout: "A",
      },
      element: <TableDemo />,
    },
  ];
};

export default appRoute;
