// Don't add any asynchronous logic or other "side effects" in reducer
// For example, logging a value to the console, ajax
// Just keep it simple
import { convertPaginationFormat } from "@/lib/helper";
import { Pagination } from "@/types/api/APIResponse";
import { Property } from "@/types/propertyManagement/Property";
import { createSlice } from "@reduxjs/toolkit";
import type Tabs from "@/types/components/Tabs/EditableTabs/Tabs1";

export interface PropertyManagementState {
  tab: {
    tabs: Tabs[];
    activeTab: string | undefined;
  };
  newProperty: {
    step: number;
  };
  allProperties: {
    table: {
      data: Property[];
      isError: boolean;
      isLoading: boolean;
      columnFilters: [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columnFiltersSettings: Record<string, any>;
      globalFilter: "";
      sorting: [];
      pagination: {
        pageIndex: number;
        pageSize: number;
      };
      rowCount: number;
    };
  };
}
// !! Remember add the new flags to here, if any !!
const initialState: PropertyManagementState = {
  tab: {
    tabs: [],
    activeTab: undefined,
  },
  newProperty: {
    step: 1,
  },
  allProperties: {
    table: {
      data: [],
      isError: false,
      isLoading: false,
      columnFilters: [],
      columnFiltersSettings: {},
      globalFilter: "",
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      rowCount: 0,
    },
  },
};

export const propertyManagementSlice = createSlice({
  name: "propertyManagement",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNewPropertyStep: (state, action) => {
      return {
        ...state,
        newProperty: {
          ...state.newProperty,
          step: action.payload,
        },
      };
    },
    //[All Properties]
    setAllPropertiesTable: (
      state,
      action: {
        payload: {
          data?: Property[];
          isError?: boolean;
          isLoading?: boolean;
          columnFilters?: [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columnFiltersSettings?: Record<string, any>;
          globalFilter?: "";
          sorting?: [];
          pagination?: Pagination;
          rowCount?: number;
        };
      }
    ) => {
      const pagination = action.payload?.pagination
        ? convertPaginationFormat(action.payload.pagination)
        : state.allProperties.table.pagination;
      const rowCount =
        action.payload?.pagination?.total ?? state.allProperties.table.rowCount;
      return {
        ...state,
        allProperties: {
          table: {
            data: action.payload?.data ?? state.allProperties.table.data,
            isError:
              action.payload?.isError ?? state.allProperties.table.isError,
            isLoading:
              action.payload?.isLoading ?? state.allProperties.table.isLoading,
            columnFilters:
              action.payload?.columnFilters ??
              state.allProperties.table.columnFilters,
            columnFiltersSettings:
              action.payload?.columnFiltersSettings ??
              state.allProperties.table.columnFiltersSettings,
            globalFilter:
              action.payload?.globalFilter ??
              state.allProperties.table.globalFilter,
            sorting:
              action.payload?.sorting ?? state.allProperties.table.sorting,
            pagination: pagination,
            rowCount: rowCount,
          },
        },
      };
    },
    setAllPropertiesTabs: (state, action) => {
      return {
        ...state,
        tab: {
          ...state.tab,
          tabs: action.payload,
        },
      };
    },
    setAllPropertiesActiveTab: (state, action) => {
      console.log("action", action.payload);
      console.log("state", state);
      return {
        ...state,
        tab: {
          ...state.tab,
          activeTab: action.payload,
        },
      };
    },
  },
});
export const {
  setNewPropertyStep,
  setAllPropertiesTable,
  setAllPropertiesTabs,
  setAllPropertiesActiveTab,
} = propertyManagementSlice.actions;

export default propertyManagementSlice.reducer;
