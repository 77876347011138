// Don't add any asynchronous logic or other "side effects" in reducer
// For example, logging a value to the console, ajax
// Just keep it simple
import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit";
interface AppState {
  loggedIn: boolean;
  layout: "A" | "B";
  initialState: boolean;
  sidebarMenu: {
    collapsed: boolean;
    showSubmenu: boolean;
  };
  tokens: {
    accessToken: string | null;
    refreshToken: string | null;
    idToken: string | null;
  };
}
// !! Remember add the new flags to here, if any !!
const initialState: AppState = {
  loggedIn:
    !!Cookies.get("API_TOKEN") &&
    !!Cookies.get("REFRESH_TOKEN") &&
    !!Cookies.get("ID_TOKEN"),
  layout: "B",
  initialState: false,
  sidebarMenu: {
    collapsed: true,
    showSubmenu: false,
  },
  tokens: {
    accessToken: Cookies.get("API_TOKEN") ?? null,
    refreshToken: Cookies.get("REFRESH_TOKEN") ?? null,
    idToken: Cookies.get("ID_TOKEN") ?? null,
  },
};

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    login: (state) => {
      state.loggedIn = true;
    },
    setAPITokenCookie: (state, action) => {
      Cookies.set("API_TOKEN", action.payload.accessToken, {
        expires: action.payload.expiresInSecond,
      });
      Cookies.set("REFRESH_TOKEN", action.payload.refreshToken);
      Cookies.set("ID_TOKEN", action.payload.idToken);
      return {
        ...state,
        ...{
          tokens: {
            ...state.tokens,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
            idToken: action.payload.idToken,
          },
        },
      };
    },
    logout: () => {
      Cookies.remove("ID_TOKEN");
      Cookies.remove("API_TOKEN");
      Cookies.remove("REFRESH_TOKEN");
      //Clean up AI bot
      if (window?.removeEmbedChatbot) {
        window.removeEmbedChatbot();
      }
      return {
        ...initialState,
        initialState: true,
        loggedIn: false,
        tokens: {
          accessToken: null,
          refreshToken: null,
          idToken: null,
        },
      };
    },
    setLayout: (state, action) => {
      const layoutList = ["A", "B"];
      if (layoutList.includes(action.payload)) {
        return {
          ...state,
          layout: action.payload,
        };
      } else {
        throw new Error("Invalid layout");
      }
    },
    appInitialize: (state, action) => {
      state.initialState = action.payload;
    },
    setSidebarMenuCollapsed: (state, action) => {
      return {
        ...state,
        sidebarMenu: {
          ...state.sidebarMenu,
          collapsed: action.payload,
        },
      };
    },
    setSidebarMenuShowSubmenu: (state, action) => {
      return {
        ...state,
        sidebarMenu: {
          ...state.sidebarMenu,
          showSubmenu: action.payload,
        },
      };
    },
  },
});
export const {
  login,
  logout,
  setLayout,
  appInitialize,
  setAPITokenCookie,
  setSidebarMenuCollapsed,
  setSidebarMenuShowSubmenu,
} = appSlice.actions;

export default appSlice.reducer;
