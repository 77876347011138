import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "@/components/Button/Button2";
import ProfileOverview from "@/views/profileManagement/ProfileDetail/Overview/ProfileOverview";
import ProfileIOT from "@/views/profileManagement/ProfileDetail/IOT/ProfileIOT";

const CustomTabPanel = styled.div.attrs({ role: "tabpanel" })<{
  $key: string;
  $value: number;
  $currentActiveTab: number;
}>`
  display: ${(props) =>
    props.$value === props.$currentActiveTab ? "block" : "none"};
`;

const ProfileDetailTab = ({ id }: { id: string }) => {
  const { t } = useTranslation(["profileManagement"], {
    keyPrefix: "Detail",
  });
  console.log("[Profile Detail Tab]", id);

  const tabsMap = useMemo(
    () => ({
      overview: { index: 0, title: t("Overview") },
      personalInfo: { index: 1, title: t("Personal Info") },
      assessment: { index: 2, title: t("Assessment") },
      calendar: { index: 3, title: t("Calendar") },
      eLearning: { index: 4, title: t("E-Learning") },
      iot: { index: 5, title: t("IOT") },
      activity: { index: 6, title: t("Activity") },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [activeTab, setActiveTab] = useState<number>(tabsMap.overview.index);

  return (
    <div className="flex flex-col gap-5 mt-5">
      <div className="flex gap-2">
        {Object.values(tabsMap).map((tab) => (
          <Button
            className="min-w-[100px]"
            active={activeTab === tab.index}
            onClick={() => setActiveTab(tab.index)}
          >
            {tab.title}
          </Button>
        ))}
      </div>
      <div className="flex flex-col items-center gap-5">
        <div className="w-full">
          <CustomTabPanel
            $key="overview"
            $value={tabsMap.overview.index}
            $currentActiveTab={activeTab}
          >
            <ProfileOverview />
          </CustomTabPanel>
          <CustomTabPanel
            $key="personalInfo"
            $value={tabsMap.personalInfo.index}
            $currentActiveTab={activeTab}
          >
            Personal Info
          </CustomTabPanel>
          <CustomTabPanel
            $key="assessment"
            $value={tabsMap.assessment.index}
            $currentActiveTab={activeTab}
          >
            Assessment
          </CustomTabPanel>
          <CustomTabPanel
            $key="calendar"
            $value={tabsMap.calendar.index}
            $currentActiveTab={activeTab}
          >
            Calendar
          </CustomTabPanel>
          <CustomTabPanel
            $key="eLearning"
            $value={tabsMap.eLearning.index}
            $currentActiveTab={activeTab}
          >
            E-Learning
          </CustomTabPanel>
          <CustomTabPanel
            $key="iot"
            $value={tabsMap.iot.index}
            $currentActiveTab={activeTab}
          >
            <ProfileIOT />
          </CustomTabPanel>
          <CustomTabPanel
            $key="activity"
            $value={tabsMap.activity.index}
            $currentActiveTab={activeTab}
          >
            Activity
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailTab;
