import ProfileStatusBadges from "@/components/Badges/ProfileStatusBadges";
import API from "@/lib/api";
import { useMaterialReactTable } from "@/lib/hook";
import { UserProfile } from "@/types/api/profile/UserProfile";
import ProfileManagementProps from "@/types/components/Table/profileManagement/ProfileTable";
import { ProfileStatus, ProfileType } from "@/types/enum/profile";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type ProfileListTableRef = object;
const ProfileListTable = forwardRef<
  ProfileListTableRef,
  ProfileManagementProps
>((props) => {
  const { viewAction } = props;
  const { t } = useTranslation("profileManagement");
  const { t: tApp } = useTranslation(["app"]);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [tableIsError, setTableIsError] = useState(false);
  const [tableData, setTableData] = useState<UserProfile[]>([]);
  const [tablePagination, setTablePagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [tableRowCount, setTableRowCount] = useState(0);
  const [tableGlobalFilter, setTableGlobalFilter] = useState("");
  const [tableSorting, setTableSorting] = useState<MRT_SortingState>([]);
  const [tableColumnFilters, setTableColumnFilters] =
    useState<MRT_ColumnFiltersState>([]);

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<UserProfile>[]>(
    () => [
      {
        accessorKey: "status",
        header: t("Columns.Status"),
        muiFilterTextFieldProps: { placeholder: t("Columns.Status") },
        filterVariant: "select",
        filterSelectOptions: [
          { text: t("Statuses.Active"), value: ProfileStatus.ACTIVE },
          { text: t("Statuses.Inactive"), value: ProfileStatus.INACTIVE },
          {
            text: t("Statuses.Pending"),
            value: ProfileStatus.PENDING,
          },
          {
            text: t("Statuses.Rejected"),
            value: ProfileStatus.REJECTED,
          },
          {
            text: t("Statuses.Approved"),
            value: ProfileStatus.APPROVED,
          },
        ],
        size: 150,
        Cell: ({ row }) => (
          <ProfileStatusBadges variant={row.original.status} />
        ),
      },
      {
        accessorKey: "userId",
        header: t("Columns.User No."),
        muiFilterTextFieldProps: { placeholder: t("Columns.User No.") },
        size: 200,
        Cell: ({ row }) => row.original.userId,
      },
      {
        accessorKey: "name",
        header: t("Columns.Name"),
        muiFilterTextFieldProps: { placeholder: t("Columns.Name") },
        size: 200,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <img
              src={
                // row.original.userIcon ??
                "https://cdn-icons-png.flaticon.com/512/219/219983.png"
              }
              alt="user"
              className="w-[32px] h-[32px] rounded-full mr-[10px]"
            />
            <span>{row.original?.firstName}</span>
            <span className="ml-1">{row.original?.lastName}</span>
          </div>
        ),
      },
      {
        accessorKey: "phone",
        header: t("Columns.Phone No."),
        muiFilterTextFieldProps: { placeholder: t("Columns.Phone No.") },
        size: 150,
        Cell: ({ row }) => row.original.phone,
      },
      {
        accessorKey: "idNumber",
        header: t("Columns.ID No."),
        muiFilterTextFieldProps: { placeholder: t("Columns.ID No.") },
        size: 150,
        Cell: ({ row }) => row.original.idNumber,
      },
      {
        accessorKey: "email",
        header: t("Columns.Email"),
        muiFilterTextFieldProps: { placeholder: t("Columns.Email") },
        size: 200,
        Cell: ({ row }) => row.original.email,
      },
      {
        accessorKey: "type",
        header: t("Columns.Type"),
        muiFilterTextFieldProps: { placeholder: t("Columns.Type") },
        filterVariant: "select",
        filterSelectOptions: [
          { text: t("Types.OHY"), value: ProfileType.OHY },
          { text: t("Types.PIR"), value: ProfileType.PIR },
          { text: t("Types.SOCIAL_WORKER"), value: ProfileType.SOCIAL_WORKER },
          { text: t("Types.STAFF"), value: ProfileType.STAFF },
          { text: t("Types.ALL"), value: ProfileType.ALL },
        ],
        size: 200,
        Cell: ({ row }) => row.original.type,
      },
      {
        accessorKey: "createDt",
        header: t("Columns.Created At"),
        enableColumnFilter: false,
        size: 200,
        Cell: ({ row }) =>
          dayjs
            .utc(row.original.createDt, "YYYY-MM-DD HH:mm:ss")
            .local()
            .format("LLL"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    getRowId: (row) => row.id,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: (filters) => {
      setTableColumnFilters(filters);
      setTablePagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onGlobalFilterChange: (text) => {
      setTableGlobalFilter(text);
    },
    onPaginationChange: setTablePagination,
    onSortingChange: (sorting) => {
      setTableSorting(sorting);
      setTablePagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    rowCount: tableRowCount,
    enableColumnFilters: true,
    enableSorting: true,
    state: {
      columnFilters: tableColumnFilters,
      globalFilter: tableGlobalFilter,
      isLoading: tableIsLoading,
      pagination: tablePagination,
      showAlertBanner: tableIsError,
      showProgressBars: tableIsLoading,
      sorting: tableSorting,
    },
    muiToolbarAlertBannerProps: tableIsError
      ? {
          color: "error",
          children: tApp("Table.Messages.Load Data Error"),
        }
      : undefined,
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <div>
        <MenuItem
          key={`profile_${row.original.id}`}
          onClick={() => {
            viewAction(row.original, true);
            closeMenu();
          }}
        >
          {t("Actions.View")}
        </MenuItem>
      </div>,
    ],
    renderToolbarInternalActions: ({ table }) => (
      <>
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
  });

  const fetchData = useCallback(async () => {
    setTableIsLoading(true);
    API.profile
      .listProfiles({
        query: tableGlobalFilter,
        page: tablePagination.pageIndex + 1,
        size: tablePagination.pageSize,
        startDt:
          (tableColumnFilters.find(
            (filter) => filter.id === "startDt" && filter.value
          )?.value as string) || undefined,
        endDt:
          (tableColumnFilters.find(
            (filter) => filter.id === "endDt" && filter.value
          )?.value as string) || undefined,
        userId:
          (tableColumnFilters.find(
            (filter) => filter.id === "userId" && filter.value
          )?.value as string) || undefined,
        phone:
          (tableColumnFilters.find(
            (filter) => filter.id === "phone" && filter.value
          )?.value as string) || undefined,
        email:
          (tableColumnFilters.find(
            (filter) => filter.id === "email" && filter.value
          )?.value as string) || undefined,
        idNumber:
          (tableColumnFilters.find(
            (filter) => filter.id === "idNumber" && filter.value
          )?.value as string) || undefined,
        statuses:
          (tableColumnFilters.find(
            (filter) => filter.id === "status" && filter.value
          )?.value as string) || undefined,
        socialWorkerProfileId:
          (tableColumnFilters.find(
            (filter) => filter.id === "socialWorkerProfileId" && filter.value
          )?.value as string) || undefined,
        name:
          (tableColumnFilters.find(
            (filter) => filter.id === "name" && filter.value
          )?.value as string) || undefined,
        types:
          (tableColumnFilters.find(
            (filter) => filter.id === "type" && filter.value
          )?.value as string) || undefined,
        sort:
          tableSorting
            .map((sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`)
            .join(",") || undefined,
      })
      .then((res) => {
        console.log("listProfiles", res.data.data);
        setTableIsError(false);
        setTableData(res.data.data);
        setTableRowCount(res.data.pagination.total);
      })
      .catch(() => {
        setTableIsError(true);
        setTableData([]);
        setTableRowCount(0);
      })
      .finally(() => {
        setTableIsLoading(false);
      });
  }, [
    tableColumnFilters,
    tableGlobalFilter,
    tablePagination.pageIndex,
    tablePagination.pageSize,
    tableSorting,
  ]);

  useEffect(() => {
    // Fetch data here
    fetchData();
  }, [
    fetchData,
    tableColumnFilters,
    tableGlobalFilter,
    tablePagination.pageIndex,
    tablePagination.pageSize,
    tableSorting,
  ]);

  return <MaterialReactTable table={table} />;
});

export default ProfileListTable;
