import AvatarBooking from "@/assets/images/avatar-booking.png";
import { AiFillMessage } from "react-icons/ai";
import { BsPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const BasicInfo = () => {
  return (
    <div className="flex flex-col items-center bg-white shadow rounded-[16px] py-5 px-10 gap-5">
      <img
        src={AvatarBooking}
        alt="Profile"
        className="w-36 h-36 rounded-full object-cover"
      />
      <h3 className="text-2xl font-bold">Kylie Lau</h3>
      <p className="text-xl text-[#A3AED0]">OHY</p>
      <div className="flex justify-around w-full">
        <div className="flex flex-col items-center">
          <p className="text-sm text-[#A3AED0]">Floor</p>
          <h4 className="text-2xl font-bold">12</h4>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm text-[#A3AED0]">Room</p>
          <h4 className="text-2xl font-bold">2</h4>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm text-[#A3AED0]">Bed</p>
          <h4 className="text-2xl font-bold">B</h4>
        </div>
      </div>
      <div className="flex gap-3">
        <BsPhoneFill size={28} />
        <MdEmail size={28} />
        <AiFillMessage size={28} />
      </div>
    </div>
  );
};

export default BasicInfo;
