import { Button } from "antd";
import styled from "styled-components";
import { lighten } from "polished";
import colors from "@/theme/default/colors";
import type Props from "@/types/components/Button/FormNext";
import classNames from "classnames";

const NextButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  border-radius: 9999px;
  width: 100%;
  min-width: 80px;
  max-width: 300px;
  white-space: wrap;
  border: 1px solid ${colors.button.next.background};
  color: ${colors.button.next.background};
  font-weight: 700;
  font-size: 16px;
  font-family: "Source Sans 3", sans-serif;
  &.ant-btn-primary {
    background-color: ${colors.button.next.background};
    border: 1px solid ${colors.button.next.border};
    color: ${colors.button.next.color};
    &:hover {
      color: ${lighten(0.2, colors.button.next.color)} !important;
      background-color: ${lighten(
        0.2,
        colors.button.next.background
      )} !important;
      border: 1px solid ${lighten(0.2, colors.button.next.border)} !important;
    }
    &:disabled {
      color: ${colors.button.next.disabled.color} !important;
      background-color: ${colors.button.next.disabled.background} !important;
      border: 1px solid ${colors.button.next.disabled.border} !important;
    }
  }
`;

const FormNext = (props: Props) => {
  const { htmlType, className, isLoading, disabled, onClick, type } = props;
  return (
    <div className="flex flex-row justify-center my-8">
      <NextButton
        type={type ?? "primary"}
        htmlType={htmlType ?? "button"}
        className={classNames("!min-w-[120px]", className)}
        loading={isLoading ?? false}
        disabled={disabled ?? false}
        onClick={onClick}
      >
        {props.children ?? null}
      </NextButton>
    </div>
  );
};

export default FormNext;
