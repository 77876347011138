import { ReactNode } from "react";
const VerticalAlignDiv = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative">
      <div className="relative m-0 top-[50%] translate-y-[-50%]">
        {children}
      </div>
    </div>
  );
};

export default VerticalAlignDiv;
