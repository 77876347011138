export enum SessionType {
  FULL_SCREEN = "FULL_SCREEN",
  APP_NOTIFICATION = "APP_NOTIFICATION",
  SECTION = "SECTION",
}

export enum SessionEvent {
  FIRST_TIME_LOGIN = "FIRST_TIME_LOGIN",
}

export enum SessionStatus {
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
