import SSO from "@/views/sso/SSO";

const ssoRoute = () => {
  return [
    {
      path: "/sso",
      element: <SSO />,
      handle: {},
    },
  ];
};
export default ssoRoute;
