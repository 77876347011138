import StatusBadges from "@/components/Badges/StatusBadges";
import { useTranslation } from "react-i18next";
import { ProfileStatus } from "@/types/enum/profile";
import ProfileStatusBadgesProps from "@/types/components/Badges/ProfileStatusBadges";

const ProfileStatusBadges = ({
  variant,
  size,
  ...restProps
}: ProfileStatusBadgesProps) => {
  const { t } = useTranslation(["profileManagement"]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const variantOptions: any = {
    [ProfileStatus.ACTIVE]: {
      color: "#000000",
      backgroundColor: "#F2FFF3",
      border: "1px solid #4FC554",
      label: t("Statuses.Active"),
    },
    [ProfileStatus.INACTIVE]: {
      color: "#000000",
      backgroundColor: "#EEEEEE",
      border: "1px solid #000000",
      label: t("Statuses.Inactive"),
    },
    [ProfileStatus.PENDING]: {
      color: "#000000",
      backgroundColor: "#FDF9D9",
      border: "1px solid #FFD43C",
      label: t("Statuses.Pending"),
    },
    [ProfileStatus.REJECTED]: {
      color: "#000000",
      backgroundColor: "#FDD9EC",
      border: "1px solid #C82556",
      label: t("Statuses.Rejected"),
    },
    [ProfileStatus.APPROVED]: {
      color: "#000000",
      backgroundColor: "#E2FDD9",
      border: "1px solid #32C825",
      label: t("Statuses.Approved"),
    },
  };

  return (
    <StatusBadges
      variantOptions={variantOptions}
      variant={variant}
      size={size}
      {...restProps}
    >
      {variantOptions[variant]?.label ?? variant}
    </StatusBadges>
  );
};

export default ProfileStatusBadges;
