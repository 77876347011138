// Don't add any asynchronous logic or other "side effects" in reducer
// For example, logging a value to the console, ajax
// Just keep it simple
import { createSlice } from "@reduxjs/toolkit";
import type Tabs from "@/types/components/Tabs/EditableTabs/Tabs1";
import { FormValues } from "@/types/eventManagement/NewEvent/Form";

interface EventManagementState {
  allEvent: {
    tabs: Tabs[];
    activeTab: string | undefined;
  };
  newEvent: {
    step: number;
  };
  formData: FormValues;
  editEvent: {
    step: number;
  };
  mode: "edit" | "view" | "new" | null;
}
// !! Remember add the new flags to here, if any !!
const initialState: EventManagementState = {
  allEvent: {
    tabs: [],
    activeTab: undefined,
  },
  newEvent: {
    step: 1,
  },
  formData: {
    eventLocationId: null,
    eventTitle: "",
    eventDate: null,
    eventLocation: "",
    startDt: null,
    endDt: null,
    eventType: "",
    eventPublishDate: null,
    eventStatus: "",
    eventTime: "",
    description: "",
    price: 0,
    organizer: "",
    quota: 1,
    eventMetadata: {
      // This is staff in charge of new event
      staffs: [
        {
          staffId: 0,
          staffName: "",
        },
      ],
    },
    imageLayout: null,
    heroImage: null,
    guest: "",
    view: 1,
    floor: 0,
    room: "",
    area: "",
    district: "",
  },
  editEvent: {
    step: 1,
  },
  mode: null,
};

export const eventManagementSlice = createSlice({
  name: "propertyManagement",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNewEventStep: (state, action) => {
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          step: action.payload,
        },
      };
    },
    setNewEventFormData: (state, action) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    setEditEventStep: (state, action) => {
      return {
        ...state,
        editEvent: {
          ...state.editEvent,
          step: action.payload,
        },
      };
    },
    setEditEventFormData: (state, action) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    // TODO if posting new event API needs this as part of payload, remove this dispatch
    // setNewImageLayout: (state, action) => {
    //   return {
    //     ...state,
    //     imageLayout: action.payload,
    //   };
    // },
    // setNewEventHeroImage: (state, action) => {
    //   return {
    //     ...state,
    //     heroImage: action.payload,
    //   };
    // },
  },
});
export const {
  setNewEventStep,
  setNewEventFormData,
  setEditEventStep,
  setEditEventFormData,
  // setNewImageLayout,
  // setNewEventHeroImage,
} = eventManagementSlice.actions;

export default eventManagementSlice.reducer;
