/* eslint-disable react-refresh/only-export-components */
import Loading from "@/components/Loading/Loading";
import i18n from "@/i18n/i18n";
import { checkLoggedIn } from "@/lib/helper";
import loadable from "@loadable/component";

const System = loadable(() => import("@/views/system"), {
  fallback: <Loading isLoading={true} />,
});

const SystemManagement = loadable(() => import("@/views/system/management"), {
  fallback: <Loading isLoading={true} />,
});

const systemConfigurationRoute = () => {
  void checkLoggedIn;
  return [
    {
      path: "/system",
      element: <System />,
      handle: {
        crumb: () => {
          return {
            display: i18n.t("SidebarMenu.System.System Configuration"),
            route: `/system`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
      children: [
        {
          path: `management`,
          element: <SystemManagement />,
          handle: {
            crumb: () => {
              return {
                display: i18n.t("SidebarMenu.System.System Management"),
                route: `/system/management`,
              };
            },
          },
        },
      ],
    },
  ];
};

export default systemConfigurationRoute;
