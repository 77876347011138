import { Input as AntInput, InputRef } from "antd";
import { Ref } from "react";
import { ensuredForwardRef } from "react-use";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { FormHelperText } from "@mui/material";

const StyledInput = styled(AntInput)`
  &.ant-input-affix-wrapper,
  &.ant-input {
    padding: 9px 14px;
    border-radius: 9px;
  }
  input.ant-input {
    margin: auto;
  }

  .ant-input-group,
  .ant-input {
    height: 100%;

    .ant-input-group-addon {
      padding: 0 !important;
      min-width: 80px;
    }
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input {
    padding: 20px 20px;
    border-radius: 9px;
  }

  .ant-input {
    border-radius: 9px;
  }

  .ant-input-suffix,
  .ant-input-clear-icon {
    font-size: 14px;
  }

  .ant-input-suffix a:hover,
  .ant-input-clear-icon:hover {
  }

  & span[class^="ant-input-affix-wrapper"] {
    padding: 9px 14px;
  }
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: #ff0000;
  .anticon {
    margin-right: 8px;
  }
`;

const Label = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
`;
type Props = {
  [x: string]: unknown;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  helperText?: string;
};
const TextInput2 = ensuredForwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<InputRef> | undefined) => {
    const { error, className, helperText, ...rest } = props;
    return (
      <>
        {props.label && <Label>{props.label}</Label>}
        <div className={className}>
          <StyledInput
            value={props["value"] ?? null}
            onChange={props["onChange"] ?? null}
            {...rest}
            ref={ref}
            allowClear={{ clearIcon: <AiFillCloseCircle /> }}
            {...(error && { status: "error" })}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error ? (
            <Error>
              <AiFillCloseCircle className="anticon" />
              {error}
            </Error>
          ) : null}
        </div>
      </>
    );
  }
);

TextInput2.displayName = "TextInput2";

export default TextInput2;
