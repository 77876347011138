import Callback from "@/views/auth/callback";

const authRoute = () => {
  return [
    {
      path: "/auth/callback",
      element: <Callback />,
      handle: {},
    },
  ];
};
export default authRoute;
