import { Layout } from "antd";
import { ReactNode } from "react";
const { Content } = Layout;
import { Scrollbars } from "react-custom-scrollbars-2";
import styled from "styled-components";
import imgUrl from "@/assets/images/loginSideImage.svg";
import logoUrl from "@/assets/images/logo_black_text.svg";
import colors from "@/theme/default/colors";

const CustomLogo = styled.img`
  height: calc(56px + 20px);
  padding: 10px;
`;

const SideImage = styled.div<{ src?: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  padding: 16px 40px;
`;

const CustomLayout = styled(Layout)`
  background-color: ${colors.layoutB.background};
`;

// const NameTag = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 75%;
//   transform: translate(-50%, -50%);
//   padding: 20px 40px;
//   font-size: 48px;
//   border-radius: 20px;
//   white-space: nowrap;

//   h5 {
//     font-weight: 700;
//   }

//   @media screen and (max-width: 1199px) {
//     font-size: 36px;
//     padding: 20px 28px;
//   }
// `;

type Props = {
  children: ReactNode;
};

const LayoutB = (props: Props) => {
  const { children } = props;

  return (
    <div className="container-fluid g-0 h-screen">
      <div className="row g-0">
        <div className="md:col-6">
          <CustomLayout>
            <div className="row g-0">
              <div className="col-12">
                <Header className="flex items-center justify-between flex-row">
                  <CustomLogo src={logoUrl} />
                  <div className="flex items-center justify-evenly"></div>
                </Header>
              </div>
            </div>
            <div className="row g-0">
              <Content>
                <Scrollbars style={{ height: "calc(100vh - 108px)" }}>
                  {children}
                </Scrollbars>
              </Content>
            </div>
          </CustomLayout>
        </div>
        <div className="hidden md:block md:col-6">
          <CustomLayout>
            <Content>
              {/* <NameTag></NameTag> */}
              <SideImage src={imgUrl}></SideImage>
            </Content>
          </CustomLayout>
        </div>
      </div>
    </div>
  );
};

export default LayoutB;
