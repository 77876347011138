import ActivitiesWeekly from "./components/ActivitiesWeekly";
import BasicInfo from "./components/BasicInfo";
import HealthWeekly from "./components/HealthWeekly";
import OverallRating from "./components/OverallRating";
import Personality from "./components/Personality";
import SpecialReminders from "./components/SpecialReminders";

const ProfileOverview = () => {
  return (
    <div className="flex gap-5">
      <section className="flex flex-col gap-5 w-1/3 min-w-[300px]">
        <BasicInfo />
        <Personality />
      </section>

      <section className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-2/3 min-w-[400px]">
        <OverallRating />
        <SpecialReminders />
        <ActivitiesWeekly />
        <HealthWeekly />
      </section>
    </div>
  );
};

export default ProfileOverview;
