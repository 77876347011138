import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { sleepLineChartConfig } from "@/lib/mock";
import { HourlySleepData } from "@/types/api/profile/UserProfile";
import { SleepState } from "@/types/enum/profile";
import { Bar, BarChart, XAxis } from "recharts";

interface Props {
  chartSleepData: HourlySleepData[];
}

const SleepBarChart = ({ chartSleepData }: Props) => {
  return (
    <ChartContainer config={sleepLineChartConfig}>
      <BarChart data={chartSleepData}>
        <XAxis
          dataKey="hour"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="dashed" />}
        />

        <Bar
          dataKey={SleepState.AWAKE}
          type="natural"
          fill={sleepLineChartConfig.awake.color}
          stackId={SleepState.AWAKE}
          radius={4}
        />
        <Bar
          dataKey={SleepState.LIGHT}
          type="natural"
          fill={sleepLineChartConfig.light.color}
          stackId={SleepState.LIGHT}
          radius={4}
        />
        <Bar
          dataKey={SleepState.DEEP}
          type="natural"
          fill={sleepLineChartConfig.deep.color}
          stackId={SleepState.DEEP}
          radius={4}
        />
        <Bar
          dataKey={SleepState.OFFBED}
          type="natural"
          fill={sleepLineChartConfig.offbed.color}
          stackId={SleepState.OFFBED}
          radius={4}
        />
        <Bar
          dataKey={SleepState.OFFLINE}
          type="natural"
          fill={sleepLineChartConfig.offline.color}
          stackId={SleepState.OFFLINE}
          radius={4}
        />
      </BarChart>
    </ChartContainer>
  );
};

export default SleepBarChart;
