import DateIconDisabled from "@/assets/images/eventManagement/date-picker-after-add-on-disabled.svg";
import FormNext from "@/components/Button/FormNext";
import DatePickerInput from "@/components/Input/DatePickerInput";
import ImagePreviewLoader from "@/components/Input/ImagePreviewLoader";
import SelectInput from "@/components/Input/SelectInput";
import TextInput2 from "@/components/Input/TextInput2";
// import { isoStringToDateJs } from "@/libs/helper";
import FilterDropdown from "@/components/Input/FilterDropdown";
import { mockEventType, mockTime } from "@/lib/mock";
import { Event } from "@/types/api/event/Event";
import Divider from "@mui/material/Divider";
import { Input } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import EventBrief from "./EventBrief";
import Preview from "./Preview";

const { TextArea } = Input;
const AddOnAfterInput = styled(TextInput2)<{ $disabled?: boolean }>`
  & .ant-input-group-addon {
    background-color: transparent;
    border-left: none;
    color: #969696;
    background-color: ${($disabled) => ($disabled ? "#f5f5f5" : "transparent")};
  }
  & span[class^="ant-input-affix-wrapper-disabled"] {
    background-color: transparent;
    padding: 9px 14px;
  }
  &
    span[class^="ant-input-affix-wrapper ant-input-affix-wrapper-disabled css-dev-only-do-not-override-"] {
    border-right: none;
  }
  & span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    padding: 9px 14px;
  }
`;
type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};

const EventSettingsConfirm = ({ onNext, onBack }: Props) => {
  const { t } = useTranslation(["eventManagement", "onboard"]);
  const methods = useFormContext();
  const {
    watch,
    getValues,
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <section className="p-[24px] max-w-[1200px] min-w-[300px] flex flex-row flex-wrap content-center justify-center gap-8">
      <section className="flex flex-col gap-y-4">
        <h1 className="font-bold text-lg text-center">
          {t("Event Management.Settings.Preview")}
        </h1>
        <Preview
          title={watch("eventTitle") ?? ""}
          description={watch("description") ?? ""}
          // Ask backend (Wilson) if imageLayout is updated as a field of request payload and response
          imageLayout={watch("imageLayout") ?? "top"}
          eventType={watch("eventType") ?? ""}
        />
        <EventBrief event={watch() as Event} />
      </section>
      <section className="flex flex-col gap-y-4 min-w-[350px] md:min-w-[450px]">
        <h1 className="font-bold text-lg text-center">
          {t("Event Management.Settings.Event Settings")}
        </h1>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <div className="basis-3/5">
            <Controller
              control={control}
              name="eventPublishDate"
              render={({
                field: { value, onBlur, name },
                fieldState: { error },
              }) => {
                return (
                  <DatePickerInput
                    suffixIcon={<img alt="" src={DateIconDisabled} />}
                    onBlur={onBlur}
                    disabled
                    value={value ?? ""}
                    name={name}
                    label={t("Event Management.Settings.Publish Date")}
                    placeholder={t("Event Management.Settings.Publish Date")}
                    error={error?.message ?? ""}
                  />
                );
              }}
            />
          </div>
          <div className="basis-2/5 relative top-[24px]">
            <Controller
              control={control}
              // Not backend API required field name but for onSubmit combined to "eventPublishTime"
              name="eventPublishTime"
              render={({
                field: { onChange, onBlur, name },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    size="large"
                    options={mockTime}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled
                    value={getValues("eventPublishTime")}
                    name={name}
                    placeholder={t("Time", { ns: "app" })}
                    error={error?.message ?? ""}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <div className="basis-1/2">
            <Controller
              control={control}
              name="startDt"
              render={({ field: { value } }) => {
                return (
                  <FilterDropdown
                    value={value ?? ""}
                    disabled={true}
                    placeholder={t("Start Time", { ns: "app" })}
                    borderStyle="solid"
                    options={mockTime}
                  />
                );
              }}
            />
          </div>
          <div className="basis-1/2">
            <Controller
              control={control}
              // Not backend API required field name but for onSubmit combined to "eventPublishTime"
              name="endDt"
              render={({ field: { value } }) => {
                return (
                  <FilterDropdown
                    value={value ?? ""}
                    disabled={true}
                    placeholder={t("End Time", { ns: "app" })}
                    borderStyle="solid"
                    options={mockTime}
                  />
                );
              }}
            />
          </div>
        </div>
        <Divider sx={{ borderWidth: "0.25px" }} />
        <div className="event-settings-event-title">
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <AddOnAfterInput
                  {...field}
                  label={t("Event Management.Settings.Event Title")}
                  placeholder={t("Event Management.Settings.Event Title")}
                  error={error?.message ?? ""}
                  disabled
                  value={
                    field.value
                      ? field.value
                          .replace(/^<p>/, "")
                          .replace(/<p>/g, "\n")
                          .replace(/<br>/g, "")
                          .replace(/<\/p>/g, "")

                          .replace(/<\/.*>/g, "")
                      : ""
                  }
                />
              );
            }}
          />
        </div>
        <div className="event-settings-description">
          <div className="font-poppins text-base font-normal mb-[5px]">
            {t("Event Management.Settings.Description")}
          </div>
          <Controller
            control={control}
            name="description"
            render={({ field: { value } }) => {
              return (
                <TextArea
                  rows={5}
                  disabled
                  className="rounded-[9px] py-[9px] px-[14px]"
                  value={
                    value
                      ? value
                          .replace(/^<p>/, "")
                          .replace(/<p>/g, "\n")
                          .replace(/<br>/g, "")
                          .replace(/<\/p>/g, "")

                          .replace(/<\/.*>/g, "")
                      : ""
                  }
                />
              );
            }}
          />
        </div>
        <div className="event-settings-description">
          <div className="font-poppins text-base font-normal mb-[5px]">
            {t("Event Management.Settings.Hero Image")}
          </div>
          <Controller
            control={control}
            name="heroImage" // field name TBC, ask backend
            render={() => {
              return (
                <ImagePreviewLoader
                  readonly
                  fileTypeCaption="PNG / JPG / JPEG"
                  file={getValues("heroImage") ?? null}
                />
              );
            }}
          />
        </div>
        <Divider sx={{ borderWidth: "0.25px" }} />
        <div className="event-settings-event-type">
          <Controller
            control={control}
            name="eventType"
            render={({ field, fieldState: { error } }) => {
              return (
                <AddOnAfterInput
                  {...field}
                  label={t("Event Management.Settings.Event Type")}
                  placeholder={t("Event Management.Settings.Event Type")}
                  error={error?.message ?? ""}
                  disabled
                  value={
                    field.value
                      ? mockEventType.filter(
                          (eventType) => eventType.value === field.value
                        )?.[0].label
                      : ""
                  }
                />
              );
            }}
          />
        </div>
      </section>
      <div className="flex gap-4 justify-center mt-[20px] w-full">
        {onBack && (
          <FormNext
            htmlType="button"
            type="default"
            disabled={isSubmitting}
            onClick={() => {
              onBack();
            }}
          >
            {t("Back", { ns: "onboard" })}
          </FormNext>
        )}
        {onNext && (
          <FormNext
            htmlType="button"
            type="primary"
            disabled={isSubmitting}
            onClick={() => {
              onNext();
            }}
          >
            {t("Event Management.Confirm.Confirm")}
          </FormNext>
        )}
      </div>
    </section>
  );
};

export default EventSettingsConfirm;
