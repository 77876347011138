import Published from "@/assets/images/eventManagement/published.svg";
import Publishing from "@/assets/images/eventManagement/publishing.svg";
import FormNext from "@/components/Button/FormNext";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EventSettingsPublished = () => {
  const { t } = useTranslation(["eventManagement", "app"]);
  const navigate = useNavigate();
  const {
    formState: { isSubmitting, isSubmitted },
  } = useFormContext();

  return (
    <section className="p-[24px] min-w-[300px] flex flex-row flex-wrap content-center justify-center gap-8">
      <div className="flex flex-col justify-center content-center gap-y-4">
        <img
          width="325px"
          src={isSubmitting ? Publishing : Published}
          alt={
            isSubmitting && !isSubmitted
              ? t("Event Management.Publish.Publishing")
              : t("Event Management.Publish.Published")
          }
        />
        <div className="text-center font-montserrat text-[1.5rem] font-bold">
          {isSubmitting && !isSubmitted
            ? t("Event Management.Publish.Publishing") + "..."
            : t("Event Management.Publish.Published")}
        </div>
        <div className="font-poppins">
          {t(
            "Event Management.Publish.Invitation has been sent to the potential participants"
          )}
        </div>
        <FormNext
          htmlType="button"
          onClick={() => {
            if (!isSubmitting && isSubmitted) {
              navigate("/event-management/all");
            }
          }}
          isLoading={isSubmitting}
        >
          {isSubmitting && !isSubmitted
            ? t("Processing", { ns: "app" })
            : t("Close", { ns: "app" })}
        </FormNext>
      </div>
    </section>
  );
};

export default EventSettingsPublished;
