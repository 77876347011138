import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ConfigType, Dayjs } from "dayjs";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { isoStringToDateJs } from "@/libs/helper";

type Props = {
  name?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
};

const RoundedMuiDatePicker = ({ name, minDate, maxDate, disabled }: Props) => {
  const { t } = useTranslation(["app"]);
  const methods = useFormContext();
  const { setValue, getValues } = methods;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          sx={[
            {
              "& div[class$='-MuiInputBase-root-MuiOutlinedInput-root']": {
                borderRadius: "9px",
                minWidth: "150px",
              },
            },
          ]}
          minDate={minDate}
          maxDate={maxDate}
          label={t("Date", { ns: "app" })}
          format="MMMM DD, YYYY"
          onChange={(date: ConfigType) => {
            setValue(name ?? "eventDate", date);
          }}
          value={getValues(name ?? "eventDate")}
          disabled={disabled}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default RoundedMuiDatePicker;
