import { FaArrowRight, FaPlus } from "react-icons/fa6";
import { MdCalendarMonth } from "react-icons/md";

const reminders = [
  {
    time: "08 am",
    title: "Lorem ipsum dolor",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    color: "bg-blue-500",
  },
  {
    time: "10 am",
    title: "Lorem ipsum dolor",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    color: "bg-teal-500",
  },
  {
    time: "2 pm",
    title: "Lorem ipsum dolor",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    color: "bg-cyan-500",
  },
  {
    time: "9 pm",
    title: "Lorem ipsum dolor",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    color: "bg-purple-500",
  },
];

const SpecialReminders = () => {
  return (
    <div className="flex flex-col justify-center bg-white shadow rounded-[16px] p-5 gap-5">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold">Special Reminders</h3>
        <FaPlus size={28} />
      </div>
      <div className="flex flex-col gap-5">
        <div className="bg-gradient-to-r from-orange-300 to-orange-500 text-white rounded-[20px] p-5 gap-1 flex flex-col justify-between items-center">
          <div className="flex items-center justify-between w-full">
            <h5 className="text-sm">Recent Achievements</h5>
            <FaArrowRight size={18} />
          </div>
          <div className="flex items-center justify-between w-full">
            <h4 className="text-lg font-bold">Mid-term score</h4>
            <span className="text-3xl font-bold">100</span>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <h4 className="text-xl font-semibold">14 July, 2023</h4>
          <MdCalendarMonth size={42} className="text-orange-500" />
        </div>
        <div className="max-h-64 overflow-y-auto">
          {reminders.map((reminder, index) => (
            <div key={index} className="flex flex-col items-center gap-2">
              <div className="flex items-center gap-2 w-full whitespace-nowrap">
                <h5 className="text-lg text-[#A3AED0]">{reminder.time}</h5>
                <hr className="w-full bg-[#A3AED0]" />
              </div>
              <div
                className={`rounded-[20px] p-5 ml-16 ${reminder.color} text-white flex-1`}
              >
                <h5 className="text-md font-bold">{reminder.title}</h5>
                <p className="text-xs">{reminder.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecialReminders;
