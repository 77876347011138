import { Navigate } from "react-router-dom";
import App from "../App";
import appRoute from "./app/appRoute";
import propertyManagementRoute from "./propertyManagement/propertyManagementRoute";
import applicationManagementRoute from "./applicationManagement/applicationManagementRoute";
import eventManagementRoute from "./eventManagement/eventManagementRoute";
import dashboardRoute from "./dashboard/dashboardRoute";
import systemRoute from "./system/systemRoute";
import approvalListRoute from "./approvalList/approvalListRoute";
import profileManagementRoute from "./profileManagement/profileManagementRoute";
import ssoRoute from "./sso/ssoRoute";
import sessionManagementRoute from "./sessionManagement/sessionManagementRoute";
import authRoute from "./auth/authRoute";
const route = () => {
  return [
    {
      // Go to default page
      // If the link missing language, add it back, default en
      path: "",
      element: <App />,
      children: [
        ...appRoute(),
        ...dashboardRoute(),
        ...approvalListRoute(),
        ...propertyManagementRoute(),
        ...applicationManagementRoute(),
        ...eventManagementRoute(),
        ...profileManagementRoute(),
        ...systemRoute(),
        ...ssoRoute(),
        ...authRoute(),
        ...sessionManagementRoute(),
        {
          path: "*",
          element: <Navigate to={`/`} replace />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={`/`} replace />,
    },
  ];
};

export default route;
