// Don't add any asynchronous logic or other "side effects" in reducer
// For example, logging a value to the console, ajax
// Just keep it simple
import { createSlice } from "@reduxjs/toolkit";
import type Tabs from "@/types/components/Tabs/EditableTabs/Tabs1";

interface ApplicationManagementState {
  allApplication: {
    tabs: Tabs[];
    activeTab: string | undefined;
  };
  newApplication: {
    step: number;
    isLoading?: boolean;
  };
}
// !! Remember add the new flags to here, if any !!
const initialState: ApplicationManagementState = {
  allApplication: {
    tabs: [],
    activeTab: undefined,
  },
  newApplication: {
    step: 1,
    isLoading: false,
  },
};

export const applicationManagementSlice = createSlice({
  name: "applicationManagement",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAllApplicationActiveTab: (state, action) => {
      return {
        ...state,
        allApplication: {
          ...state.allApplication,
          activeTab: action.payload,
        },
      };
    },
    setAllApplicationTabs: (state, action) => {
      return {
        ...state,
        allApplication: {
          ...state.allApplication,
          tabs: action.payload,
        },
      };
    },
    setNewApplicationStep: (state, action) => {
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          step: action.payload,
        },
      };
    },
    setNewApplicationIsLoading: (state, action) => {
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          isLoading: action.payload,
        },
      };
    },
  },
});
export const {
  setAllApplicationActiveTab,
  setAllApplicationTabs,
  setNewApplicationStep,
  setNewApplicationIsLoading,
} = applicationManagementSlice.actions;

export default applicationManagementSlice.reducer;
