/* eslint-disable react-refresh/only-export-components */
import Loading from "@/components/Loading/Loading";
import { checkLoggedIn } from "@/lib/helper";
import NewEvent from "@/views/eventManagement/newEvent/NewEvent";
import loadable from "@loadable/component";
import { Trans } from "react-i18next";
const EventManagement = loadable(
  () => import("@/views/eventManagement/EventManagement"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const AllEvent = loadable(() => import("@/views/eventManagement/AllEvent"), {
  fallback: <Loading isLoading={true} />,
});

const eventManagementRoute = () => {
  void checkLoggedIn;
  return [
    {
      path: "/event-management",
      element: <EventManagement />,
      handle: {
        crumb: () => {
          return {
            display: <Trans i18nKey="All Event.Event" ns="eventManagement" />,
            route: `/event-management`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
      children: [
        {
          path: `all`,
          element: <AllEvent />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans i18nKey="All Event.All Event" ns="eventManagement" />
                ),
                route: `/event-management/all`,
              };
            },
          },
        },
        {
          path: `new`,
          element: <NewEvent />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans
                    i18nKey="Event Management.New Event"
                    ns="eventManagement"
                  />
                ),
                route: `/event-management/new`,
              };
            },
          },
        },
      ],
    },
  ];
};

export default eventManagementRoute;
