import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en_app from "./en/app.json";
import en_onboard from "./en/onboard.json";
import en_propertyManagement from "./en/propertyManagement.json";
import en_system from "./en/system.json";
import en_applicationManagement from "./en/applicationManagement.json";
import en_eventManagement from "./en/eventManagement.json";
import en_dashboard from "./en/dashboard.json";
import en_approvalList from "./en/approvalList.json";
import en_profileManagement from "./en/profileManagement.json";
import en_sessionManagement from "./en/sessionManagement.json";
import zh_app from "./zh-HK/app.json";
import zh_onboard from "./zh-HK/onboard.json";
import zh_propertyManagement from "./zh-HK/propertyManagement.json";
import zh_system from "./zh-HK/system.json";
import zh_applicationManagement from "./zh-HK/applicationManagement.json";
import zh_eventManagement from "./zh-HK/eventManagement.json";
import zh_dashboard from "./zh-HK/dashboard.json";
import zh_approvalList from "./zh-HK/approvalList.json";
import zh_profileManagement from "./zh-HK/profileManagement.json";
import zh_sessionManagement from "./zh-HK/sessionManagement.json";

const resources = {
  en: {
    app: en_app,
    onboard: en_onboard,
    propertyManagement: en_propertyManagement,
    system: en_system,
    applicationManagement: en_applicationManagement,
    eventManagement: en_eventManagement,
    dashboard: en_dashboard,
    approvalList: en_approvalList,
    profileManagement: en_profileManagement,
    sessionManagement: en_sessionManagement,
  },
  "zh-HK": {
    app: zh_app,
    onboard: zh_onboard,
    propertyManagement: zh_propertyManagement,
    system: zh_system,
    applicationManagement: zh_applicationManagement,
    eventManagement: zh_eventManagement,
    dashboard: zh_dashboard,
    approvalList: zh_approvalList,
    profileManagement: zh_profileManagement,
    sessionManagement: zh_sessionManagement,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    debug: true,
    defaultNS: "app",
    fallbackLng: "zh-HK",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      // order and from where user language should be detected
      order: [
        "cookie",
        // "subdomain",
        // "querystring",
        // "localStorage",
        // "sessionStorage",
        // "navigator",
        // "htmlTag",
        // "path",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lang",
      lookupCookie: "lang",
      lookupLocalStorage: "lang",
      lookupSessionStorage: "lang",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: import.meta.env.HOST_DOMAIN,

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
  });

export default i18n;
