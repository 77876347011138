import { AppDispatch, useAppSelector } from "@/redux/store";
import { ActiveArea } from "./components/ActiveArea";
import BasicInfo from "./components/BasicInfo";
import SleepCondition from "./components/SleepCondition";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { fetchProfileIOT } from "@/redux/slices/profileManagementSlice";
import Loading from "@/components/Loading/Loading";
import { HourlySleepData } from "@/types/api/profile/UserProfile";
import ProfileIOTDetail from "./ProfileIOTDetail";
import Health from "./components/Health";
import { convertSleepChartData, removeIdPrefix } from "@/lib/helper";

enum ActivePanel {
  OVERALL = "overall",
  SLEEP_CONDITION = "sleepCondition",
}

const ProfileIOT = () => {
  const { activeProfile } = useAppSelector(
    (state) => state.profileManagement.allProfile
  );

  const { loading, data: profileIOT } = useAppSelector(
    (state) => state.profileManagement.iot
  );

  const dispatch = useDispatch<AppDispatch>();

  const [chartSleepData, setChartSleepData] = useState<HourlySleepData[]>([]);

  const [activePanel, setActivePanel] = useState<ActivePanel>(
    ActivePanel.OVERALL
  );

  useEffect(() => {
    if (activeProfile) {
      const id = removeIdPrefix(activeProfile.id);
      dispatch(
        fetchProfileIOT({
          userId: id,
          date: dayjs().toISOString(),
        })
      );
    }
  }, [activeProfile, dispatch]);

  useEffect(() => {
    if (profileIOT) {
      setChartSleepData(convertSleepChartData(profileIOT.report.result));
    }
  }, [profileIOT]);

  return (
    <div className="container w-full h-full">
      <div className="w-full flex gap-5">
        <BasicInfo />
        {activePanel === ActivePanel.OVERALL && (
          <div className="w-full flex flex-col gap-5">
            <div className="flex-grow flex flex-col gap-5">
              <div className="grid grid-cols-2 gap-3">
                <ActiveArea />
                <Loading isLoading={loading}>
                  <SleepCondition
                    chartSleepData={chartSleepData}
                    setActivePanel={() =>
                      setActivePanel(ActivePanel.SLEEP_CONDITION)
                    }
                  />
                </Loading>
              </div>
            </div>
            <Health />
          </div>
        )}
        {activePanel === ActivePanel.SLEEP_CONDITION && (
          <ProfileIOTDetail
            chartSleepData={chartSleepData}
            onBack={() => setActivePanel(ActivePanel.OVERALL)}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileIOT;
