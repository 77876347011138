import FormNext from "@/components/Button/FormNext";
import { useBuildingOptions2 } from "@/lib/hook";
import { venueTypeOptions } from "@/lib/mock";
import { VenueType } from "@/types/enum/event";
import { Property } from "@/types/propertyManagement/Property";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import buildingUrl from "@/assets/images/propertyManagement/black-building.svg";
import otherBuildingUrl from "@/assets/images/propertyManagement/white-building.svg";

type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};

const EventLocation = ({ mode = "NEW", onNext, onBack }: Props) => {
  const { t } = useTranslation(["eventManagement", "onboard", "app"]);

  const methods = useFormContext();
  const { control, setValue, watch } = methods;
  const [buildingOptions, setBuildingOptions] = useState<Property[]>([]);

  useEffect(() => {
    const fetchBuildingOptions = async () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const options = await useBuildingOptions2();
      const tmp = [...options].map((option) => {
        return {
          ...option,
          venueType: VenueType.INTERNAL_VENUE,
        };
      });
      setBuildingOptions(tmp);
    };
    fetchBuildingOptions();
  }, []);

  const handleBuildingChange = (building: Property) => {
    const selectedBuilding = watch("building");
    if (selectedBuilding && selectedBuilding.id === building.id) {
      setValue("propertyId", "");
      setValue("building", null);
      setValue("venueType", "");
      setValue("address", "");
      setValue("area", "");
      setValue("district", "");
      setValue("street", "");
    } else {
      setValue("propertyId", building.id);
      setValue("building", building);
      setValue("venueType", building.venueType);
      setValue(
        "address",
        `${building.address?.country}, ${building.address?.city}`
      );
      setValue("area", building.address?.area);
      setValue("district", building.address?.district);
      setValue("street", building.address?.streetName);
    }
  };

  return (
    <section className="p-[24px] max-w-[1200px] min-w-[300px] flex flex-col content-center justify-center gap-y-4">
      <h1 className="text-[1.5rem] font-bold text-center">
        {t("Event Management.Location.Where will the event be hold")}
      </h1>
      <div className="flex flex-row flex-wrap justify-center p-4 gap-16">
        <Controller
          control={control}
          name="eventLocation"
          rules={{
            required: {
              value: true,
              message: t(
                "Event Management.Location.Errors.Please select a location"
              ),
            },
          }}
          render={() => {
            return (
              <>
                {venueTypeOptions.map((eachLocation, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setValue("venueType", eachLocation.value);
                      setValue("typeId", eachLocation.id);
                      if (eachLocation.value === VenueType.VIRTUAL) {
                        setValue("building", null);
                        setValue("propertyId", "");
                        setValue("address", "");
                        setValue("area", "");
                        setValue("district", "");
                        setValue("street", "");
                      }
                    }}
                    className={classNames(
                      "min-w-[180px] flex flex-col content-center justify-center p-6 rounded-[32px] hover:bg-[#FFF3E9] hover:cursor-pointer",
                      (mode !== "EDIT" &&
                        eachLocation.value === watch("venueType")) ||
                        eachLocation.id === watch("typeId")
                        ? "bg-[#FFF3E9]"
                        : mode === "EDIT" &&
                            eachLocation.value === watch("venueType")
                          ? "bg-[#FFF3E9]"
                          : ""
                    )}
                  >
                    <div className="mb-4 self-center">
                      <img
                        width="150px"
                        src={eachLocation.picture}
                        alt="Motel"
                      />
                    </div>
                    <div className="flex flex-col jusfity-center content-center min-w-[200px]">
                      <div className="text-center font-bold text-lg">
                        {eachLocation.label}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            );
          }}
        />
      </div>
      {watch("venueType") !== VenueType.VIRTUAL &&
        watch("venueType") !== null &&
        watch("venueType") !== undefined && (
          <div>
            <h3 className="text-[1.5rem] font-bold text-center">
              Select Physical Building
            </h3>
            <ol className="grid grid-cols-5 py-10 gap-5 items-center justify-center">
              {buildingOptions.map((building, idx) => (
                <li
                  onClick={() => handleBuildingChange(building)}
                  key={idx}
                  className={`min-w-[180px] rounded-[32px] p-4 
                ${
                  watch("building")?.id === building.id
                    ? "bg-[#FFF3E9]"
                    : "hover:bg-[#FFF3E9]"
                }
                cursor-pointer`}
                >
                  <div className="flex flex-col justify-center items-center flex-wrap">
                    <img src={buildingUrl} />
                    <div className="text-lg font-semibold text-[#343434] my-[6px]">
                      {building.name}
                    </div>
                    <div className="text-sm">{building.address?.city}</div>
                  </div>
                </li>
              ))}
              <li
                onClick={() =>
                  handleBuildingChange({
                    id: "other",
                    name: "Other Location",
                    address: {
                      streetNumber: "",
                      streetName: "",
                      district: "",
                      area: "",
                      city: "",
                      country: "",
                    },
                    venueType: VenueType.EXTERNAL_VENUE,
                  })
                }
                className={`min-w-[180px] rounded-[32px] p-4 
                ${
                  watch("building")?.id === "other"
                    ? "bg-[#FFF3E9]"
                    : "hover:bg-[#FFF3E9]"
                }
                cursor-pointer`}
              >
                <div className="flex flex-col justify-center items-center flex-wrap">
                  <img src={otherBuildingUrl} />
                  <div className="text-lg font-semibold text-[#343434] my-[6px]">
                    Other Location
                  </div>
                </div>
              </li>
            </ol>
          </div>
        )}

      <div className="flex justify-center gap-4">
        {onBack && (
          <FormNext
            htmlType="button"
            type="default"
            onClick={() => {
              onBack();
            }}
          >
            {t("Back", { ns: "onboard" })}
          </FormNext>
        )}
        {onNext && (
          <FormNext
            disabled={!watch("venueType")}
            htmlType="button"
            onClick={() => {
              onNext();
            }}
          >
            {t("Next", { ns: "onboard" })}
          </FormNext>
        )}
      </div>
    </section>
  );
};

export default EventLocation;
