export enum EventType {
  SPORTS = "SPORTS",
  ENTERTAINMENT = "ENTERTAINMENT",
  ART = "ART",
  EDUCATIONAL = "EDUCATIONAL",
  OTHER = "OTHER",
  ALL = "ALL",
}

export enum VenueType {
  VIRTUAL = "VIRTUAL",
  INTERNAL_VENUE = "INTERNAL_VENUE",
  EXTERNAL_VENUE = "EXTERNAL_VENUE",
}

export enum UnitType {
  BEDROOM = "BEDROOM",
  ACTIVITY_ROOM = "ACTIVITY_ROOM",
  HALL = "HALL",
  ALL = "ALL",
}
