/* eslint-disable react-refresh/only-export-components */
import i18n from "@/i18n/i18n";
import loadable from "@loadable/component";
import Loading from "@/components/Loading/Loading";
import { checkLoggedIn } from "@/lib/helper";

const PropertyManagement = loadable(
  () => import("@/views/propertyManagement/PropertyManagement"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const AllProperty = loadable(
  () => import("@/views/propertyManagement/AllProperty"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const NewProperty = loadable(
  () => import("@/views/propertyManagement/NewProperty/NewProperty"),
  {
    fallback: <Loading isLoading={true} />,
  }
);

const propertyManagementRoute = () => {
  void checkLoggedIn;
  return [
    {
      path: "/property-management",
      element: <PropertyManagement />,
      handle: {
        crumb: () => {
          return {
            display: i18n.t("SidebarMenu.Property management.Property"),
            route: `/property-management`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
      children: [
        {
          path: `all`,
          element: <AllProperty />,
          handle: {
            crumb: () => {
              return {
                display: i18n.t("SidebarMenu.Property management.All"),
                route: `/property-management/all`,
              };
            },
          },
        },
        {
          path: `new`,
          element: <NewProperty />,
          handle: {
            crumb: () => {
              return {
                display: i18n.t("SidebarMenu.Property management.New"),
                route: `/property-management/new`,
              };
            },
          },
        },
      ],
    },
  ];
};

export default propertyManagementRoute;
