import styled from "styled-components";
import { Button } from "antd";
import colors from "@/theme/default/colors";
import { lighten } from "polished";

const Button2 = styled(Button)<{ active?: boolean }>`
  background-color: ${({ active = true }) =>
    active
      ? colors.button.button2.background
      : colors.button.button2.background + "80"};
  color: ${({ active = true }) =>
    active ? colors.button.button2.color : colors.button.button2.color + "80"};
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  padding: 16px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ active = true }) =>
      active
        ? lighten(0.1, colors.button.button2.background)
        : colors.button.button2.background + "80"} !important;
    color: ${({ active = true }) =>
      active
        ? colors.button.button2.color
        : colors.button.button2.color + "80"} !important;
  }
`;

export default Button2;
