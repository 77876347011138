/* eslint-disable react-refresh/only-export-components */
import { Trans } from "react-i18next";
import loadable from "@loadable/component";
import Loading from "@/components/Loading/Loading";
import { checkLoggedIn } from "@/lib/helper";

const ApplicationManagement = loadable(
  () => import("@/views/applicationManagement/ApplicationManagement"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const AllApplication = loadable(
  () => import("@/views/applicationManagement/AllApplication"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const NewApplication = loadable(
  () => import("@/views/applicationManagement/NewApplication/NewApplication"),
  {
    fallback: <Loading isLoading={true} />,
  }
);

const applicationManagementRoute = () => {
  void checkLoggedIn();
  return [
    {
      path: "/application-management",
      element: <ApplicationManagement />,
      handle: {
        crumb: () => {
          return {
            display: (
              <Trans
                ns="app"
                i18nKey="SidebarMenu.Application management.Application"
              />
            ),
            route: `/application-management`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
      children: [
        {
          path: `all`,
          element: <AllApplication />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans
                    ns="app"
                    i18nKey="SidebarMenu.Application management.All"
                  />
                ),
                route: `/application-management/all`,
              };
            },
          },
        },
        {
          path: `new`,
          element: <NewApplication />,
          handle: {
            crumb: () => {
              return {
                display: (
                  <Trans
                    ns="app"
                    i18nKey="SidebarMenu.Application management.New"
                  />
                ),
                route: `/application-management/new`,
              };
            },
          },
        },
      ],
    },
  ];
};

export default applicationManagementRoute;
