import Layout1 from "@/assets/images/eventManagement/layout-1.png";
import Layout2 from "@/assets/images/eventManagement/layout-2.png";
import Layout3 from "@/assets/images/eventManagement/layout-3.png";
import FormNext from "@/components/Button/FormNext";
import ImagePreviewLoader from "@/components/Input/ImagePreviewLoader";
import { cssColorNames } from "@/lib/color";
import { Event } from "@/types/api/event/Event";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import EventBrief from "./EventBrief";
import Preview from "./Preview";

const StyledImg = styled.img`
  box-shadow: 0px 0px 1px 3px rgba(255, 132, 19, 1);
  border-radius: 1rem;
  cursor: pointer;
`;

const StyledReactQuill = styled(ReactQuill)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  & .ql-toolbar.ql-snow {
    border-bottom: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
  }
  & .ql-container.ql-snow {
    border-radius: 8px;
  }
  & .ql-editor.ql-blank {
    border-top: solid 1px #ccc;
    border-radius: 8px;
  }
  & .ql-editor {
    border-top: solid 1px #ccc;
    border-radius: 8px;
  }
  & .ql-font-sans-serif {
    font-family: "sans-serif";
  }
  & .ql-font-Poppins {
    font-family: "Poppins";
  }
  & .ql-picker-item[data-value="sans-serif"]::before {
    font-family: "sans-serif";
    content: "Sans Serif" !important;
  }
  & .ql-picker-item[data-value="Poppins"]::before {
    font-family: "Poppins";
    content: "Poppins" !important;
  }
`;

type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};

const EventInformation = ({ mode = "NEW", onNext, onBack }: Props) => {
  const { t } = useTranslation(["eventManagement", "onboard"]);
  const methods = useFormContext();
  const { control, getValues, setValue, watch, trigger } = methods;
  const descriptionRef = useRef<ReactQuill>(null);
  // This Layout array is not mocked. Don't remove it.
  const layouts = [
    {
      label: t("Event Management.Information.Picture at the middle"),
      id: 1,
      src: Layout1,
      value: "middle",
    },
    {
      label: t("Event Management.Information.Picture at the top"),
      id: 2,
      src: Layout2,
      value: "top",
    },
    {
      label: t("Event Management.Information.Picture at the top-right"),
      id: 3,
      src: Layout3,
      value: "topRight",
    },
  ];
  const colorArr: string[] = Object.keys(cssColorNames).map((color) =>
    color.toLowerCase()
  );

  const Quill = ReactQuill.Quill;
  const fonts = Quill.import("formats/font");
  fonts.whitelist = ["sans-serif", "Poppins"];
  Quill.register(fonts, true);

  // Reference: https://quilljs.com/docs/formats/
  const toolbarOptions = [
    [{ font: fonts.whitelist }],
    [{ color: colorArr }, { background: colorArr }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
  ];
  const modules = {
    toolbar: toolbarOptions,
  };

  useEffect(() => {
    console.log("[EventInformation] Mode : ", mode);
  }, [mode]);

  return (
    <div className="container-fluid p-[40px]">
      <section
        className={classNames(
          "p-[24px] min-h-[700px] max-w-[1200px] min-w-[300px] flex flex-row  content-center justify-center",
          mode === "EDIT" ? "flex-wrap gap-12" : "gap-24"
        )}
      >
        {/* <article className="flex flex-row flex-wrap p-[1rem] gap-8"> */}
        <section className="flex flex-col gap-y-4">
          <h1 className="font-bold text-lg text-center">
            {t("Event Management.Settings.Preview")}
          </h1>

          <Preview
            title={watch("title") ?? ""}
            description={watch("description") ?? ""}
            imageLayout={watch("imageLayout") ?? "top"}
            eventType={watch("eventType") ?? ""}
          />
          <EventBrief event={watch() as Event} />
        </section>
        <section className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[450px]">
          <h1 className="font-bold text-lg text-center">
            {t("Event Management.Settings.Information")}
          </h1>
          <article className="flex flex-col gap-y-[2rem]">
            <div className="information-select-a-layout font-poppins text-base">
              <h2>{t("Event Management.Information.Select a layout")}</h2>
              <div className="flex flex-row gap-8 mt-4">
                <Controller
                  control={control}
                  name="imageLayout"
                  render={() => {
                    return (
                      <>
                        {layouts.map((layout, index) => {
                          if (watch("imageLayout") === layout.value) {
                            return (
                              <StyledImg
                                key={index}
                                alt={layout.label}
                                src={layout.src}
                              />
                            );
                          } else {
                            return (
                              <img
                                className="cursor-pointer"
                                key={index}
                                alt={layout.label}
                                src={layout.src}
                                onClick={() => {
                                  if (watch("imageLayout") === layout.value)
                                    return;
                                  setValue("imageLayout", layout.value);
                                }}
                              />
                            );
                          }
                        })}
                      </>
                    );
                  }}
                />
              </div>
            </div>
            <div className="information-event-title font-poppins text-base">
              <h2>{t("Event Management.Information.Event Title")}</h2>
              <div className="min-w-[300px] max-w-[484px]">
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    validate: (value) =>
                      // Backend limits 255 bytes content
                      new Blob([value]).size < 50 ||
                      t(
                        "Event Management.Information.Errors.Title is too long",
                        {
                          ns: "eventManagement",
                        }
                      ),
                  }}
                  render={({ fieldState: { error } }) => {
                    return (
                      <>
                        <StyledReactQuill
                          preserveWhitespace
                          theme="snow"
                          modules={modules}
                          value={watch("title") ?? ""}
                          onChange={(e) => {
                            setValue("title", e);
                          }}
                          onBlur={() => {
                            trigger("title");
                          }}
                        />
                        <div className="text-[red]">{error?.message}</div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
            <div className="information-description font-poppins text-base">
              <h2>{t("Event Management.Information.Description")}</h2>
              <div className="min-w-[300px] max-w-[484px]">
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    validate: (value) =>
                      // Backend limits 255 bytes content
                      new Blob([value]).size < 256 ||
                      t(
                        "Event Management.Information.Errors.Description is too long",
                        {
                          ns: "eventManagement",
                        }
                      ),
                  }}
                  render={({ fieldState: { error } }) => {
                    return (
                      <>
                        <StyledReactQuill
                          onBlur={() => {
                            trigger("description");
                          }}
                          ref={descriptionRef}
                          theme="snow"
                          modules={modules}
                          value={watch("description") ?? ""}
                          onChange={(e) => {
                            setValue("description", e);
                          }}
                          className="h-[300px]"
                        />
                        <div className="text-[red]">{error?.message}</div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
            <div className="information-preiew-image font-poppins text-base">
              <h2>{t("Event Management.Information.Hero Image")}</h2>
              <Controller
                name="heroImage"
                render={() => {
                  return (
                    <ImagePreviewLoader
                      fileTypeCaption="PNG / JPG / JPEG"
                      file={getValues("heroImage") ?? null}
                    />
                  );
                }}
                control={control}
              />
            </div>
          </article>
        </section>
      </section>

      <div className="flex justify-center gap-4">
        {onBack && (
          <FormNext
            disabled={watch("venueType") == null}
            htmlType="button"
            type="default"
            onClick={() => {
              onBack();
            }}
          >
            {t("Back", { ns: "onboard" })}
          </FormNext>
        )}
        {onNext && (
          <FormNext
            htmlType="button"
            onClick={() => {
              onNext();
            }}
          >
            {t("Next", { ns: "onboard" })}
          </FormNext>
        )}
      </div>
    </div>
  );
};

export default EventInformation;
