import Navbar from "@/components/Navbar/Navbar1";
import SidebarMenu from "@/components/SidebarMenu/SidebarMenu";
import { RootState } from "@/redux/store";
import { Layout } from "antd";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
const { Content } = Layout;

type LayoutAProps = {
  children: ReactNode;
};

const LayoutA = (props: LayoutAProps) => {
  const { sidebarMenu } = useSelector((state: RootState) => state.app);
  const { collapsed, showSubmenu } = sidebarMenu;
  const { children } = props;

  useEffect(() => {
    if (!window?.isChatbotInstalled) {
      if (localStorage.getItem("enableAI") === "true") {
        startAIChatbot();
      }
    } else {
      if (
        localStorage.getItem("enableAI") === "true" &&
        ((window?.isChatbotInstalled() ?? false) === false ||
          window?.isChatbotInstalled() === undefined)
      ) {
        startAIChatbot();
      }
    }
  }, []);

  const startAIChatbot = () => {
    if (window?.removeEmbedChatbot) {
      window.removeEmbedChatbot();
    }
    window.aiChatbotConfig = {
      baseUrl: "https://aibot-demo.altech.hk",
      bots: [
        {
          token: "CxlTKJZ2cnoe736z",
          name: "SBS General Enquiry - Demo",
        },
        {
          token: "ebx3BjHjU5h2KYHy",
          name: "Note Enquiry Bot - Demo",
        },
        {
          token: "oxiTkOpJUKeTiniY",
          name: "SBS Profile Bot - Demo",
        },
      ],
    };
    if (!document.getElementById("ai-chatbot-script")) {
      const script = document.createElement("script");
      script.src = "https://aibot-demo.altech.hk/embed.min.js";
      script.id = "ai-chatbot-script";
      script.addEventListener("load", () => {
        return window?.embedChatbot();
      });
      document.body.appendChild(script);
    } else {
      window?.embedChatbot();
    }
  };

  return (
    <Layout>
      <Navbar />
      <SidebarMenu />
      <Layout>
        <Content
          className={classNames({
            blur: !collapsed || showSubmenu,
            "lg:blur-none": !collapsed || showSubmenu,
          })}
        >
          <Scrollbars style={{ height: "100vh" }}>{children}</Scrollbars>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutA;
