import { GoArrowRight } from "react-icons/go";
import WaterBottle from "@/assets/images/profileManagement/iot_water.png";
import { WeightChart } from "./WeightChart";
import { useTranslation } from "react-i18next";

const Health = () => {
  const { t } = useTranslation(["profileManagement"], {
    keyPrefix: "IOT",
  });

  return (
    <div className="w-full h-full rounded-[32px] bg-white p-8 text-[#1B2559]">
      <div className="flex justify-between items-center">
        <h3 className="text-base font-semibold">{t("Health")}</h3>
        <div className="flex items-center gap-1 cursor-pointer hover:opacity-80">
          <h5 className="text-sm text-[#A3AED0]">{t("All Details")}</h5>
          <GoArrowRight size={15} className="text-primary" />
        </div>
      </div>
      <div className="w-full h-full py-5 flex gap-8 items-center justify-between">
        <div className="flex-grow flex flex-col gap-8">
          <div className="flex justify-between items-center">
            <h5 className="text-base font-semibold">{t("Sex")}</h5>
            <h5 className="text-[22px] font-bold">Female</h5>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="text-base font-semibold">{t("Age")}</h5>
            <h5 className="text-[22px] font-bold">22</h5>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="text-base font-semibold">{t("Height")}</h5>
            <div className="text-right">
              <h5 className="text-[22px] font-bold">175cm</h5>
              <p className="text-xs text-[#A3AED0]">
                {t("Last Update")}: 21 July, 24
              </p>
            </div>
          </div>
        </div>
        <div className="w-[1px] h-full bg-[#E0E0E0]" />
        <div className="flex-grow flex flex-col gap-10">
          <div className="flex justify-between items-center">
            <h5 className="text-base font-semibold text-[#1B2559]">
              {t("Weight")}
            </h5>
            <div className="text-right">
              <h5 className="text-[22px] font-bold">110 lbs</h5>
              <p className="text-xs text-[#A3AED0]">
                {t("Last Update")}: 21 July, 24
              </p>
            </div>
          </div>
          <WeightChart />
        </div>
        <div className="w-[1px] h-full bg-[#E0E0E0]" />
        <div className="flex-grow flex flex-col gap-5 items-center">
          <h4 className="text-base font-serif text-[#1B2559]">
            {t("Water Intake")}
          </h4>
          <div className="flex flex-col items-center">
            <img
              src={WaterBottle}
              alt="water"
              className="w-[153px] object-cover"
            />
            <h1 className="text-[#030229] text-[30px] font-extrabold">
              2,231 ml
            </h1>
          </div>
          <p className="text-[#A3AED0] text-sm">{t("Target")}: 2,000 ml</p>
        </div>
      </div>
    </div>
  );
};

export default Health;
