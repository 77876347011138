import DateIcon from "@/assets/images/eventManagement/date-piacker-after-add-on.svg";
import FormNext from "@/components/Button/FormNext";
import DatePickerInput from "@/components/Input/DatePickerInput";
import SelectInput from "@/components/Input/SelectInput";
import TextInput2 from "@/components/Input/TextInput2";
import { mockEventType, mockGuest, mockTime } from "@/lib/mock";
import { Event } from "@/types/api/event/Event";
import { Dayjs } from "dayjs";
import { ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import EventBrief from "./EventBrief";
import Preview from "./Preview";
const AddOnAfterInput = styled(TextInput2)<{ $disabled?: boolean }>`
  & .ant-input-group-addon {
    background-color: transparent;
    border-left: none;
    color: #969696;
  }
  & span[class^="ant-input-affix-wrapper css-dev"] {
    background-color: transparent;
    border-right: none;
    padding: 9px 14px;
  }
  & span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    padding: 9px 14px;
  }
`;

type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};

const EventSettings = ({ onNext, onBack, mode }: Props) => {
  const { t } = useTranslation(["eventManagement", "onboard"]);
  const methods = useFormContext();
  const { control, getValues, setValue, watch, clearErrors } = methods;

  return (
    <section className="p-[24px] max-w-[1500px] min-w-[300px] flex flex-row flex-wrap content-center justify-center gap-8">
      <section className="flex flex-col gap-y-4">
        <h1 className="font-bold text-lg text-center">
          {t("Event Management.Settings.Preview")}
        </h1>
        <Preview
          title={watch("title") ?? ""}
          description={watch("description") ?? ""}
          imageLayout={watch("imageLayout") ?? "top"}
          eventType={watch("eventType") ?? ""}
        />
        <EventBrief event={watch() as Event} />
      </section>
      <section className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[450px]">
        <h1 className="font-bold text-lg text-center">
          {t("Event Management.Settings.Event Settings")}
        </h1>
        <div className="flex flex-row nowrap gap-x-[1rem]">
          <div className="basis-3/5">
            <Controller
              control={control}
              name="eventPublishDate"
              render={({
                field: { onBlur, value, disabled, name },
                fieldState: { error },
              }) => {
                return (
                  <DatePickerInput
                    suffixIcon={<img alt="" src={DateIcon} />}
                    onBlur={onBlur}
                    disabled={disabled}
                    value={value ?? ""}
                    defaultValue={13}
                    name={name}
                    label={t("Event Management.Settings.Publish Date")}
                    placeholder={t("Event Management.Settings.Publish Date")}
                    error={error?.message ?? ""}
                    onChange={(e: Dayjs) => {
                      setValue("eventPublishDate", e);
                    }}
                    clearIcon={false}
                  />
                );
              }}
            />
          </div>
          <div className="basis-2/5 relative top-[24px]">
            <Controller
              control={control}
              // Not backend API required field name but for onSubmit combined to "eventPublishTime"
              name="eventPublishTime"
              rules={{
                required: {
                  value: true,
                  message: t(
                    "Event Management.Settings.Errors.Please select Event Publish Time"
                  ),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    defaultValue={9}
                    size="large"
                    options={mockTime}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      console.log(e);
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    disabled={
                      // !watch("eventPublishDate") cannot use as 0 is a valid value
                      // 0 becomes falsy value
                      watch("eventPublishDate") === "" ||
                      watch("eventPublishDate") == null
                    }
                    value={value ?? ""}
                    name={name}
                    // label={t("Event Management.Settings.Event Type")}
                    placeholder={t("Time", { ns: "app" })}
                    error={error?.message ?? ""}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="event-settings-event-type">
          <Controller
            control={control}
            name="eventType"
            rules={{
              required: {
                value: true,
                message: t(
                  "Event Management.Settings.Errors.Please select Event Type"
                ),
              },
            }}
            render={({
              field: { onChange, onBlur, disabled, value, name },
              fieldState: { error },
            }) => {
              return (
                <SelectInput
                  size="large"
                  options={mockEventType}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={disabled}
                  value={value ?? ""}
                  name={name}
                  label={t("Event Management.Settings.Event Type")}
                  placeholder={t("Event Management.Settings.Event Type")}
                  error={error?.message ?? ""}
                  allowClear
                  onKeyDown={() => {
                    clearErrors("price");
                  }}
                />
              );
            }}
          />
        </div>
        <div className="event-settings-fee">
          <Controller
            control={control}
            name="price"
            rules={{
              required: {
                value: true,
                message: t(
                  "Event Management.Settings.Errors.Please input the fee"
                ),
              },
              // pattern: {
              //   value: /^\d+(\.\d{1,2})?$/,
              //   message: t(
              //     "Event Management.Settings.Errors.Price has only 2 decimal places"
              //   ),
              // },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <AddOnAfterInput
                    type="number"
                    addonAfter="HKD"
                    {...field}
                    label={t("Event Management.Settings.Fee (Per Person)")}
                    placeholder={t(
                      "Event Management.Settings.Fee (Per Person)"
                    )}
                    error={error?.message ?? ""}
                    $disabled={false}
                    // onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    //   const value = e.target.value;
                    //   const regex = /^\d+(\.\d{1,2})?$/;
                    //   if (!regex.test(value)) {
                    //     setError(
                    //       "price",
                    //       {
                    //         message: t(
                    //           "Event Management.Settings.Errors.Price has only 2 decimal places"
                    //         ),
                    //       },
                    //       { shouldFocus: true }
                    //     );
                    //   }
                    // }}
                    value={watch("price") ?? null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValue("price", e.target.value);
                    }}
                    onFocus={() => {
                      clearErrors("price");
                    }}
                  />
                </>
              );
            }}
          />
        </div>
        <div className="event-settings-max-capacity">
          <Controller
            control={control}
            name="quota"
            rules={{
              required: {
                value: true,
                message: t(
                  "Event Management.Settings.Errors.Please enter Max Capacity"
                ),
              },
              pattern: {
                value: /[0-9]/g,
                message: t(
                  "Event Management.Settings.Errors.Please input number"
                ),
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <AddOnAfterInput
                  type="number"
                  step="1"
                  min="1"
                  {...field}
                  label={t("Event Management.Settings.Max Capacity")}
                  placeholder={t("Event Management.Settings.Max Capacity")}
                  error={error?.message ?? ""}
                  $disabled={false}
                  value={watch("quota") ? +watch("quota") : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue("quota", parseInt(e.target.value));
                  }}
                />
              );
            }}
          />
        </div>
        <div className="event-settings-who-is-joining">
          <Controller
            control={control}
            name="audienceType" // field name TBC, ask backend
            defaultValue="ALL"
            rules={{
              required: {
                value: true,
                message: t(
                  "Event Management.Settings.Errors.Please select Staff In Charge"
                ),
              },
            }}
            render={({
              field: { onBlur, disabled, name },
              fieldState: { error },
            }) => {
              return (
                <SelectInput
                  size="large"
                  options={mockGuest}
                  onChange={(e: string) => {
                    setValue("audienceType", e);
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                  defaultValue="ALL"
                  name={name}
                  label={t("Event Management.Settings.Who is joining")}
                  placeholder={t("Event Management.Settings.Who is joining")}
                  error={error?.message ?? ""}
                  allowClear
                />
              );
            }}
          />
        </div>
        <div className="event-settings-organizer">
          <Controller
            control={control}
            name="organizer" // field name TBC, ask backend
            defaultValue=""
            render={({
              field: { value, onBlur, name },
              fieldState: { error },
            }) => {
              return (
                <TextInput2
                  size="large"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue("organizer", e.target.value);
                    console.log("organizer", getValues("organizer"));
                  }}
                  value={value ?? ""}
                  onBlur={onBlur}
                  defaultValue=""
                  name={name}
                  label={t("Event Management.Settings.Organizer")}
                  placeholder={t("Event Management.Settings.Organizer")}
                  error={error?.message ?? ""}
                  allowClear
                />
              );
            }}
          />
        </div>
      </section>
      <div className="flex justify-center gap-4 mt-[20px] w-full">
        {onBack && (
          <FormNext
            htmlType="button"
            type="default"
            onClick={() => {
              onBack();
            }}
          >
            {t("Back", { ns: "onboard" })}
          </FormNext>
        )}
        {onNext && (
          <FormNext
            htmlType="button"
            onClick={() => {
              onNext();
            }}
          >
            {mode === "EDIT"
              ? t("Save", { ns: "app" })
              : t("Next", { ns: "onboard" })}
          </FormNext>
        )}
      </div>
    </section>
  );
};

export default EventSettings;
