import {
  logout,
  setSidebarMenuCollapsed,
  setSidebarMenuShowSubmenu,
} from "@/redux/slices/appSlice";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import classNames from "classnames";
import { MouseEvent, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import sidebarMenuConfig from "./sidebarMenuConfig";

import { RootState } from "@/redux/store";
import colors from "@/theme/default/colors";

import altech_text_logo from "@/assets/images/altech_logo_text.svg";
import sracp_logo from "@/assets/images/logo.svg";
import sracp_logo_icon from "@/assets/images/logo_icon.svg";
import SidebarMenuConfig from "@/types/app/SidebarMenu/SidebarMenuConfig";
import { useTranslation } from "react-i18next";
import arrowIconUrl from "./img/arrow.svg";
const { Sider } = Layout;

const firstLevelMenuWidth = 286;
const secondLevelMenuWidth = 353;

const CustomSider = styled(Sider)`
  max-height: 100vh;
  background: ${colors.sidebarMenu.background} !important;
  & > .ant-layout-sider-children {
    display: flex;
  }
  & .sidebar-toggle {
    position: absolute;
    right: -10px;
    top: 50%;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: ${colors.sidebarMenu.toggleColor};
    background: ${colors.sidebarMenu.toggleBackground};
    border: none;
    z-index: 10;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: fixed;
      width: 20px;
      height: 100vh;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
const SidebarMenuFistLevelArea = styled.div`
  box-shadow: 6px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: ${firstLevelMenuWidth}px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const SidebarMenuSecondLevelArea = styled.div<{ $show: boolean }>`
  padding: 80px 0 0 0;
  max-width: ${secondLevelMenuWidth}px;
  width: ${(props) => (props.$show ? "100%" : "0")};
  overflow: hidden;
  height: 100%;
  transition-delay: ${(props) => (props.$show ? "0.2s" : "0s")};
  transition-property: width, opacity;
  transition-duration: 0s, 0.2s;
  transition-timing-function: none, ease-in-out;
  opacity: ${(props) => (props.$show ? "1" : "0")};
`;
const SidebarMenuLogo = styled.img<{ $collapsed: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$collapsed ? "34px" : "215px")};
  max-height: 120px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 28px;
  padding-bottom: 28px;
  transition: max-width 0.3s ease-in-out;
`;
const SidebarMenuFirstLevelOffset = styled.div<{ $collapsed: boolean }>`
  padding-left: ${(props) => (props.$collapsed ? "0px" : "16px")};
  padding-right: ${(props) => (props.$collapsed ? "0px" : "16px")};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$collapsed ? "center" : "flex-start")};
`;
const SidebarMenuFistLevel = styled(NavLink)<{
  $collapsed: boolean;
  $key: string;
  $disabled: boolean;
}>`
  display: flex;
  position: relative;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 12px 15px;
  gap: 9px;
  border-radius: 10px;
  justify-content: ${(props) => (props.$collapsed ? "center" : "flex-start")};
  opacity: ${(props) => (props.$disabled ? "0.5" : "1")};
  & > div.icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    & > img {
      height: 24px;
      width: 24px;
    }
  }
  & > div.label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) =>
      props.$key === "logout" ? "#FF8686" : colors.sidebarMenu.menuLabel};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.active,
  &:hover {
    // If $key is logout, then don't apply below styles
    ${(props) =>
      props.$key !== "logout" &&
      `
      background: ${colors.sidebarMenu.firstLevelMenuActiveBackground};
      & > div.icon {
        filter: brightness(0) invert(1);
      }
      & > div.label {
        font-weight: 700;
        color: ${colors.sidebarMenu.firstLevelMenuLabelActive};
      }
      &::before {
        content: url(${arrowIconUrl});
        position: absolute;
        right: 5px;
        top: calc(50% - 22px / 2);
        width: 22px;
        height: 22px;
      }
    `}
  }
`;
const SidebarMenuSecondLevelOffset = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  padding: 20px;
  flex-wrap: wrap;
  overflow: hidden;
`;
const SubmenuTile = styled(Link)<{ disabled: boolean }>`
  width: 148.25px;
  height: 148.25px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12.35px;
  border: 2.5px solid ${colors.sidebarMenu.secondLevelMenuBorder};
  & > div.icon {
    filter: brightness(0) invert(0.6);
  }
  & > div.label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: ${colors.sidebarMenu.menuLabel};
    padding-left: 5px;
    padding-right: 5px;
    filter: brightness(0) invert(0.6);
  }
  &.active,
  &:hover:not([disabled]) {
    background: ${colors.sidebarMenu.secondLevelMenuActiveBackground};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    & > div.icon {
      filter: none;
    }
    & > div.label {
      filter: none;
    }
  }
`;

const SidebarMenu = () => {
  const { t } = useTranslation();
  const { height } = useWindowSize();
  const dispatch = useDispatch();
  const { sidebarMenu } = useSelector((state: RootState) => state.app);
  const { collapsed, showSubmenu } = sidebarMenu;
  const [latestFirstLevelMenuItems, setLatestFirstLevelMenuItems] = useState(
    {} as SidebarMenuConfig
  );

  const [submenuItems, setSubmenuItems] = useState([] as SidebarMenuConfig[]);

  const location = useLocation();

  const isLinkActive = (path: string) => {
    if (!path) return false;

    if (!path.includes("?") && !location.search) {
      return location.pathname === path;
    }

    const [basePath, queryString] = path.split("?");
    const targetParams = new URLSearchParams(queryString);
    const currentParams = new URLSearchParams(location.search);

    if (location.pathname !== basePath) {
      return false;
    }

    const targetStatus = targetParams.get("status");
    const currentStatus = currentParams.get("status");

    if (targetStatus && currentStatus) {
      const targetStatuses = targetStatus.split(",").sort().join(",");
      const currentStatuses = currentStatus.split(",").sort().join(",");
      return targetStatuses === currentStatuses;
    }

    return false;
  };

  const handleFirstLevelMenuClick = (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    item: SidebarMenuConfig
  ) => {
    if (item.disabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (item.key === "logout") {
      dispatch(logout());
      return;
    }
    if (latestFirstLevelMenuItems.key === item.key) {
      if (
        item.children &&
        Array.isArray(item.children) &&
        item.children.length > 0
      ) {
        event.stopPropagation();
        event.preventDefault();
        if (!showSubmenu && collapsed) {
          dispatch(setSidebarMenuCollapsed(false));
        }
        dispatch(setSidebarMenuShowSubmenu(!showSubmenu));
      }
      return;
    }
    setLatestFirstLevelMenuItems({ ...item });
    if (
      item.children &&
      Array.isArray(item.children) &&
      item.children.length > 0
    ) {
      event.stopPropagation();
      event.preventDefault();
      setSubmenuItems(item.children);
      if (collapsed) {
        dispatch(setSidebarMenuCollapsed(false));
      }
      dispatch(setSidebarMenuShowSubmenu(true));
    } else {
      dispatch(setSidebarMenuShowSubmenu(false));
      setSubmenuItems([]);
    }
  };

  useEffect(() => {
    if (collapsed) {
      dispatch(setSidebarMenuShowSubmenu(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  return (
    <CustomSider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={
        showSubmenu
          ? firstLevelMenuWidth + secondLevelMenuWidth
          : firstLevelMenuWidth
      }
    >
      <Button
        className="sidebar-toggle"
        onClick={() => {
          dispatch(setSidebarMenuCollapsed(!collapsed));
        }}
      >
        <LeftOutlined rotate={collapsed ? 180 : 0} />
      </Button>
      <SidebarMenuFistLevelArea>
        <SidebarMenuLogo
          src={collapsed ? sracp_logo_icon : sracp_logo}
          $collapsed={collapsed}
        />
        <Scrollbars style={{ height: Math.max(height - 84 - 220, 192) }}>
          <SidebarMenuFirstLevelOffset $collapsed={collapsed}>
            {
              // Loop through the sidebarMenuConfig array and render the menu items
              (() =>
                sidebarMenuConfig(t).map((item) => (
                  <SidebarMenuFistLevel
                    key={item.key}
                    $key={item.key}
                    to={item.path || ""}
                    $collapsed={collapsed}
                    $disabled={item.disabled || false}
                    onClick={(event) => handleFirstLevelMenuClick(event, item)}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      className={classNames({
                        label: true,
                        hidden: collapsed,
                      })}
                    >
                      {item.label}
                    </div>
                  </SidebarMenuFistLevel>
                )))()
            }
          </SidebarMenuFirstLevelOffset>
        </Scrollbars>
        <img
          src={altech_text_logo}
          alt="Altech"
          style={{
            margin: "auto 10px 10px 10px",
            maxHeight: "10px",
          }}
        />
      </SidebarMenuFistLevelArea>
      <SidebarMenuSecondLevelArea $show={showSubmenu}>
        <Scrollbars style={{ height: "calc(100vh - 80px)" }}>
          <SidebarMenuSecondLevelOffset>
            {(() =>
              // Loop through the submenuItems array and render the menu items
              submenuItems.map((item) => (
                <SubmenuTile
                  key={item.key}
                  disabled={item.disabled || false}
                  to={item.path || ""}
                  onClick={(event) => {
                    if (item.disabled) {
                      event.preventDefault();
                    }
                  }}
                  className={
                    isLinkActive(item.path?.toString() || "") ? "active" : ""
                  }
                >
                  <div className="icon">{item.icon}</div>
                  <div className="label">{item.label}</div>
                </SubmenuTile>
              )))()}
          </SidebarMenuSecondLevelOffset>
        </Scrollbars>
      </SidebarMenuSecondLevelArea>
    </CustomSider>
  );
};
export default SidebarMenu;
