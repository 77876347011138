import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type Tabs from "@/types/components/Tabs/EditableTabs/Tabs1";
import {
  ProfileIOTResponse,
  UserProfile,
} from "@/types/api/profile/UserProfile";
import API from "@/lib/api";

interface ProfileManagementState {
  allProfile: {
    profileTabs: UserProfile[];
    activeProfile?: UserProfile;
    tabs: Tabs[];
    activeTab: string | undefined;
  };
  iot: {
    loading: boolean;
    data: ProfileIOTResponse | null;
  };
}

const initialState: ProfileManagementState = {
  allProfile: {
    profileTabs: [],
    tabs: [],
    activeTab: undefined,
  },
  iot: {
    loading: false,
    data: null,
  },
};

export const fetchProfileIOT = createAsyncThunk(
  "profileManagement/fetchProfileIOT",
  async ({ userId, date }: { userId: string; date: string }) => {
    const res = await API.profile.viewProfileIOT(userId, {
      date: date,
    });
    return res.data.data;
  }
);

export const profileManagementSlice = createSlice({
  name: "profileManagement",
  initialState,
  reducers: {
    setAllProfileActiveTab: (state, action) => {
      return {
        ...state,
        allProfile: {
          ...state.allProfile,
          activeTab: action.payload,
        },
      };
    },
    setAllProfileTabs: (state, action) => {
      return {
        ...state,
        allProfile: {
          ...state.allProfile,
          tabs: action.payload,
        },
      };
    },
    setActiveProfile: (state, action) => {
      return {
        ...state,
        allProfile: {
          ...state.allProfile,
          activeProfile: action.payload,
        },
      };
    },
    setProfileTabs: (state, action) => {
      return {
        ...state,
        allProfile: {
          ...state.allProfile,
          profileTabs: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileIOT.pending, (state) => {
        state.iot.loading = true;
      })
      .addCase(
        fetchProfileIOT.fulfilled,
        (state, action: PayloadAction<ProfileIOTResponse>) => {
          state.iot.loading = false;
          state.iot.data = action.payload;
        }
      )
      .addCase(fetchProfileIOT.rejected, (state) => {
        state.iot.loading = false;
      });
  },
});

export const {
  setAllProfileActiveTab,
  setAllProfileTabs,
  setActiveProfile,
  setProfileTabs,
} = profileManagementSlice.actions;

export default profileManagementSlice.reducer;
