// import { Spin } from "antd";
import { ReactNode } from "react";
import { isChildNull } from "../../lib/reactUtil";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Spin } from "antd";

const CustomSpin = tw(Spin)``;
const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .ant-spin-spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  children?: ReactNode;
  isLoading: boolean;
};

const Loading = ({ isLoading, children, ...props }: Props) => {
  if (isChildNull(children)) {
    return (
      <CenterWrapper>
        <CustomSpin {...props} spinning={isLoading} />
      </CenterWrapper>
    );
  } else {
    return (
      <CustomSpin {...props} spinning={isLoading}>
        {children}
      </CustomSpin>
    );
  }
};

Loading.defaultProps = {
  isLoading: false,
};
export default Loading;
