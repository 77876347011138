import { GoArrowRight } from "react-icons/go";
import { SleepStatusChart } from "@/components/Chart/ProfileIOT/SleepStatusChart";
import { HourlySleepData } from "@/types/api/profile/UserProfile";
import { sleepLineChartConfig } from "@/lib/mock";
import { useTranslation } from "react-i18next";

type Props = {
  chartSleepData: HourlySleepData[];
  setActivePanel: () => void;
};

const SleepCondition = ({ chartSleepData, setActivePanel }: Props) => {
  const { t } = useTranslation(["profileManagement"], {
    keyPrefix: "IOT",
  });

  const sleepLineChartConfigArray = Object.entries(sleepLineChartConfig).map(
    ([, value]) => ({
      label: value.label,
      color: value.color,
    })
  );

  return (
    <div className="w-full h-full rounded-[32px] bg-white p-5 flex flex-col gap-3">
      <div className="w-full h-full flex justify-between items-center">
        <h3 className="text-base font-semibold">{t("Sleep Condition")}</h3>
        <div
          onClick={setActivePanel}
          className="flex items-center gap-1 cursor-pointer hover:opacity-80"
        >
          <h5 className="text-sm text-[#A3AED0]">{t("All Details")}</h5>
          <GoArrowRight size={15} className="text-primary" />
        </div>
      </div>
      <h5 className="text-black text-sm text-center">
        {t("Today Date Sleep Status")}
      </h5>
      <div className="flex-grow w-full h-full">
        <SleepStatusChart data={chartSleepData} />
      </div>
      <div className="flex flex-wrap items-center justify-center gap-3">
        {sleepLineChartConfigArray.map((item) => (
          <div key={item.label} className="flex items-center gap-2">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: item.color }}
            />
            <h5 className="text-sm text-black">{item.label}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SleepCondition;
