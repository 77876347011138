import BreadCrumbs from "@/components/BreadCrumbs/BreadCrumbs";
import ProgressBar from "@/components/ProgressBar/eventManagement/ProgressBar";
import { setNewEventStep } from "@/redux/slices/eventManagementSlice";
import { RootState } from "@/redux/store";
import type FormFields from "@/types/eventManagement/NewEvent/Form";
import { createContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EventInformation from "../components/EventInformation";
import EventLocation from "../components/EventLocation";
import EventLocationUnit from "../components/EventLocationUnit";
import EventOtherLocation from "../components/EventOtherLocation";
import EventSettings from "../components/EventSettings";
import EventSettingsConfirm from "../components/EventSettingsConfirm";
import EventSettingsPublished from "../components/EventSettingsPublished";
import { localToUTC } from "@/lib/helper";
import dayjs from "dayjs";
import API from "@/lib/api";
import { message } from "antd";
import axios from "axios";
import { VenueType } from "@/types/enum/event";
type EventContextType = {
  setHeroImage: React.Dispatch<React.SetStateAction<File | null>>;
  heroImage: File | null;
};

export const EventContext = createContext<EventContextType>({
  setHeroImage: () => {},
  heroImage: null,
});

const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const Form = styled.form<{ $step: number }>`
  min-width: 320px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 32px 60px 0px rgba(84, 95, 122, 0.08);
  display: flex;
  ${(props) =>
    props.$step === 1 ||
    props.$step === 1.1 ||
    props.$step === 1.2 ||
    props.$step === 2 ||
    props.$step === 3 ||
    props.$step === 4 ||
    props.$step === 5
      ? "justify-content: center;"
      : "justify-content: start;"}
  align-items: center;
`;

const NewEvent = () => {
  const { t } = useTranslation(["eventManagement"]);
  const newEvent = useSelector(
    (state: RootState) => state.eventManagement.newEvent
  );
  const dispatch = useDispatch();
  const { step } = newEvent;
  const methods = useForm<FormFields>();
  const { handleSubmit, watch } = methods;
  useEffect(() => {
    // Reset the step to 1 when the component is mounted
    dispatch(setNewEventStep(1));
    return () => {
      // Reset the step to 1 when the component is unmounted
      dispatch(setNewEventStep(1));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [heroImage, setHeroImage] = useState<File | null>(null);
  //TODO: values type should be defined properly once the backend is stable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmission = async (values: any) => {
    console.log("values", values);
    try {
      const effectiveDate = localToUTC(
        values.eventPublishDate
          .set("hour", values.eventPublishTime)
          .set("minute", 0)
          .set("second", 0)
      );
      const startDt = localToUTC(
        dayjs(values.eventDate)
          .set("hour", values.startDt)
          .set("minute", 0)
          .set("second", 0)
      );
      const endDt = localToUTC(
        dayjs(values.eventDate)
          .set("hour", values.endDt)
          .set("minute", 0)
          .set("second", 0)
      );
      console.log({
        title: values.title,
        effectiveDt: effectiveDate,
        description: values.description,
        price: values.price,
        organizer: values.organizer,
        quota: values.quota,
        eventConfig: {
          images: [
            {
              name: values.title,
              url: values.heroImage,
              seq: 0,
            },
          ],
          form: values.imageLayout,
          notificationTemplateName: "event_created",
        },
        startDt: startDt,
        endDt: endDt,
        eventType: values.eventType,
        venueType: values.venueType,
        venueId:
          values.venueType !== VenueType.VIRTUAL
            ? {
                address: values.address,
                street: values.street,
                district: values.district,
                area: values.area,
                propertyId: values.propertyId ?? null,
                unitId: values.unitId ?? null,
              }
            : null,
        paymentMethods: [{ name: "FPS" }],
        audienceType: {
          audienceType: values.audienceType,
        },
      });
      await API.event.createEvent({
        title: values.title,
        effectiveDt: localToUTC(values.publishDate),
        description: values.description,
        price: values.price,
        organizer: values.organizer,
        quota: values.quota,
        eventConfig: {
          images: [
            {
              name: values.title,
              url: values.heroImage,
              seq: 0,
            },
          ],
          form: values.imageLayout,
          notificationTemplateName: "event_created",
        },
        startDt: startDt,
        endDt: endDt,
        eventType: values.eventType,
        venueType: values.venueType,
        venueId:
          values.venueType !== VenueType.VIRTUAL
            ? {
                address: values.address,
                street: values.street,
                district: values.district,
                area: values.area,
                propertyId:
                  values.propertyId !== "other"
                    ? values.propertyId ?? null
                    : null,
                unitId: values.unitId ?? null,
              }
            : null,
      });
      dispatch(setNewEventStep(5));
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        message.error(
          t("Event Management.Location.Errors.Create Event.Failed")
        );
      }
    }
  };

  return (
    <section className="new-event">
      <div className="container-fluid p-[40px]">
        <div className="row">
          <BreadCrumbs />
        </div>
        <div className="row">
          <div className="col-auto flex items-center">
            <Title>{t("Event Management.New Event")}</Title>
          </div>
        </div>
        <div className="row flex-nowrap">
          <div className="col-auto">
            <ProgressBar
              step={step}
              onChangeStep={(step) => dispatch(setNewEventStep(step))}
            />
          </div>
          <div className="col-auto grow basis-[min-content]">
            <EventContext.Provider
              value={{
                heroImage,
                setHeroImage,
              }}
            >
              <FormProvider {...methods}>
                <Form
                  $step={step}
                  onSubmit={handleSubmit((values) => handleSubmission(values))}
                >
                  {step === 1 && (
                    <EventLocation
                      onNext={() => {
                        switch (watch("venueType")) {
                          case VenueType.VIRTUAL:
                          case VenueType.INTERNAL_VENUE:
                            dispatch(setNewEventStep(1.1));
                            break;
                          case VenueType.EXTERNAL_VENUE:
                            dispatch(setNewEventStep(1.2));
                            break;
                        }
                      }}
                    />
                  )}
                  {step === 1.1 && (
                    <EventLocationUnit
                      onBack={() => {
                        dispatch(setNewEventStep(1));
                      }}
                      onNext={() => {
                        dispatch(setNewEventStep(2));
                      }}
                    />
                  )}
                  {step === 1.2 && (
                    <EventOtherLocation
                      onBack={() => {
                        dispatch(setNewEventStep(1));
                      }}
                      onNext={() => {
                        dispatch(setNewEventStep(2));
                      }}
                    />
                  )}
                  {step === 2 && (
                    <EventInformation
                      onNext={() => {
                        dispatch(setNewEventStep(3));
                      }}
                      onBack={() => {
                        switch (watch("venueType")) {
                          case VenueType.VIRTUAL:
                            dispatch(setNewEventStep(1));
                            break;
                          case VenueType.INTERNAL_VENUE:
                            dispatch(setNewEventStep(1.1));
                            break;
                          case VenueType.EXTERNAL_VENUE:
                            dispatch(setNewEventStep(1.2));
                            break;
                        }
                      }}
                    />
                  )}
                  {step === 3 && (
                    <EventSettings
                      onNext={() => {
                        dispatch(setNewEventStep(4));
                      }}
                      onBack={() => {
                        dispatch(setNewEventStep(2));
                      }}
                    />
                  )}
                  {step === 4 && (
                    <EventSettingsConfirm
                      onBack={() => {
                        dispatch(setNewEventStep(3));
                      }}
                      onNext={() => {
                        handleSubmit((values) => handleSubmission(values))();
                      }}
                    />
                  )}
                  {step === 5 && <EventSettingsPublished />}
                </Form>
              </FormProvider>
            </EventContext.Provider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewEvent;
