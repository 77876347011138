import i18n from "@/i18n/i18n";
import { checkLoggedIn } from "@/lib/helper";
import AllProfile from "@/views/profileManagement/AllProfile";

const profileManagement = () => {
  return [
    {
      path: "/profile-management",
      element: <AllProfile />,
      handle: {
        crumb: () => {
          return {
            display: i18n.t("SidebarMenu.Profile management.Profile"),
            route: `/profile-management`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
    },
  ];
};

export default profileManagement;
