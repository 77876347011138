import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useMatches, useNavigate } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import i18n from "./i18n/i18n";
import LayoutWrapper from "./layouts/LayoutWrapper";
import API from "./lib/api";
import {
  appInitialize,
  logout,
  setAPITokenCookie,
  setLayout,
} from "./redux/slices/appSlice";
import { AppDispatch, RootState } from "./redux/store";

type Handle = {
  auth?: () => boolean | boolean;
  layout?: "A" | "B";
  crumb?: (param?: number | string) => { route: string; display: ReactNode };
};

const App = () => {
  const app = useSelector((state: RootState) => state.app);
  const { layout, loggedIn, initialState } = app;
  const location = useLocation();
  const [antdLocale, setAntdLocale] = useState(enUS);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const matches = useMatches();

  useEffect(() => {
    // Update plugin locale
    setAntdLocale(i18n.language === "en" ? enUS : enUS);
    dayjs.locale(i18n.language === "en" ? "en" : "en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const initialize = async () => {
      dispatch(appInitialize(true));

      const token = Cookies.get("API_TOKEN");
      const refreshToken = Cookies.get("REFRESH_TOKEN");
      // const tokenExpireTime = Cookies.get("API_TOKEN_EXPIRES");

      if (
        loggedIn &&
        token &&
        refreshToken
        // && tokenExpireTime
      ) {
        try {
          const res = await API.uaa.refreshToken(refreshToken);
          const {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
            idToken: newIdToken,
            // expiresIn: newExpiresIn,
          } = res.data.data;
          dispatch(
            setAPITokenCookie({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
              idToken: newIdToken,
              // expiresInSecond: newExpiresIn,
            })
          );
        } catch (err) {
          console.error(err);
          dispatch(logout());
          dispatch(appInitialize(false));
          return;
        }
        dispatch(appInitialize(true));
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("initialState", initialState);
    setLoading(!initialState);
  }, [initialState]);

  // =====Handle navigation=====
  useEffect(() => {
    // Default page
    if (location?.pathname === "/" || location == null) {
      if (loggedIn) {
        // Here changes default page after login
        navigate(`/dashboard`, {
          replace: true,
        });
      } else {
        navigate(`/login`, { replace: true });
      }
    }
    // Special handling for login page, forget password page and reset password page
    switch (location?.pathname) {
      case `/login`:
      case `/forget-password`:
      case `/reset-password`:
        if (loggedIn) {
          // Here changes default page after login
          navigate(`/dashboard`, {
            replace: true,
          });
        }
        break;
      default:
        break;
    }
  }, [loggedIn, navigate, location]);

  useEffect(() => {
    // Handle layout
    // Get the last matched route with layout property
    const lastMatchedRoute = matches
      .filter((match) =>
        Boolean((match as { handle?: { layout?: string } }).handle?.layout)
      )
      .slice(-1);
    if (lastMatchedRoute.length > 0) {
      const layout = (
        lastMatchedRoute[0].handle as { layout?: string } | undefined
      )?.layout;
      dispatch(setLayout(layout || "B"));
    } else {
      // Default layout
      dispatch(setLayout("B"));
    }
  }, [dispatch, matches]);

  useEffect(() => {
    if (!initialState) {
      console.log("No initiate yet");
      return;
    }

    const matchedRoutesWithAuth = matches.filter((match) => {
      return Boolean((match.handle as Handle)?.auth);
    });
    console.log("matchedRoutesWithAuth", matchedRoutesWithAuth);
    if (matchedRoutesWithAuth.length > 0) {
      console.log(
        "%c====Start to check page authentication====",
        "color: #453c94"
      );
      if (
        !matchedRoutesWithAuth.every((match) => {
          console.log(`Path: %c${match.pathname}`, "color: #692869");
          return (match.handle as Handle).auth?.();
        })
      ) {
        console.log("%cUnauthorized", "color: #ff0000");
        //redirect to default page
        navigate(`/`, { replace: true });
      }
      console.log(
        "%c====End to check page authentication====",
        "color: #453c94"
      );
    }
  }, [loggedIn, matches, initialState, navigate]);

  return (
    <div id="app">
      <ConfigProvider locale={antdLocale}>
        <Loading isLoading={loading}>
          {!loading && (
            <LayoutWrapper layout={layout}>
              <Outlet />
            </LayoutWrapper>
          )}
        </Loading>
      </ConfigProvider>
    </div>
  );
};

export default App;
