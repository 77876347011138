import { TFunction } from "i18next";
import cardIconUrl from "./img/card.svg";
import eventIconUrl from "./img/event.svg";
import homeIconUrl from "./img/home.svg";
import logoutIconUrl from "./img/logout.svg";
import pieIconUrl from "./img/pie.svg";
import userIconUrl from "./img/user.svg";

const sidebarMenuConfig = (t: TFunction<"translation", undefined>) => [
  {
    key: "dashboard",
    label: t("SidebarMenu.Dashboard"),
    icon: <img src={homeIconUrl} alt={t("SidebarMenu.Dashboard")} />,
    path: `/dashboard`,
    // disabled: true,
  },
  {
    key: "approval-list",
    label: t("SidebarMenu.Approval List"),
    icon: <img src={userIconUrl} alt={t("SidebarMenu.Approval List")} />,
    path: `/approval-list`,
  },
  {
    key: "application-management",
    label: t("SidebarMenu.Application management.Application"),
    icon: (
      <img
        src={userIconUrl}
        alt={t("SidebarMenu.Application management.Application")}
      />
    ),
    path: `/application-management`,
    children: [
      {
        key: "application-management-all",
        label: t("SidebarMenu.Application management.All"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all`,
      },
      {
        key: "application-management-new",
        label: t("SidebarMenu.Application management.New"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/new`,
      },
      {
        key: "application-management-submitted",
        label: t("SidebarMenu.Application management.Submitted"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=SUBMITTED`,
      },
      {
        key: "application-management-pending-referral",
        label: t("SidebarMenu.Application management.Pending Referral"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=PENDING_REFERRAL`,
      },
      {
        key: "application-management-pending-review",
        label: t("SidebarMenu.Application management.Pending Review"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=PENDING_REVIEW`,
      },
      {
        key: "application-management-interview",
        label: t("SidebarMenu.Application management.Interview"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=PENDING_INTERVIEW,CONFIRMED_INTERVIEW,REJECTED_INTERVIEW`,
      },
      {
        key: "application-management-offer",
        label: t("SidebarMenu.Application management.Offer"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=PENDING_OFFER,REJECTED_OFFER`,
      },
      {
        key: "application-management-admission-offer",
        label: t("SidebarMenu.Application management.Admission Offer"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=PENDING_ADMISSION_OFFER`,
      },
      {
        key: "application-management-completed",
        label: t("SidebarMenu.Application management.Completed"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=COMPLETED`,
      },
      {
        key: "application-management-rejected",
        label: t("SidebarMenu.Application management.Rejected"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/application-management/all?status=REJECTED,REJECTED_OFFER`,
      },
    ],
  },
  {
    key: "event-management",
    label: t("SidebarMenu.Event management.Event"),
    icon: (
      <img src={eventIconUrl} alt={t("SidebarMenu.Event management.Event")} />
    ),
    path: `/event-management`,
    children: [
      {
        key: "event-management-all",
        label: t("SidebarMenu.Event management.All"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/event-management/all`,
      },
      {
        key: "event-management-new",
        label: t("SidebarMenu.Event management.New"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        path: `/event-management/new`,
      },
    ],
  },
  {
    key: "session-management",
    label: t("SidebarMenu.Session management.Session"),
    icon: (
      <img
        src={cardIconUrl}
        alt={t("SidebarMenu.Session management.Session")}
      />
    ),
    path: `/session-management`,
  },
  {
    key: "facility-booking",
    label: t("SidebarMenu.Facility booking.Facility Booking"),
    icon: (
      <img
        src={cardIconUrl}
        alt={t("SidebarMenu.Facility booking.Facility Booking")}
      />
    ),
    path: `/facility-booking`,
    children: [
      {
        key: "facility-booking-all",
        label: t("SidebarMenu.Facility booking.All"),
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
        disabled: true,
      },
    ],
  },
  {
    key: "property-management",
    label: t("SidebarMenu.Property management.Property"),
    icon: (
      <img
        src={pieIconUrl}
        alt={t("SidebarMenu.Property management.Property")}
      />
    ),
    path: `/property-management`,
    children: [
      {
        key: "property-management-all",
        label: t("SidebarMenu.Property management.All"),
        path: `/property-management/all`,
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
      },
      {
        key: "property-management-new",
        label: t("SidebarMenu.Property management.New"),
        path: `/property-management/new`,
        icon: (
          <img
            src={eventIconUrl}
            alt={t("SidebarMenu.Event management.Event")}
          />
        ),
      },
    ],
  },
  {
    key: "profile-management",
    label: t("SidebarMenu.Profile management.Profile"),
    icon: (
      <img
        src={cardIconUrl}
        alt={t("SidebarMenu.Profile management.Profile")}
      />
    ),
    // children: [
    //   {
    //     key: "profile-management-all",
    //     label: t("SidebarMenu.Profile management.All"),
    //     path: `/profile-management/all`,
    //     icon: (
    //       <img
    //         src={eventIconUrl}
    //         alt={t("SidebarMenu.Event management.Event")}
    //       />
    //     ),
    //   },
    // ],
    path: `/profile-management`,
  },
  {
    key: "system",
    label: t("SidebarMenu.System.System"),
    icon: <img src={cardIconUrl} alt={t("SidebarMenu.System.System")} />,
    path: `/system`,
    disabled: false,
  },
  {
    key: "logout",
    label: t("SidebarMenu.Log out"),
    icon: <img src={logoutIconUrl} alt={t("SidebarMenu.Log out")} />,
    // path: `/logout`,
  },
];

export default sidebarMenuConfig;
