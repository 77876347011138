import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
// import MuiTypography from "@mui/material/Typography";
import styled from "styled-components";
import { useMatches } from "react-router-dom";
import { memo } from "react";
import Props from "@/types/components/BreadCrumbs/BreadCrumbs";
import { Handle } from "@/types/routes/Route";

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #737373;

  .MuiLink-root {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #737373;
    &:hover {
      color: #000;
    }
  }
`;

const CurrentLocation = styled.div`
  color: #fe6a00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const BreadCrumbs = memo((props: Props) => {
  const matches = useMatches();
  let breadcrumbs = props.breadcrumbs;
  if ((breadcrumbs && breadcrumbs.length === 0) || !breadcrumbs) {
    breadcrumbs = matches
      .filter((match) => Boolean((match.handle as Handle)?.crumb))
      .map((match) => {
        const handle = match.handle as Handle;
        if (handle.crumb) {
          return handle.crumb(match);
        }
      });
  }
  console.log("Breadcrumbs", breadcrumbs);
  return (
    <>
      <StyledBreadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {breadcrumbs && breadcrumbs.length > 0
          ? breadcrumbs.map((breadcrumb, index) => {
              return breadcrumbs && index === breadcrumbs.length - 1 ? (
                <CurrentLocation key={breadcrumb?.display}>
                  {breadcrumb?.display}
                </CurrentLocation>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  href={breadcrumb?.route}
                  key={breadcrumb?.display}
                >
                  {breadcrumb?.display}
                </Link>
              );
            })
          : null}
      </StyledBreadcrumbs>
    </>
  );
});

BreadCrumbs.displayName = "BreadCrumbs";

export default BreadCrumbs;
