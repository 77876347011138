import { ReactNode } from "react";
import LayoutA from "./LayoutA";
import LayoutB from "./LayoutB";

type LayoutWrapperProps = {
  children: ReactNode;
  layout: string;
};

const LayoutWrapper = (props: LayoutWrapperProps) => {
  const { layout, children, ...restProps } = props;
  switch (layout) {
    case "A":
      // console.log("A layout match");
      return <LayoutA {...restProps}>{children}</LayoutA>;
    case "B":
      // console.log("B layout match");
      return <LayoutB {...restProps}>{children}</LayoutB>;
    default:
      console.error("No layout match");
      return null;
  }
};

export default LayoutWrapper;
