import { FaArrowRight } from "react-icons/fa6";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdEventNote } from "react-icons/md";
import { RiBookLine } from "react-icons/ri";

const activities = [
  {
    title: "Learning Hours",
    lastUpdate: "21 July, 23",
    value: 25,
    valueColor: "#1A1A1A",
    icon: <RiBookLine size={28} className="text-[#4318FF]" />,
  },
  {
    title: "Joined Events",
    lastUpdate: "21 July, 23",
    value: 2,
    valueColor: "#1A1A1A",
    icon: <MdEventNote size={28} className="text-[#01B574]" />,
  },
  {
    title: "Gaming Hours",
    lastUpdate: "21 July, 23",
    value: 40,
    valueColor: "#FF4D4D",
    icon: <IoGameControllerOutline size={28} className="text-[#FFB547]" />,
  },
];

const ActivitiesWeekly = () => {
  return (
    <div className="flex flex-col justify-center bg-white shadow rounded-[20px] py-5 px-10 gap-5">
      <h3 className="text-2xl font-bold">Activities (Weekly)</h3>
      <div className="flex flex-col gap-4">
        {activities.map((activity, index) => (
          <div
            key={index}
            className="flex justify-between items-center gap-1 w-full"
          >
            <div className="flex items-center gap-4">
              <div className="w-16 h-16 bg-gray-100 rounded-full p-2 flex items-center justify-center">
                {activity.icon}
              </div>
              <div>
                <p className="text-lg font-bold">{activity.title}</p>
                <p className="text-sm text-[#A3AED0]">
                  Last update: {activity.lastUpdate}
                </p>
              </div>
            </div>
            <h4
              className="text-xl font-bold"
              style={{ color: activity.valueColor }}
            >
              {activity.value}
            </h4>
          </div>
        ))}
      </div>
      <div className="w-auto flex gap-1 justify-center items-center cursor-pointer hover:opacity-80">
        <h4 className="text-[#A3AED0]">All Details</h4>
        <FaArrowRight size={18} className="text-orange-500" />
      </div>
    </div>
  );
};

export default ActivitiesWeekly;
