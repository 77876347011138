import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import GuitarIcon from "@/assets/images/guitar_icon.png";
import FoodieIcon from "@/assets/images/foodie_icon.png";
import GamerIcon from "@/assets/images/gamer_icon.png";

const chartData = [
  { type: "Physical Health", score: 186 },
  { type: "Mental Health", score: 305 },
  { type: "Achievement", score: 237 },
  { type: "Self-care Ability", score: 273 },
  { type: "Social Skills", score: 209 },
];

const hobbies = [
  { icon: GuitarIcon, label: "Guitar" },
  { icon: GamerIcon, label: "Gamer" },
  { icon: FoodieIcon, label: "Foodie" },
];

const Personality = () => {
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div className="bg-white border border-gray-200 rounded-lg p-2">
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
            <p style={{ color: entry.color }}>{`Total: ${entry.value}`}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center h-full bg-white shadow rounded-[20px] py-5 px-10">
      <h3 className="text-2xl font-bold">Personality</h3>
      <div className="h-[300px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart data={chartData}>
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}
            />
            <PolarAngleAxis dataKey="type" />
            <PolarGrid />
            <Radar
              dataKey="score"
              stroke="#FE6A00"
              fill="#FF993C"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
      <div className="flex flex-col items-center gap-4">
        <h5 className="text-xl font-bold">Hobbies</h5>
        <div className="flex gap-10">
          {hobbies.map((hobby, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-around"
            >
              <img src={hobby.icon} alt={hobby.label} />
              <p className="text-sm text-[#A3AED0]">{hobby.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Personality;
