import VerticalAlignDiv from "@/components/Alignment/VerticalAlignDiv";
import FormNext from "@/components/Button/FormNext";
import RoundedMuiDatePicker from "@/components/Datepicker/RoundedMuiDatePicker";
import FilterDropdown from "@/components/Input/FilterDropdown";
import { useFloorRoomOptions } from "@/lib/hook";
import { mockTime } from "@/lib/mock";
import { Unit } from "@/types/api/unit/Unit";
import { UnitType, VenueType } from "@/types/enum/event";
import { Divider, FormControl } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TimeDiv = styled.div<{ $isDateFilled?: boolean }>`
  margin-top: auto;
  margin-bottom: auto;
  &
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated {
    background-color: "white";
  }
  & label {
    display: none;
  }
  & div[class$="-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
    border-radius: 9px;
    background-color: ${(props) =>
      props.$isDateFilled ? "transparent" : "#F5F5F5"};
  }
`;
const DateDiv = styled.div`
  & div.MuiStack-root {
    padding-top: 0;
    margin-top: "auto";
    margin-bottom: "auto";
  }
`;

type Props = {
  mode?: "EDIT" | "NEW";
  onNext?: () => void;
  onBack?: () => void;
};

const EventLocationUnit = ({ mode = "NEW", onBack, onNext }: Props) => {
  const { t } = useTranslation(["eventManagement", "app"]);
  const methods = useFormContext();
  const { control, setValue, getValues, watch } = methods;

  const [disabled, setDisabled] = useState(true);

  const [floorRoomOptions, setFloorRoomOptions] = useState<Unit[]>([]);
  const [floorOptions, setFloorOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [roomOptions, setRoomOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const propertyId = watch("propertyId");
  const watchFloor = watch("floor");
  const venueType = watch("venueType");
  const unitId = watch("unitId");
  const startDt = watch("startDt");
  const endDt = watch("endDt");

  useEffect(() => {
    console.log("[EventLocationUnit] Mode : ", mode);
    const startDt = getValues("startDt");
    const endDt = getValues("endDt");

    // Check type of start and end time if coming back from next step
    // If coming back from non confirm page, use react-hook-form stored value directly
    // If coming back from confirm page, ISO string needs to change back to number
    if (startDt != null && typeof startDt !== "number") {
      setValue("startDt", null);
    }
    // if (startDt != null && typeof startDt === "string") {
    //   setValue("startDt", isoStringToDateJs(startDt).hour());
    // }
    if (endDt != null && typeof endDt !== "number") {
      setValue("endDt", null);
    }
    // if (startDt != null && typeof startDt === "string") {
    //   setValue("endDt", isoStringToDateJs(endDt).hour());
    // }
  }, [getValues, mode, setValue]);

  useEffect(() => {
    const fetchFloorRoomOptions = async () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const options = await useFloorRoomOptions({
        propertyId: propertyId,
        unitType: UnitType.ACTIVITY_ROOM,
      });
      setFloorRoomOptions(options);
      const floors = Array.from(
        new Set(options.map((option) => option.floor))
      ).map((floor) => ({
        value: floor,
        label: floor,
      }));
      setFloorOptions(floors);
    };
    fetchFloorRoomOptions();
  }, [propertyId]);

  useEffect(() => {
    if (watchFloor) {
      const rooms = Array.from(
        new Set(
          floorRoomOptions.filter((option) => option.floor === watchFloor)
        )
      ).map((unit) => ({
        value: unit.id,
        label: unit.room,
      }));
      setRoomOptions(rooms);
      setValue("room", "");
    }
  }, [watchFloor, floorRoomOptions, setValue]);

  useEffect(() => {
    let isDisabled = !startDt || !endDt;
    if (venueType !== VenueType.VIRTUAL) {
      isDisabled = isDisabled || !unitId;
    }
    setDisabled(isDisabled);
  }, [venueType, startDt, endDt, unitId]);

  return (
    <section className="min-w-[300px] max-w-[1200px] flex flex-col content-content justify-start gap-y-4 p-8 w-full">
      <div className="flex flex-row flex-wrap justify-between">
        <div className="font-bold text-lg">Select Date & Time</div>
      </div>
      <div className="flex flex-col gap-y-8">
        <div className="flex flex-row flex-wrap justify-between gap-y-4">
          <div className="font-bold text-lg flex flex-row flex-wrap gap-4 content-center">
            <VerticalAlignDiv>{t("Date", { ns: "app" })}:</VerticalAlignDiv>
            <DateDiv>
              <Controller
                name="eventDate"
                control={control}
                render={() => {
                  return <RoundedMuiDatePicker />;
                }}
              />
            </DateDiv>
            <Divider orientation="vertical" flexItem />
            <VerticalAlignDiv>
              {t("Start Time", { ns: "app" })}
            </VerticalAlignDiv>
            <TimeDiv $isDateFilled={watch("eventDate") != null}>
              <Controller
                control={control}
                name="startDt"
                render={({ field: { value } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      placeholder={t("Start Time", { ns: "app" })}
                      borderStyle="solid"
                      options={mockTime}
                      disabled={watch("eventDate") == null}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (watch("eventDate") == null) return;
                        setValue("startDt", e.target.value);
                      }}
                    />
                  );
                }}
              />
            </TimeDiv>
            <VerticalAlignDiv>{t("to", { ns: "app" })}</VerticalAlignDiv>
            <TimeDiv $isDateFilled={watch("eventDate") != null}>
              <Controller
                control={control}
                name="endDt"
                render={({ field: { value } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      disabled={watch("eventDate") == null}
                      placeholder={t("End Time", { ns: "app" })}
                      borderStyle="solid"
                      options={mockTime}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (getValues("eventDate") == null) return;
                        setValue("endDt", e.target.value);
                      }}
                    />
                  );
                }}
              />
            </TimeDiv>
          </div>
        </div>
        {venueType !== VenueType.VIRTUAL && (
          <div className="flex flex-row flex-wrap justify-start font-bold text-lg gap-4">
            <VerticalAlignDiv>{t("Location", { ns: "app" })}:</VerticalAlignDiv>
            <FormControl
              sx={[
                { marginY: "auto" },
                {
                  "& label[class$='-MuiFormLabel-root-MuiInputLabel-root']": {
                    backgroundColor: "white",
                  },
                },
              ]}
            >
              <Controller
                control={control}
                name="floor"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      borderStyle="solid"
                      placeholder={t("Event Management.Location.Floor")}
                      options={floorOptions}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        onChange(e.target.value);
                        setValue("unitId", "");
                      }}
                    />
                  );
                }}
              />
            </FormControl>
            <FormControl
              sx={[
                { marginY: "auto" },
                {
                  "& label[class$='-MuiFormLabel-root-MuiInputLabel-root']": {
                    backgroundColor: "white",
                  },
                },
              ]}
            >
              <Controller
                control={control}
                name="room"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FilterDropdown
                      value={value ?? ""}
                      borderStyle="solid"
                      placeholder={t("Event Management.Location.Room")}
                      options={roomOptions}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const { value } = e.target;
                        onChange(value);
                        setValue("unitId", value);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </div>
        )}

        <div className="flex gap-4 justify-center">
          {onBack && (
            <FormNext
              htmlType="button"
              type="default"
              onClick={() => {
                onBack();
              }}
            >
              {t("Back", { ns: "onboard" })}
            </FormNext>
          )}
          {onNext && (
            <FormNext
              htmlType="button"
              disabled={disabled}
              onClick={() => {
                onNext();
              }}
            >
              {t("Next", { ns: "onboard" })}
            </FormNext>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventLocationUnit;
