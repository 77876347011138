import {
  useMaterialReactTable as useOriginalMaterialReactTable,
  type MRT_RowData,
  type MRT_TableOptions,
} from "material-react-table";
import { useTranslation } from "react-i18next";
import buildingUrl from "@/assets/images/propertyManagement/black-building.svg";
import dummyFloorUrl from "@/assets/images/applicationManagement/dummyFloor.png";
import dummyRoomUrl from "@/assets/images/applicationManagement/dummyRoom.png";
import dummyBedUrl from "@/assets/images/applicationManagement/dummyBed.png";

import {
  type District,
  type Area,
  type BuildingDataWithLabelValue,
  type FloorDataWithLabelValue,
  type RoomDataWithLabelValue,
  type BedDataWithLabelValue,
  type ApplicationDiscountDataWithLabelValue,
  type Country,
} from "@/types/libs/hook";
import API from "@/lib/api";
import { Property } from "@/types/propertyManagement/Property";
import { Unit } from "@/types/api/unit/Unit";
import { UnitType } from "@/types/enum/event";

const useMaterialReactTable = <TData extends MRT_RowData>(
  tableOptions: MRT_TableOptions<TData>
) => {
  return useOriginalMaterialReactTable({
    ...tableOptions,
    muiTablePaperProps: {
      sx: {
        borderRadius: "24px",
        overflow: "hidden",
        boxShadow: "none",
        padding: 0,
        ".MuiTableRow-head": {
          background: "#2C3432",
        },
        ".MuiTableCell-head": {
          color: "#fff",
          "& .Mui-TableHeadCell-Content-Actions": {
            "& .MuiButtonBase-root": {
              color: "#fff",
            },
          },
          "& .Mui-TableHeadCell-Content-Labels": {
            "& .MuiButtonBase-root": {
              color: "#fff",
              "& .MuiTableSortLabel-icon": {
                color: "#fff !important",
              },
            },
          },
        },
      },
    },
    muiTopToolbarProps: {
      sx: {
        background: "#2C3432",
        ".MuiButtonBase-root, .MuiOutlinedInput-root": {
          color: "#fff",
        },
        ".MuiButtonBase-root.Mui-disabled": {
          color: "#fff",
          opacity: 0.5,
        },
      },
    },
    muiBottomToolbarProps: {
      sx: {
        background: "#2C3432",
        ".MuiFormLabel-root, .MuiTypography-root, .MuiButtonBase-root, .MuiSvgIcon-root, .MuiSelect-select":
          {
            color: "#fff",
          },
      },
    },
    muiFilterTextFieldProps: {
      sx: {
        ".MuiButtonBase-root, .MuiOutlinedInput-root, .MuiInputBase-input": {
          color: "#fff",
        },
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
      },
    },
  });
};

const useDistrictOptions = (area_id?: string) => {
  const { i18n } = useTranslation();
  const districts: District[] = [
    {
      id: "cw",
      area_id: "hk",
      name_en: "Central and Western",
      name_tc: "中西區",
      name_sc: "中西区",
    },
    {
      id: "est",
      area_id: "hk",
      name_en: "Eastern",
      name_tc: "東區",
      name_sc: "东区",
    },
    {
      id: "ild",
      area_id: "nt",
      name_en: "Islands",
      name_tc: "離島",
      name_sc: "离岛",
    },
    {
      id: "kc",
      area_id: "kl",
      name_en: "Kwai Tsing",
      name_tc: "葵青",
      name_sc: "葵青",
    },
    {
      id: "klc",
      area_id: "kl",
      name_en: "Kowloon City",
      name_tc: "九龍城",
      name_sc: "九龙城",
    },
    {
      id: "kt",
      area_id: "kl",
      name_en: "Kwun Tong",
      name_tc: "觀塘",
      name_sc: "观塘",
    },
    {
      id: "nth",
      area_id: "nt",
      name_en: "North",
      name_tc: "北區",
      name_sc: "北区",
    },
    {
      id: "sk",
      area_id: "nt",
      name_en: "Sai Kung",
      name_tc: "西貢區",
      name_sc: "西贡",
    },
    {
      id: "sth",
      area_id: "hk",
      name_en: "Southern",
      name_tc: "南區",
      name_sc: "南区",
    },
    {
      id: "ssp",
      area_id: "kl",
      name_en: "Sham Shui Po",
      name_tc: "深水埗區",
      name_sc: "深水埗",
    },
    {
      id: "st",
      area_id: "nt",
      name_en: "Sha Tin",
      name_tc: "沙田區",
      name_sc: "沙田",
    },
    {
      id: "tm",
      area_id: "nt",
      name_en: "Tuen Mun",
      name_tc: "屯門",
      name_sc: "屯门",
    },
    {
      id: "tp",
      area_id: "nt",
      name_en: "Tai Po",
      name_tc: "大埔",
      name_sc: "大埔",
    },
    {
      id: "tw",
      area_id: "nt",
      name_en: "Tsuen Wan",
      name_tc: "荃灣",
      name_sc: "荃湾",
    },
    {
      id: "wc",
      area_id: "hk",
      name_en: "Wan Chai",
      name_tc: "灣仔",
      name_sc: "湾仔",
    },
    {
      id: "wts",
      area_id: "kl",
      name_en: "Wong Tai Sin",
      name_tc: "黃大仙",
      name_sc: "黄大仙",
    },
    {
      id: "yl",
      area_id: "nt",
      name_en: "Yuen Long",
      name_tc: "元朗",
      name_sc: "元朗",
    },
    {
      id: "ytm",
      area_id: "kl",
      name_en: "Yau Tsim Mong",
      name_tc: "油尖旺",
      name_sc: "油尖旺",
    },
  ];

  if (area_id) {
    return districts
      .filter((district: District) => district.area_id === area_id)
      .map((district: District) => {
        return {
          label: district[`name_${i18n.language as "en" | "tc" | "sc"}`],
          value: district.id,
        };
      });
  } else {
    return districts.map((district: District) => {
      return {
        label: district[`name_${i18n.language as "en" | "tc" | "sc"}`],
        value: district.id,
      };
    });
  }
};

const useAreaOptions = () => {
  const { i18n } = useTranslation();
  const areas: Area[] = [
    {
      id: "nt",
      name_en: "New Territories",
      name_tc: "新界",
      name_sc: "新界",
    },
    {
      id: "hk",
      name_en: "Hong Kong",
      name_tc: "香港",
      name_sc: "香港",
    },
    {
      id: "kl",
      name_en: "Kowloon",
      name_tc: "九龍",
      name_sc: "九龙",
    },
  ];

  return areas.map((area) => {
    return {
      label: area[`name_${i18n.language as "en" | "tc" | "sc"}`],
      value: area.id,
    };
  });
};

const useCountryOptions = () => {
  const { i18n } = useTranslation();
  const countries: Country[] = [
    {
      iso2: "HK",
      iso3: "HKG",
      name: {
        en: "Hong Kong",
        tc: "香港",
        sc: "香港",
      },
    },
    {
      iso2: "CN",
      iso3: "CHN",
      name: {
        en: "China",
        tc: "中國",
        sc: "中国",
      },
    },
    {
      iso2: "TW",
      iso3: "TWN",
      name: {
        en: "Taiwan",
        tc: "台灣",
        sc: "台湾",
      },
    },
  ];

  return countries.map((country) => {
    return {
      label: country.name[i18n.language as "en" | "tc" | "sc"],
      value: country.iso2,
    };
  });
};

const useBuildingOptions: () => BuildingDataWithLabelValue[] = () => {
  return [
    {
      id: "1",
      name: "Building 1",
      img: buildingUrl,
      address: "1234 Building 1",
      label: "Building 1",
      value: "1",
    },
    {
      id: "2",
      name: "Building 2",
      img: buildingUrl,
      address: "1234 Building 2",
      label: "Building 2",
      value: "2",
    },
    {
      id: "3",
      name: "Building 3",
      img: buildingUrl,
      address: "1234 Building 3",
      label: "Building 3",
      value: "3",
    },
  ] as unknown as BuildingDataWithLabelValue[];
};

const useBuildingOptions2 = async (): Promise<Property[]> => {
  try {
    const res = await API.property.listProperties({
      statuses: "ACTIVE",
      page: 1,
      size: 999,
      sort: "createDt,desc",
    });
    return res.data.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const useFloorOptions: () => FloorDataWithLabelValue[] = () => {
  return [
    {
      id: "1",
      name: "G",
      img: dummyFloorUrl,
      label: "G",
      value: "1",
    },
    {
      id: "2",
      name: "1",
      img: dummyFloorUrl,
      label: "1",
      value: "2",
    },
    {
      id: "3",
      name: "2",
      img: dummyFloorUrl,
      label: "2",
      value: "3",
    },
    {
      id: "4",
      name: "3",
      img: dummyFloorUrl,
      label: "3",
      value: "4",
    },
    {
      id: "5",
      name: "4",
      img: dummyFloorUrl,
      label: "4",
      value: "5",
    },
    {
      id: "6",
      name: "5",
      img: dummyFloorUrl,
      label: "5",
      value: "6",
    },
    {
      id: "7",
      name: "6",
      img: dummyFloorUrl,
      label: "6",
      value: "7",
    },
    {
      id: "8",
      name: "7",
      img: dummyFloorUrl,
      label: "7",
      value: "8",
    },
    {
      id: "9",
      name: "8",
      img: dummyFloorUrl,
      label: "8",
      value: "9",
    },
    {
      id: "10",
      name: "9",
      img: dummyFloorUrl,
      label: "9",
      value: "10",
    },
    {
      id: "11",
      name: "10",
      img: dummyFloorUrl,
      label: "10",
      value: "11",
    },
    {
      id: "12",
      name: "11",
      img: dummyFloorUrl,
      label: "11",
      value: "12",
    },
  ];
};

const useRoomOptions: () => RoomDataWithLabelValue[] = () => {
  return [
    {
      id: "1",
      name: "room_a",
      img: dummyRoomUrl,
      label: "Room A",
      value: "1",
    },
    {
      id: "2",
      name: "room_b",
      img: dummyRoomUrl,
      label: "Room B",
      value: "2",
    },
    {
      id: "3",
      name: "room_c",
      img: dummyRoomUrl,
      label: "Room C",
      value: "3",
    },
  ];
};

const useFloorRoomOptions = async ({
  propertyId,
  unitType = UnitType.BEDROOM,
}: {
  propertyId: string;
  unitType?: UnitType;
}): Promise<Unit[]> => {
  if (!propertyId) {
    return [];
  }
  try {
    const res = await API.unit.listUnits({
      propertyId,
      statuses: "AVAILABLE",
      page: 1,
      size: 9999,
      sort: "id,createDt,desc",
      unitType,
    });
    return res.data.data.map((unit: Unit) => ({
      ...unit,
      imgDummyFloor: dummyFloorUrl, // Add dummy img
      imgDummyRoom: dummyRoomUrl, // Add dummy img
      imgDummyBed: dummyBedUrl, // Add dummy img
    }));
  } catch (e) {
    console.log(e);
    return [];
  }
};

const useBedOptions: () => BedDataWithLabelValue[] = () => {
  return [
    {
      id: "1",
      name: "bed_a",
      type: "single",
      img: dummyBedUrl,
      price: 1000.0,
      label: "Bed A",
      value: "1",
    },
    {
      id: "2",
      name: "bed_b",
      type: "single",
      img: dummyBedUrl,
      price: 1000.0,
      label: "Bed B",
      value: "2",
    },
    {
      id: "3",
      name: "bed_c",
      type: "double",
      img: dummyBedUrl,
      price: 1000.0,
      label: "Bed C",
      value: "3",
    },
  ];
};

const useApplicationDiscountOptions: () => ApplicationDiscountDataWithLabelValue[] =
  () => {
    return [
      {
        id: "1",
        name: "discount_a",
        discount: {
          originalPrice: 1000.0,
          discountPrice: 900.0,
        },
        label: "Discount A (-10%)",
        value: "1",
      },
    ];
  };

export {
  useMaterialReactTable,
  useDistrictOptions,
  useAreaOptions,
  useBuildingOptions,
  useBuildingOptions2,
  useFloorOptions,
  useRoomOptions,
  useFloorRoomOptions,
  useBedOptions,
  useApplicationDiscountOptions,
  useCountryOptions,
};
