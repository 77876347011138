import { Pie, PieChart } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { ProfileIOTResponse } from "@/types/api/profile/UserProfile";
import { calculatePercentage } from "@/lib/helper";

const chartConfig = {} satisfies ChartConfig;

type Props = {
  profileIOT?: ProfileIOTResponse | null;
};

export function SleepStagePieChart({ profileIOT }: Props) {
  const total =
    (profileIOT?.statistics?.awake ?? 0) +
    (profileIOT?.statistics?.light ?? 0) +
    (profileIOT?.statistics?.deep ?? 0);

  const chartData = [
    {
      label: "awake",
      duration: profileIOT?.statistics?.awake ?? 0,
      fill: "#85DDFA",
    },
    {
      label: "light",
      duration: profileIOT?.statistics?.light ?? 0,
      fill: "#253940",
    },
    {
      label: "deep",
      duration: profileIOT?.statistics?.deep ?? 0,
      fill: "#416D7A",
    },
  ];

  return (
    <div className="w-full h-[200px]">
      <div className="flex items-center justify-center text-white gap-3">
        <div className="flex items-center justify-center gap-1">
          <h3 className="text-xs font-normal">Awake:</h3>
          <h3 className="text-sm font-semibold">
            {calculatePercentage(profileIOT?.statistics?.awake ?? 0, total)}
          </h3>
        </div>
        <div className="flex items-center justify-center gap-1">
          <h3 className="text-xs font-normal">Light:</h3>
          <h3 className="text-sm font-semibold">
            {calculatePercentage(profileIOT?.statistics?.light ?? 0, total)}
          </h3>
        </div>
        <div className="flex items-center justify-center gap-1">
          <h3 className="text-xs font-normal">Deep:</h3>
          <h3 className="text-sm font-semibold">
            {calculatePercentage(profileIOT?.statistics?.deep ?? 0, total)}
          </h3>
        </div>
      </div>
      <ChartContainer config={chartConfig} className="w-full h-[200px]">
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie data={chartData} dataKey="duration" nameKey="label" />
        </PieChart>
      </ChartContainer>
    </div>
  );
}
