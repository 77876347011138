import { Event } from "@/types/api/event/Event";
import { useTranslation } from "react-i18next";

type Props = {
  event: Event;
};

const EventBrief = ({ event: { organizer, quota } }: Props) => {
  const { t } = useTranslation(["eventManagement", "app"]);
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between">
        <div className="font-poppins w-[75%] text-base text-[#737373]">
          {t("Event Management.Settings.Organizer")}
        </div>
        <div className="text-right text-base">
          {organizer != null && organizer != "" ? organizer : "-"}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="font-poppins w-[75%] text-base text-[#737373]">
          {t("Event Management.Settings.Max Capacity")}
        </div>
        <div className="text-right text-base">{`${
          quota == null ? "-" : isNaN(quota) ? "-" : quota.toString()
        }`}</div>
      </div>
    </div>
  );
};

export default EventBrief;
