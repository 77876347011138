/* eslint-disable react-refresh/only-export-components */
import loadable from "@loadable/component";
import Loading from "@/components/Loading/Loading";
import { Trans } from "react-i18next";
import { checkLoggedIn } from "@/lib/helper";

const ApprovalList = loadable(
  () => import("@/views/app/approvalList/ApprovalList"),
  {
    fallback: <Loading isLoading={true} />,
  }
);
const approvalListRoute = () => {
  void checkLoggedIn;
  return [
    {
      path: "/approval-list",
      element: <ApprovalList />,
      handle: {
        crumb: () => {
          return {
            display: <Trans ns="approvalList" i18nKey="Approval List" />,
            route: `/approval-list`,
          };
        },
        auth: () => checkLoggedIn(),
        layout: "A",
      },
    },
  ];
};
export default approvalListRoute;
