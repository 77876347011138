import { QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./i18n/i18n";
import "./index.scss";
import { queryClient } from "./lib/reactQuery";
import store from "./redux/store";
import route from "./routes/route";
console.log("[Endpoit] " + import.meta.env.VITE_API);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(LocalizedFormat);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("Asia/Hong_Kong");

const routes = route();
const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </Provider>
);
