import { fetchProfileIOT } from "@/redux/slices/profileManagementSlice";
import { AppDispatch, useAppSelector } from "@/redux/store";
import { HourlySleepData } from "@/types/api/profile/UserProfile";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { BsLungsFill } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa6";
import { GoAlert } from "react-icons/go";
import { IoBedSharp } from "react-icons/io5";
import { LuHeartPulse } from "react-icons/lu";
import { useDispatch } from "react-redux";
import SleepBarChart from "../../../../components/Chart/ProfileIOT/SleepBarChart";
import { sleepLineChartConfig } from "@/lib/mock";
import { SleepScoreChart } from "../../../../components/Chart/ProfileIOT/SleepScoreChart";
import { SleepStagePieChart } from "@/components/Chart/ProfileIOT/SleepStagePieChart";
import { DatePicker } from "antd";
import { FaCalendarAlt } from "react-icons/fa";
import Loading from "@/components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { removeIdPrefix } from "@/lib/helper";

type Props = {
  onBack: () => void;
  chartSleepData: HourlySleepData[];
};

const ProfileIOTDetail = ({ onBack, chartSleepData }: Props) => {
  const { t } = useTranslation(["profileManagement"], {
    keyPrefix: "IOT",
  });
  const { activeProfile } = useAppSelector(
    (state) => state.profileManagement.allProfile
  );
  const { data, loading } = useAppSelector(
    (state) => state.profileManagement.iot
  );
  const dispatch = useDispatch<AppDispatch>();

  const [activeDate, setActiveDate] = useState(dayjs());

  const handleChangeDate = (date: Dayjs) => {
    setActiveDate(date);
    const id = removeIdPrefix(activeProfile!.id);
    dispatch(
      fetchProfileIOT({
        userId: id,
        date: date.toISOString(),
      })
    );
  };

  const handleChangeDateClick = () => {
    const datePickerInput = document.querySelector(".ant-picker-input input");
    if (datePickerInput instanceof HTMLElement) {
      datePickerInput.click();
    }
  };

  const sleepLineChartConfigArray = Object.entries(sleepLineChartConfig).map(
    ([, value]) => ({
      label: value.label,
      color: value.color,
    })
  );

  return (
    <div className="w-full ">
      <Loading isLoading={loading}>
        <div className="w-full flex flex-col gap-3">
          <div className="relative flex items-center justify-between">
            <FaArrowLeft
              className="cursor-pointer"
              size={20}
              onClick={() => {
                onBack();
                handleChangeDate(dayjs());
              }}
            />
            <div className="flex items-center justify-center cursor-pointer">
              <h4
                className="font-semibold text-lg text-primary mt-[2px] -mr-[130px]"
                onClick={handleChangeDateClick}
              >
                {activeDate.format("YYYY-MM-DD")}
              </h4>
              <DatePicker
                value={activeDate}
                onChange={(date) => handleChangeDate(date!)}
                className="invisible"
              />

              <FaCalendarAlt
                className="text-primary"
                size={22}
                onClick={handleChangeDateClick}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="w-full h-full flex flex-col gap-3">
              <div className="bg-teal-600 flex-grow gap-3 rounded-[10px] p-5 text-base font-semibold grid grid-cols-2 items-center justify-between">
                <div className="flex flex-col items-center justify-center">
                  <SleepScoreChart score={data?.statistics.score ?? 0} />
                  <h3 className="text-2xl font-semibold text-white mt-3">
                    {t("Sleep Rating")}
                  </h3>
                </div>
                <div className="flex flex-col items-center justify-center gap-3">
                  {data ? (
                    <SleepStagePieChart profileIOT={data} />
                  ) : (
                    <div className="w-full h-[200px] flex items-center justify-center ">
                      <h3 className="text-lg font-semibold text-white">
                        {t("No Data")}
                      </h3>
                    </div>
                  )}
                  <h3 className="text-2xl font-semibold text-white">
                    {t("Sleep Stages")}
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-3">
                <div className="rounded-[10px] p-5 text-base flex items-center justify-evenly bg-emerald-950 text-white">
                  <IoBedSharp size={40} />
                  <div className="flex flex-col">
                    <h3 className="text-sm font-normal">
                      {t("Nighttime Bed Exits")}
                    </h3>
                    <h3 className="text-2xl font-normal">
                      {data?.statistics.offbed ?? "-"}
                    </h3>
                  </div>
                </div>
                <div className="rounded-[10px] p-5 text-base flex items-center justify-evenly bg-yellow-600 text-white">
                  <GoAlert size={40} />
                  <div className="flex flex-col">
                    <h3 className="text-sm font-normal">{t("Alarm Times")}</h3>
                    <h3 className="text-2xl font-normal">
                      {data?.statistics.alarmNum ?? "-"}
                    </h3>
                  </div>
                </div>
                <div className="flex-grow rounded-[10px] p-5 text-base flex items-center justify-evenly bg-orange-400 text-white">
                  <LuHeartPulse size={40} />
                  <div className="flex flex-col">
                    <h3 className="text-sm font-normal">
                      {t("Average Heart Rate")}
                    </h3>
                    <h3 className="text-2xl font-normal">
                      {data?.statistics.heartbeat ?? "-"}
                    </h3>
                  </div>
                </div>
                <div className="rounded-[10px] p-5 text-base flex items-center justify-evenly bg-lime-700 text-white">
                  <BsLungsFill size={40} />
                  <div className="flex flex-col">
                    <h3 className="text-sm font-normal">
                      {t("Average Breathing Rate")}
                    </h3>
                    <h3 className="text-2xl font-normal">
                      {data?.statistics.breath ?? "-"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-[10px] bg-white p-5 text-base font-semibold w-full h-full">
            <h3 className="text-2xl font-semibold">{t("Sleep Stages")}</h3>
            <div className="flex items-center justify-center gap-3">
              {sleepLineChartConfigArray.map((item) => (
                <div key={item.label} className="flex items-center gap-2">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: item.color }}
                  />
                  <h5 className="text-sm text-black">{item.label}</h5>
                </div>
              ))}
            </div>
            {chartSleepData.length > 0 ? (
              <SleepBarChart chartSleepData={chartSleepData} />
            ) : (
              <div className="w-full h-[200px] flex items-center justify-center ">
                <h3 className="text-lg font-semibold text-black">
                  {t("No Data")}
                </h3>
              </div>
            )}
          </div>
        </div>
      </Loading>
    </div>
  );
};

export default ProfileIOTDetail;
