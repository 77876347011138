import { Select, RefSelectProps } from "antd";
import styled from "styled-components";
import { CloseCircleFilled } from "@ant-design/icons";
import colors from "@/theme/default/colors";
import { ensuredForwardRef } from "react-use";
import { ReactNode, Ref } from "react";
// import { BaseSelectRef } from "rc-select";

const CustomSelect = styled(Select)`
  width: 100%;
  &.ant-select-lg {
    height: 41.6px;
  }
  & .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      background-color: #efefef;
    }
  }

  & .ant-select-selector {
    min-width: 60px;
  }
`;

const Label = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
  color: ${colors.input.select.label};
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: ${colors.input.select.error};
  .anticon {
    margin-right: 8px;
  }
`;

type Props = {
  label?: ReactNode | string;
  error?: ReactNode | string | null;
  [key: string]: unknown;
};

// ensuredForwardRef tyoe of ref is not working, so use react forwardRef
const SelectInput = ensuredForwardRef<HTMLDivElement, Props>(
  ({ label, error, ...restProps }: Props, ref: Ref<RefSelectProps>) => {
    return (
      <>
        {label && <Label>{label}</Label>}
        <CustomSelect
          ref={ref}
          {...(error && { status: "error" })}
          {...restProps}
        />
        {error ? (
          <Error>
            <CloseCircleFilled />
            {error}
          </Error>
        ) : null}
      </>
    );
  }
);

export default SelectInput;
